<script setup>
import { ref, defineProps, onMounted, watch, defineEmits } from "vue";
import { useStore } from "vuex";
import api from "@/api/index";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useStore();
const userId = ref(store.state.userId);
import { message } from "ant-design-vue";
const emit = defineEmits([
  "handleCancel",
  "handleAddResoure",
  "saveResoure",
  "closeLoading",
]);
const props = defineProps({
  showAddResource: Boolean,
  formType: String,
  resoureInfo: Object,
  newGroupId: String,
});
const show = ref(props.showAddResource);
watch(
  () => props.showAddResource,
  (val) => {
    show.value = val;
  }
);
watch(
  () => props.resoureInfo.resourceType,
  (val) => {
    if (props.formType == "edit") {
      logActive.value = val;
      props.resoureInfo.resourceType = val;
    } else {
      props.resoureInfo.resourceType = 0;
    }
  }
);
const loading2 = ref(false);
const modelList = ref([]);
const getUrl = ref("");
const logoAzure = require("@/assets/manage/logoAzure.svg");
const logoBaidu = require("@/assets/manage/logoBaidu.svg");
const logoAli = require("@/assets/manage/logoAli.svg");
const logActive = ref(0);
// 切换资源类型
const changeLog = (index) => {
  logActive.value = index;
  props.resoureInfo.resourceType = index;
  if (props.formType == "add") {
    props.resoureInfo.functionType = "";
    props.resoureInfo.modelType = "";
  }

  if (index == 0) {
    getUrl.value = "Azure";
  } else if (index == 1) {
    getUrl.value = "Baidu";
  } else if (index == 2) {
    getUrl.value = "Ali";
  }
  getBaseSelect();
};
onMounted(() => {
  getUrl.value = "Azure";
  getBaseSelect();
  if (props.formType == "add") {
    logActive.value = 0;
  }
});
const rules = ref({
  functionType: [{ required: true, message: t("manage.baseForm.base7") }],
  configurationName: [{ required: true, message: t("manage.baseForm.base8") }],
  modelName: [{ required: true, message: t("manage.baseForm.base9") }],
  modelType: [{ required: true, message: t("manage.baseForm.base10") }],
  apiBase: [{ required: true, message: t("manage.baseForm.base11") }],
  apiVersion: [{ required: true, message: t("manage.baseForm.base12") }],
  apiKey: [{ required: true, message: t("manage.baseForm.base13") }],
  apiUrl: [{ required: true, message: t("manage.baseForm.base14") }],
  connectionKey: [{ required: true, message: t("manage.baseForm.base15") }],
  accountKey: [{ required: true, message: t("manage.baseForm.base16") }],
  accountUrl: [{ required: true, message: t("manage.baseForm.base17") }],
  secretKey: [{ required: true, message: t("manage.baseForm.base18") }],
  endpoint: [{ required: true, message: t("manage.baseForm.base19") }],
  accessKeyId: [{ required: true, message: t("manage.baseForm.base20") }],
  accessKeySecret: [{ required: true, message: t("manage.baseForm.base21") }],
  accessKey: [{ required: true, message: t("manage.baseForm.base22") }],
  endpoint: [{ required: true, message: t("manage.baseForm.base23") }],
});

// 配置下拉框
const getBaseSelect = () => {
  api.manage.getBaseSelectApi(getUrl.value).then((res) => {
    if (res.returnCode == 200) {
      modelList.value = res.data;
    }
  });
};
const newChildren = ref([]);
const getSelectChildren = (arr, label) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].label == label) {
      newChildren.value = arr[i].children;
    }
  }
};
const azureLabel = ref(null);
watch(
  () => props.resoureInfo.functionType,
  (newVal) => {
    getSelectChildren(modelList.value, newVal);
    azureLabel.value = newVal;
  }
);
const changeModel = () => {
  props.resoureInfo.modelType = "";
};
const formRef = ref(null);
// 关闭
const handleCancel = () => {
  formRef.value.resetFields();
  show.value = !show.value;
  emit("handleCancel");
  if (props.formType == "add") {
    props.resoureInfo = {};
  }
};
//新增确定
const handleUpload = async (formRef) => {
  loading2.value = true;
  props.resoureInfo.userId = userId.value;
  props.resoureInfo.resourceGroupId = props.newGroupId;
  props.resoureInfo.resourceType = logActive.value;

  await formRef.validate((valid) => {
    if (valid) {
      if (logActive.value == 0) {
        // Azure
        api.manage.addAzureBillApi(props.resoureInfo).then((res) => {
          loading2.value = false;
          if (res.returnCode == 200) {
            message.success(t("manage.baseForm.base24"));
            show.value = !show.value;
            emit("handleAddResoure");
          }
        });
      } else if (logActive.value == 1) {
        // baidu
        api.manage.addBaiduBillApi(props.resoureInfo).then((res) => {
          loading2.value = false;
          if (res.returnCode == 200) {
            message.success(t("manage.baseForm.base24"));
            show.value = !show.value;
            emit("handleAddResoure");
          }
        });
      } else if (logActive.value == 2) {
        // 阿里
        api.manage.addAliBillApi(props.resoureInfo).then((res) => {
          loading2.value = false;
          if (res.returnCode == 200) {
            message.success(t("manage.baseForm.base24"));
            show.value = !show.value;
            emit("handleAddResoure");
          }
        });
      }
    }
  });
};
// 修改确定
const handleUpload2 = async (formRef) => {
  props.resoureInfo.userId = userId.value;
  props.resoureInfo.resourceGroupId = props.newGroupId;
  await formRef.validate((valid) => {
    if (valid) {
      show.value = !show.value;
      emit("saveResoure", props.resoureInfo);
    }
  });
};
// 资源配置选中
const logList = ref([
  {
    url: logoAzure,
  },
  {
    url: logoBaidu,
  },
  {
    url: logoAli,
  },
]);
</script>

<template>
  <div>
    <!-- 资源配置新增与修改 -->
    <el-dialog
      :z-index="100"
      destroy-on-close
      v-model="show"
      align-center
      :close-on-click-modal="false"
      :show-close="false"
    >
      <template #header="">
        <div class="my-header">
          <div style="display: flex">
            <div class="tit_img">
              <img src="@/assets/manage/addResoure.svg" alt="" />
            </div>
            {{ $t("manage.baseForm.base25") }} -
            <span>{{
              props.formType == "add"
                ? $t("manage.baseForm.base26")
                : $t("manage.baseForm.base27")
            }}</span>
          </div>
          <el-icon
            class="el-icon--left"
            :class="'close-icon'"
            @click="handleCancel"
            style="cursor: pointer"
          >
            <Close />
          </el-icon>
        </div>
      </template>
      <div class="content" v-loading="loading2">
        <el-form
          :model="props.resoureInfo"
          label-position="top"
          :rules="rules"
          ref="formRef"
        >
          <!-- <el-form-item label="资源平台" v-if="props.formType == 'add'">
                        <div class="form-name">
                            <div class="comtent">
                                <div v-for="(item, index) in logList" :key="index" @click="changeLog(index)"
                                    :class="logActive == index ? 'log_itemActive' : 'log_item'">
                                    <img :src=item.url alt="">
                                </div>
                            </div>
                        </div>
                    </el-form-item> -->
          <el-form-item
            prop="resourceType"
            :label="$t('manage.baseForm.base28')"
          >
            <div class="form-name">
              <div class="comtent">
                <div
                  v-for="(item, index) in logList"
                  :key="index"
                  @click="changeLog(index)"
                  :class="logActive == index ? 'log_itemActive' : 'log_item'"
                >
                  <img :src="item.url" alt="" />
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            prop="configurationName"
            :label="$t('manage.baseForm.base29')"
          >
            <el-input
              :placeholder="$t('manage.baseForm.base29')"
              class="comtent"
              v-model="props.resoureInfo.configurationName"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="functionType"
            :label="$t('manage.baseForm.base30')"
          >
            <el-select
              v-model="props.resoureInfo.functionType"
              :placeholder="$t('manage.baseForm.base31')"
              @change="changeModel"
            >
              <el-option
                v-for="item in modelList"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              >
                <span class="dropText">{{ item.label }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="modelType" :label="$t('manage.baseForm.base32')">
            <el-select
              v-model="props.resoureInfo.modelType"
              :placeholder="$t('manage.baseForm.base33')"
            >
              <el-option
                v-for="item in newChildren"
                :key="item.value"
                :label="item.label"
                :value="item.label"
              >
                <span class="dropText">{{ item.label }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <!-- Azure -->
          <div v-if="logActive == 0">
            <el-form-item
              v-if="['文本生成', '向量化', '文生图'].includes(azureLabel)"
              prop="modelName"
              :label="$t('manage.baseForm.base34')"
            >
              <el-input
                :placeholder="$t('manage.baseForm.base34')"
                class="comtent"
                v-model="props.resoureInfo.modelName"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="
                ['文本生成', '向量化', '文本翻译', '语音转换'].includes(
                  azureLabel
                )
              "
              prop="apiBase"
              :label="$t('manage.baseForm.base35')"
            >
              <el-input
                :placeholder="$t('manage.baseForm.base35')"
                class="comtent"
                v-model="props.resoureInfo.apiBase"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="['文本生成', '向量化'].includes(azureLabel)"
              prop="apiVersion"
              :label="$t('manage.baseForm.base36')"
            >
              <el-input
                :placeholder="$t('manage.baseForm.base36')"
                class="comtent"
                v-model="props.resoureInfo.apiVersion"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="
                [
                  '文本生成',
                  '向量化',
                  '文生图',
                  '光学扫描',
                  '文本翻译',
                  '语音转换',
                  'bing搜索',
                ].includes(azureLabel)
              "
              prop="apiKey"
              :label="$t('manage.baseForm.base37')"
            >
              <el-input
                :placeholder="$t('manage.baseForm.base37')"
                class="comtent"
                v-model="props.resoureInfo.apiKey"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="['文生图', '光学扫描', 'bing搜索'].includes(azureLabel)"
              prop="apiUrl"
              :label="$t('manage.baseForm.base35')"
            >
              <el-input
                :placeholder="$t('manage.baseForm.base35')"
                class="comtent"
                v-model="props.resoureInfo.apiUrl"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="['数据存储'].includes(azureLabel)"
              prop="connectionKey"
              :label="$t('manage.baseForm.base38')"
            >
              <el-input
                :placeholder="$t('manage.baseForm.base38')"
                class="comtent"
                v-model="props.resoureInfo.connectionKey"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="['数据存储'].includes(azureLabel)"
              prop="accountKey"
              :label="$t('manage.baseForm.base39')"
            >
              <el-input
                :placeholder="$t('manage.baseForm.base39')"
                class="comtent"
                v-model="props.resoureInfo.accountKey"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="['数据存储'].includes(azureLabel)"
              prop="accountUrl"
              :label="$t('manage.baseForm.base40')"
            >
              <el-input
                :placeholder="$t('manage.baseForm.base40')"
                class="comtent"
                v-model="props.resoureInfo.accountUrl"
              ></el-input>
            </el-form-item>
          </div>
          <!-- 百度 -->
          <div v-if="logActive == 1">
            <el-form-item
              v-if="
                ['文本生成', '向量化', '文生图', '数据存储'].includes(
                  azureLabel
                )
              "
              prop="accessKey"
              :label="$t('manage.baseForm.base41')"
            >
              <el-input
                :placeholder="$t('manage.baseForm.base41')"
                class="comtent"
                v-model="props.resoureInfo.accessKey"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="
                [
                  '光学扫描',
                  '向量化',
                  '数据存储',
                  '文本生成',
                  '文生图',
                ].includes(azureLabel)
              "
              prop="secretKey"
              :label="$t('manage.baseForm.base42')"
            >
              <el-input
                :placeholder="$t('manage.baseForm.base42')"
                class="comtent"
                v-model="props.resoureInfo.secretKey"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="['光学扫描'].includes(azureLabel)"
              prop="apiKey"
              :label="$t('manage.baseForm.base37')"
            >
              <el-input
                :placeholder="$t('manage.baseForm.base37')"
                class="comtent"
                v-model="props.resoureInfo.apiKey"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="['数据存储'].includes(azureLabel)"
              prop="endpoint"
              :label="$t('manage.baseForm.base35')"
            >
              <el-input
                :placeholder="$t('manage.baseForm.base35')"
                class="comtent"
                v-model="props.resoureInfo.endpoint"
              ></el-input>
            </el-form-item>
          </div>
          <!-- 阿里 -->
          <div v-if="logActive == 2">
            <el-form-item
              v-if="['文本生成', '向量化', '文生图'].includes(azureLabel)"
              prop="apiKey"
              :label="$t('manage.baseForm.base37')"
            >
              <el-input
                :placeholder="$t('manage.baseForm.base37')"
                class="comtent"
                v-model="props.resoureInfo.apiKey"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="['光学扫描'].includes(azureLabel)"
              prop="accessKeyId"
              :label="$t('manage.baseForm.base43')"
            >
              <el-input
                :placeholder="$t('manage.baseForm.base43')"
                class="comtent"
                v-model="props.resoureInfo.accessKeyId"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="['光学扫描'].includes(azureLabel)"
              prop="accessKeySecret"
              :label="$t('manage.baseForm.base44')"
            >
              <el-input
                :placeholder="$t('manage.baseForm.base44')"
                class="comtent"
                v-model="props.resoureInfo.accessKeySecret"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="['数据存储'].includes(azureLabel)"
              prop="secretKey"
              :label="$t('manage.baseForm.base42')"
            >
              <el-input
                :placeholder="$t('manage.baseForm.base42')"
                class="comtent"
                v-model="props.resoureInfo.secretKey"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="['数据存储'].includes(azureLabel)"
              prop="accessKey"
              :label="$t('manage.baseForm.base41')"
            >
              <el-input
                :placeholder="$t('manage.baseForm.base41')"
                class="comtent"
                v-model="props.resoureInfo.accessKey"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="['数据存储'].includes(azureLabel)"
              prop="endpoint"
              :label="$t('manage.baseForm.base35')"
            >
              <el-input
                :placeholder="$t('manage.baseForm.base35')"
                class="comtent"
                v-model="props.resoureInfo.endpoint"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer" v-if="props.formType == 'add'">
          <el-button @click="handleCancel(formRef)" class="cancel">{{
            $t("manage.baseForm.base45")
          }}</el-button>
          <el-button
            :loading="loading2"
            @click="handleUpload(formRef)"
            class="confirm"
            >{{ $t("manage.baseForm.base46") }}</el-button
          >
        </div>
        <div class="dialog-footer" v-if="props.formType == 'edit'">
          <el-button @click="handleCancel(formRef)" class="cancel">{{
            $t("manage.baseForm.base45")
          }}</el-button>
          <el-button @click="handleUpload2(formRef)" class="confirm">{{
            $t("manage.baseForm.base46")
          }}</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:deep(.el-dialog) {
  --el-dialog-width: 520px;
  border-radius: 30px;
  height: 700px;
  padding: 30px;
  background-color: #f7f8ff;
}

:deep(.el-select__wrapper) {
  width: 450px !important;
  border-radius: 12px !important;
}

:deep(.el-select--large) {
  width: 450px !important;
}
:deep(.el-form-item__label) {
  margin-top: 10px;
}
:deep(.el-input__wrapper) {
  height: 38px !important;
  width: 428px !important;
  border-radius: 12px;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Medium";

  .tit_img {
    width: 28px;
    height: 28px;
    border-radius: 8px;
    margin-right: 12px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
}

.close-icon {
  font-size: 20px;
}

.content {
  font-size: 18px;
  color: #8a8a8a;
  height: 550px;
  overflow-x: hidden;
  overflow: auto;

  .name {
    color: #3d3d3d;
    font-size: 24px;
    margin: auto 6px auto 18px;
    font-weight: 400;
  }
}

.form-name {
  font-size: 17px;
  color: #3d3d3d;

  .tit {
    margin-bottom: 8px;
    display: flex;

    div {
      margin-left: 5px;
      margin-top: 4px;
    }
  }

  .comtent {
    margin-bottom: 8px;
    display: flex;

    .log_img {
      width: 25px;
      height: 25px;
      border-radius: 8px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }

    .log_name {
      margin-left: 8px;
      color: #8a8a8a;
      height: 25px;
      line-height: 25px;
    }

    .log_item {
      width: 132px;
      height: 42px;
      border-radius: 18px;
      padding: 10px 6px;
      background-color: #fff;
      margin-right: 10px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .log_itemActive {
      width: 132px;
      height: 42px;
      border-radius: 18px;
      padding: 10px 6px;
      background-color: #dbdfff;
      margin-right: 10px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.dialog-footer {
  display: flex;
  justify-content: end;
  font-size: 15px;

  .cancel {
    color: #f02d63;
    padding: 8px 20px;
    border-radius: 16px;
    border: 1px solid #edeef6;
    cursor: pointer;
  }

  .confirm {
    color: #fff;
    background-color: #3376ff;
    margin-left: 10px;
    padding: 8px 20px;
    border-radius: 16px;
    cursor: pointer;
  }
}
.dropText {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
  padding-left:10px
}
</style>
