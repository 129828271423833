<template>
  <div class="chunkIndex">
    <!-- pdf -->
    <pdf :pdfUrl="pdfUrl" v-if="pdfType"></pdf>
    <!-- txt -->
    <txt class="txt" :textData="textData" v-if="txtType"></txt>
    <!-- word -->
    <word :wordUrl="wordUrl" v-if="wordType"></word>
    <!-- excel -->
    <excel
      v-if="excelType"
      :excelData="excelData"
      :excelBodyData="excelBodyData"
    ></excel>
    <!-- html -->
    <htmlView v-if="htmlType" :htmlData="htmlData"></htmlView>
    <!-- ppt -->
    <ppt :pptUrl="pptData" v-if="pptType"></ppt>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import pdf from "./pdf.vue";
import txt from "./txt.vue";
import excel from "./excel.vue";
import word from "./word.vue";
import htmlView from "./html.vue";
import ppt from "./ppt.vue";
import api from "@/api/index";
const store = useStore();
const props = defineProps(["fileItem"]);
const userId = ref(store.state.userId);
const topicItem = ref(store.state.topicItem);
const chatType = ref(store.state.chatType);
const pdfUrl = ref("");
const textData = ref("");
const wordUrl = ref("");
const excelData = ref();
const htmlData = ref("");
const pptData = ref("");
const excelBodyData = ref();
const pdfType = ref(false);
const wordType = ref(false);
const excelType = ref(false);
const txtType = ref(false);
const htmlType = ref(false);
const pptType = ref(false);
onMounted(() => {
  if (chatType.value == "chat" && props.fileItem.type != 1) {
    getChatFile();
  } else {
    getFile();
  }
});
watch(
  () => store.state.chatType,
  (newValue, oldValue) => {
    chatType.value = newValue;
  }
);
watch(
  () => props.fileItem,
  (newValue, oldValue) => {
    if (chatType.value == "chat" && props.fileItem.type != 1) {
      getChatFile();
    } else {
      getFile();
    }
  }
);
// chat聊天文件查看
const getChatFile = () => {
  let fileType = props.fileItem.fileName
    .substring(props.fileItem.fileName.lastIndexOf(".") + 1)
    .toLowerCase();
  pdfUrl.value = "";
  textData.value = "";
  wordUrl.value = "";
  excelData.value = "";
  excelBodyData.value = "";
  htmlData.value = "";
  pptData.value = "";
  pdfType.value = false;
  wordType.value = false;
  excelType.value = false;
  txtType.value = false;
  htmlType.value = false;
  pptType.value = false;
  if (fileType == "pdf") {
    prewViewPdf();
  } else if (fileType == "xlsx") {
    prewExcel();
  } else if (fileType == "docx") {
    prewWord();
  } else if (fileType == "txt") {
    prewTxt();
  } else if (fileType == "html") {
    prewHtml();
  } else if (fileType == "pptx") {
    prewPpt();
  }
};
const prewViewPdf = () => {
  api.chat
    .pdfFind({
      fileId: props.fileItem.fileId || props.fileItem.id,
      userId: userId.value,
      pageId: 1,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        pdfUrl.value = res.data.Url;
        pdfType.value = true;
      }
    });
};
const prewExcel = () => {
  api.chat
    .excelFind({
      fileId: props.fileItem.fileId || props.fileItem.id,
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        excelData.value = res.res;
        excelBodyData.value = res.res[0].data.slice(1);
        excelType.value = true;
      }
    });
};
const prewWord = () => {
  api.chat
    .wordFind({
      fileId: props.fileItem.fileId || props.fileItem.id,
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        wordUrl.value = res.data;
        wordType.value = true;
      }
    });
};
const prewTxt = () => {
  api.chat
    .txtFind({
      userId: userId.value,
      fileId: props.fileItem.fileId || props.fileItem.id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        textData.value = res.data;
        txtType.value = true;
      }
    });
};
const prewHtml = () => {
  api.chat
    .txtFind({
      userId: userId.value,
      fileId: props.fileItem.fileId || props.fileItem.id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        htmlData.value = res.data;
        htmlType.value = true;
      }
    });
};
const prewPpt = () => {
  api.chat
    .wordFind({
      userId: userId.value,
      fileId: props.fileItem.fileId || props.fileItem.id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        pptData.value = res.data;
        pptType.value = true;
      }
    });
};
// 微模型文件查询
const getFile = () => {
  let fileType = props.fileItem.fileName
    .substring(props.fileItem.fileName.lastIndexOf(".") + 1)
    .toLowerCase();
  pdfUrl.value = "";
  textData.value = "";
  wordUrl.value = "";
  excelData.value = "";
  excelBodyData.value = "";
  htmlData.value = "";
  pptData.value = "";
  pdfType.value = false;
  wordType.value = false;
  excelType.value = false;
  txtType.value = false;
  htmlType.value = false;
  pptType.value = false;
  if (fileType == "pdf") {
    goPdf();
  } else if (fileType == "txt") {
    goTxt();
  } else if (fileType == "doc" || fileType == "docx") {
    goWord();
  } else if (fileType == "xls" || fileType == "xlsx") {
    goExcel();
  }
};
// 预览pdf
const goPdf = () => {
  api.base
    .pdfView(
      {
        fileId: props.fileItem.fileId || props.fileItem.id,
        userId: userId.value,
        pageId: 1,
      },
      topicItem.value.knowledge_library_id || topicItem.value.libraryId
    )
    .then((res) => {
      if (res.returnCode == 200) {
        pdfUrl.value = res.data.Url;
        pdfType.value = true;
      }
    });
};
// 预览txt
const goTxt = () => {
  api.base
    .getText(
      topicItem.value.knowledge_library_id || topicItem.value.libraryId,
      userId.value,
      props.fileItem.fileId || props.fileItem.id
    )
    .then((res) => {
      if (res.returnCode == 200) {
        textData.value = res.data;
        txtType.value = true;
      }
    });
};
// 预览word
const goWord = () => {
  api.base
    .wordView(
      {
        fileId: props.fileItem.fileId || props.fileItem.id,
        userId: userId.value,
      },
      topicItem.value.knowledge_library_id || topicItem.value.libraryId
    )
    .then((res) => {
      if (res.returnCode == 200) {
        wordUrl.value = res.data;
        wordType.value = true;
      }
    });
};
// 预览excel
const goExcel = () => {
  api.base
    .excelView(
      {
        fileId: props.fileItem.fileId || props.fileItem.id,
        userId: userId.value,
      },
      topicItem.value.knowledge_library_id || topicItem.value.libraryId
    )
    .then((res) => {
      if (res.returnCode == 200) {
        excelData.value = res.res;
        excelBodyData.value = res.res[0].data.slice(1);
        excelType.value = true;
      }
    });
};
</script>

<style lang="scss" scoped>
.chunkIndex {
  width: 100%;
  height: 100%;
}
.txt {
  height: 100%;
  overflow: auto;
  background-color: #ffffff;
}
</style>
