<template>
  <div class="baseFile">
    <div class="flexBetween">
      <div class="dialogTitle">{{ t("base.base252") }}</div>
      <img class="closeImg" :src="closeFileView" alt="" @click="cancel" />
    </div>
    <!-- 返回 -->
    <div class="pageHeader">
      <el-page-header @back="goBack" v-if="urlList.length != 0">
        <template #content>
          {{ urlList[urlList.length - 1].folderName }}
        </template>
      </el-page-header>
    </div>
    <div class="baseBody">
      <div class="baseLeft" v-loading="baseLoading">
        <VueDraggable
          v-model="baseFile"
          :animation="150"
          :group="{ name: 'fileList', pull: 'clone', put: false }"
          :sort="false"
          class="VueDraggable"
        >
          <div
            v-for="(item, index) in baseFile"
            :key="index"
            @dblclick="openFolder(item)"
          >
            <div class="fileBetween fileMoreImgDiv">
              <div class="flexCenter treeDiv">
                <img :src="getBaseFileIcon(item)" alt="" class="fileIcon" />
                <div v-if="item.fileName" class="fileTitle">
                  {{ item.fileName }}
                </div>
                <div v-if="item.folderName" class="fileTitle">
                  {{ item.folderName }}
                </div>
                <el-tooltip
                  popper-class="toolTip"
                  :content="t('base.base291')"
                  v-if="item.importantFlag == 1"
                >
                  <img :src="fileYear" alt="" class="fileYear" />
                </el-tooltip>
                <el-tooltip
                  popper-class="toolTip"
                  :content="t('base.base292')"
                  v-if="item.expireFlag == 1"
                >
                  <img :src="baseGq" class="fileYear" alt="" />
                </el-tooltip>
              </div>
            </div>
          </div>
        </VueDraggable>
      </div>
      <div class="baseRight">
        <VueDraggable
          v-model="bottomFileList"
          :animation="150"
          group="fileList"
          :sort="false"
          class="bottomDra"
        >
          <div class="drapFlex" v-if="bottomFileList.length == 0">
            <img :src="drapBottom" alt="" class="drapBottom" />
            <div class="drapBottomText">
              {{ t("base.base253") }}
            </div>
          </div>
          <div v-else class="bottomMax">
            <div
              v-for="(item, index) in bottomFileList"
              :key="item.id"
              class="bottomFileItem"
            >
              <div class="fileBetween">
                <div class="flexCenter bottomTreeDiv">
                  <img :src="getBaseFileIcon(item)" alt="" class="fileIcon" />
                  <div
                    v-if="item.fileName"
                    :class="{
                      fileTitle:
                        item.expireFlag == 1 && item.importantFlag == 1,
                      fileTitle1:
                        item.expireFlag == 1 || item.importantFlag == 1,
                      fileTitle2:
                        item.expireFlag != 1 && item.importantFlag != 1,
                    }"
                  >
                    {{ item.fileName }}
                  </div>
                  <div
                    v-if="item.folderName"
                    :class="{
                      fileTitle:
                        item.expireFlag == 1 && item.importantFlag == 1,
                      fileTitle1:
                        item.expireFlag == 1 || item.importantFlag == 1,
                      fileTitle2:
                        item.expireFlag != 1 && item.importantFlag != 1,
                    }"
                  >
                    {{ item.folderName }}
                  </div>
                  <div
                    v-if="item.sessionName"
                    :class="{
                      fileTitle:
                        item.expireFlag == 1 && item.importantFlag == 1,
                      fileTitle1:
                        item.expireFlag == 1 || item.importantFlag == 1,
                      fileTitle2:
                        item.expireFlag != 1 && item.importantFlag != 1,
                    }"
                  >
                    {{ item.sessionName }}
                  </div>
                  <el-tooltip
                    popper-class="toolTip"
                    :content="t('base.base291')"
                    v-if="item.importantFlag == 1"
                  >
                    <img :src="fileYear" alt="" class="fileYear" />
                  </el-tooltip>
                  <el-tooltip
                    popper-class="toolTip"
                    :content="t('base.base292')"
                    v-if="item.expireFlag == 1"
                  >
                    <img :src="baseGq" class="fileYear" alt="" />
                  </el-tooltip>
                </div>
                <img
                  :src="closeFileBottom"
                  alt=""
                  class="closeFileBottom"
                  @click="deleteBottomFile(index)"
                  v-if="props.type"
                />
              </div>
            </div>
          </div>
        </VueDraggable>
      </div>
    </div>
    <div class="flexEnd">
      <el-button class="cancelBtn" @click="cancel">{{
        t("base.base45")
      }}</el-button>
      <el-button class="okBtn" @click="ok">{{ t("base.base122") }}</el-button>
    </div>
  </div>
  <div class="mask"></div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import closeFileBottom from "@/assets/base/closeFileBottom.svg";
import drapBottom from "@/assets/base/drapBottom.svg";
import { VueDraggable } from "vue-draggable-plus";
import fileYear from "@/assets/base/fileYear.svg";
import { getBaseFileIcon, formatFileSize } from "@/utils/file";
import closeFileView from "@/assets/base/closeFileView.svg";
import { useI18n } from "vue-i18n";
import baseGq from "@/assets/base/bseGq.svg";
import { useStore } from "vuex";
import api from "@/api";
const store = useStore();
const { t } = useI18n();
const props = defineProps(["saveList", "type", "baseId"]);
const emit = defineEmits(["cancel", "ok"]);
const userId = ref(store.state.userId);
const bottomFileList = ref([]);
const topicItem = ref(store.state.topicItem); //点击详情很重要
const baseFile = ref([]);
const baseLoading = ref(false);
const urlList = ref([]); //存放url
onMounted(() => {
  gerBaseFile();
  if (props.saveList.length != 0) {
    bottomFileList.value = JSON.parse(JSON.stringify(props.saveList));
  } else {
    bottomFileList.value = [];
  }
});
watch(
  () => bottomFileList.value,
  (newValue, oldValue) => {
    setBottomFileList();
  }
);
// 监听切换 然后调用接口
watch(
  () => store.state.topicItem,
  (newValue, oldValue) => {
    topicItem.value = newValue;
  }
);
// 返回上一级
const goBack = () => {
  // 特殊情况 获取root
  if (urlList.value.length == 1) {
    gerBaseFile(null);
    urlList.value = [];
  } else {
    // 获取最后的前面的
    gerBaseFile(urlList.value[urlList.value.length - 2].id);
    urlList.value.splice(urlList.value.length - 1, 1);
  }
};
// 打开文件夹
const openFolder = (item) => {
  if (item.folderName) {
    gerBaseFile(item.id);
    urlList.value.push(item);
  }
};
// 微模型选择
const gerBaseFile = (id) => {
  baseLoading.value = true;
  api.base
    .queryFileList(
      { userId: userId.value, folderId: id ? id : null },
      props.baseId || topicItem.value.knowledge_library_id
    )
    .then((res) => {
      baseLoading.value = false;
      if (res.returnCode == 200) {
        baseFile.value = [...res.data.folderInfo, ...res.data.filesInfo];
      }
    });
};
const cancel = () => {
  emit("cancel");
};
const ok = () => {
  console.log(bottomFileList.value)
  emit("ok", bottomFileList.value);
};
// 去重底部
const setBottomFileList = () => {
  bottomFileList.value = newSetFn(bottomFileList.value);
};
// 删除底部
const deleteBottomFile = (index) => {
  bottomFileList.value.splice(index, 1);
};
// 去重
const newSetFn = (arr) => {
  let len = arr.length;
  for (let i = 0; i < len; i++) {
    for (let j = i + 1; j < len; j++) {
      if (arr[i].id === arr[j].id) {
        arr.splice(j, 1);
        len--; // 减少循环次数提高性能
        j--; // 保证j的值自加后不变
      }
    }
  }
  return arr;
};
</script>

<style lang="scss" scoped>
.baseFile {
  position: fixed;
  width: 700px;
  height: 525px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 13;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 30px;
}
.drapBottom {
  width: 20px;
  height: 20px;
  margin-right: 11px;
}
.drapBottomText {
  font-size: 16px;
  color: #8a8a8a;
  font-family: "Medium";
}
.drapFlex {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
}
.drapBottomDiv {
  background-color: #f7f8ff;
  border-radius: 20px;
  padding: 12px;
}
.fileBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  cursor: default;
  margin-bottom: 10px;
}
.fileMoreImgDiv:hover {
  background-color: #dbdfff;
}
.mask {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.3;
  z-index: 11;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dialogTitle {
  font-size: 22px;
  color: #3d3d3d;
  font-family: "Medium";
}
.closeImg {
  width: 15px;
  cursor: pointer;
}
.baseBody {
  display: flex;
  height: calc(100% - 115px);
  margin-bottom: 10px;
}
.flexEnd {
  display: flex;
  justify-content: flex-end;
}
.cancelBtn {
  height: 48px;
  border: 2px solid #edeef6;
  color: #f02d63;
  border-radius: 16px;
  font-size: 20px;
  font-family: "Regular";
}
.okBtn {
  height: 48px;
  background-color: #3376ff;
  border-radius: 16px;
  border: 2px solid #3376ff;
  color: #fefefe;
  font-family: "Regular";
  font-size: 20px;
}
.baseLeft {
  width: calc(50% - 40px);
  padding: 20px;
}
.baseRight {
  width: calc(50% - 40px);
  padding: 20px;
  background-color: #f7f8ff;
  border-radius: 20px;
}
.VueDraggable {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.treeDiv {
  width: 100%;
}
.fileIcon {
  width: 21px;
  height: 21px;
  margin-right: 7px;
}
.fileTitle {
  color: #3d3d3d;
  font-size: 22px;
  font-family: "Regular";
  max-width: calc(100% - 64px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fileTitle1 {
  color: #3d3d3d;
  font-size: 22px;
  font-family: "Regular";
  max-width: calc(100% - 32px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fileTitle2 {
  color: #3d3d3d;
  font-size: 22px;
  font-family: "Regular";
  max-width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fileYear {
  width: 22px;
  margin-left: 10px;
}
.closeFileBottom {
  width: 16px;
}
.bottomDra {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.bottomFileItem {
  height: 26px;
  padding: 10px;
  cursor: pointer;
  border-radius: 20px;
  background-color: #eceeff;
  margin-bottom: 8px;
}
.bottomTreeDiv {
  width: calc(100% - 16px);
}
.pageHeader {
  height: 25px;
  :deep(.el-page-header__title) {
    font-size: 18px;
    font-family: "Regular";
    color: #8a8a8a;
  }
  :deep(.el-page-header__content) {
    font-size: 18px;
    font-family: "Regular";
    color: #3d3d3d;
  }
}
</style>
