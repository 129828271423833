<script setup>
import { ref, defineProps, reactive, watch, defineEmits, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import detailView from "./userDetail.vue";
import userReset from "./userReset.vue";
import userResetInfo from "./userResetInfo.vue";
import addView from "./userAdd.vue";
import api from "@/api/index";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import { ElMessageBox } from "element-plus";
const store = useStore();
const userId = ref(store.state.userId);
const { t } = useI18n();
// 你可以在这里添加更多的点击处理逻辑
const props = defineProps({
  tableId: Number,
  tableName: String,
  user: String,
});
onMounted(() => {
  getUserTable();
  getUserTotal();
});
const tableId = ref(props.tableId);
watch(
  () => props.tableId,
  (newVal) => {
    totalForm.value.departmentId = newVal;
    userTable.value.departmentId = newVal;
    getUserTotal();
    getUserTable();
  }
);
watch(
  () => props.user,
  (newVal) => {
    userTable.value.userNamePattern = newVal;
    getUserTable();
  }
);
// 分页
const currentPage = ref(1);
const pageSize = ref(20);
const updatePageSize = (newSize) => {
  pageSize.value = newSize;
  userTable.value.perPage = newSize;
  getUserTable();
};
const updateCurrentPage = (newPage) => {
  currentPage.value = newPage;
  userTable.value.pageId = newPage;
  getUserTable();
};
// 请求表格数据
const totalItems = ref(0);
const userTable = ref({
  userId: userId.value,
  userNamePattern: "",
  departmentId: tableId.value,
  activate: null,
  ifDirectly: true,
  pageId: currentPage.value,
  perPage: pageSize.value,
});
const selectable = (row) => {
  return row.userId != 1000000;
};
const tableMember = ref([]);
const getUserTable = () => {
  if (userTable.value.departmentId) {
    api.manage.getUserList(userTable.value).then((res) => {
      if (res.returnCode == 200) {
        totalItems.value = res.totalDataNum;
        tableMember.value = res.data;
      }
    });
  }
};
// 用户数量
const userTotal = ref(null);
const activeTotal = ref(null);
const totalForm = ref({
  userId: userId.value,
  departmentId: tableId.value,
  ifDirectly: userTable.value.ifDirectly,
});
const getUserTotal = () => {
  api.manage.getUserTotal(totalForm.value).then((res) => {
    if (res.returnCode == 200) {
      userTotal.value = res.data.alluserCount;
      activeTotal.value = res.data.activeUserCount;
    }
  });
};
// 状态下拉
const status = ref(t("manage.userForm.user70"));
const statusList = ref([
  {
    value: 3,
    label: t("manage.userForm.user70"),
  },
  {
    value: 1,
    label: t("manage.userForm.user52"),
  },
  {
    value: 0,
    label: t("manage.userForm.user53"),
  },
]);
const statusBtn = (item) => {
  userTable.value.activate = item;
  getUserTable();
};
// 用户数量下拉
const allUser = ref(t("manage.userForm.user71"));
const allUserList = ref([
  {
    value: true,
    label: t("manage.userForm.user71"),
  },
  {
    value: false,
    label: t("manage.userForm.user72"),
  },
]);
watch(
  () => allUser.value,
  (newVal) => {
    userTable.value.ifDirectly = newVal;
    totalForm.value.ifDirectly = newVal;
    getUserTable();
    getUserTotal();
  }
);
// 控制表头显示
const tabelType = ref({
  userName: true,
  isActive: true,
  email: true,
  departmentName: true,
  operation: true,
  address: false,
  phone: false,
  roleName: false,
  // isManager: false, //部门负责人
  sex: false,
  // supervisorName: false, //直属上级
});
const copyTable = ref({});
const showDialog = ref(false);
const tabelTypeBtn = () => {
  copyTable.value = JSON.parse(JSON.stringify(tabelType.value));
  showDialog.value = true;
};
const handleChange = (e, key) => {
  copyTable.value[key] = e;
};
const handleConfirm = () => {
  tabelType.value = copyTable.value;
  showDialog.value = false;
};
// 多选
const ids = ref([]);
const multipleSelection = ref([]);
const handleSelectionChange = (val) => {
  ids.value = val.map((item) => item.userId);
  multipleSelection.value = val.map((item) => item.userId);
};
// 批量禁用
const deleteAll = () => {
  if (multipleSelection.value.length == 0) {
    message.error(t("manage.userForm.user73"));
  } else {
    ElMessageBox.confirm(
      t("manage.userForm.user74"),
      t("manage.userForm.user75"),
      {
        confirmButtonText: t("manage.userForm.user38"),
        cancelButtonText: t("manage.userForm.user37"),
        type: "warning",
      }
    ).then(() => {
      api.manage
        .batchDisable({
          userId: userId.value,
          deleteUserIds: ids.value,
        })
        .then((res) => {
          if (res.returnCode == 200) {
            message.success(t("manage.userForm.user76"));
            getUserTable();
            getUserTotal();
          }
        });
    });
  }
};
const showReset = ref(false);
const resetInfo = ref({});
// 重置密码
const handleReset = (row) => {
  showReset.value = true;
  resetInfo.value = row;
};
const closeReset = () => {
  showReset.value = false;
};
const newReset = ref(false); // 新密码展示
const resetArr = ref([]);
const submitReset = (data) => {
  showReset.value = false;
  api.manage.resetPassword(data).then((res) => {
    if (res.returnCode === 200) {
      newReset.value = true;
      resetArr.value = res.successUsers;
      message.success(t("manage.userForm.user68"));
    }
  });
};
const closeResetInfo = () => {
  newReset.value = false;
};
// 详情
const detailInfo = ref({});
const uploadInfo = ref({});
const showDetail = ref(false);
const handleDetail = (data) => {
  detailInfo.value = data;
  showDetail.value = true;
};
const handleCancel = () => {
  showDetail.value = false;
};
// 用户新增与修改
const showAdd = ref(false);
const formType = ref("add");
const handleAdd = (data) => {
  showDetail.value = false;
  uploadInfo.value = data;
  formType.value = "edit";
  showAdd.value = true;
};

const addUser = () => {
  showAdd.value = true;
  uploadInfo.value = {
    sex: 0,
  };
  formType.value = "add";
};
const newData = ref({
  groupIds: [],
});
const uploadUser = (data) => {
  data.groupIds = data.groups.map((item) => item.groupId);
  formType.value = "edit";
  uploadInfo.value = data;
  showAdd.value = true;
};
// 关闭编辑
const handleCloseUser = () => {
  showAdd.value = false;
  getUserTable();
};
// 保存编辑
const handleAddUser = (form) => {
  let forms = JSON.parse(JSON.stringify(form));
  forms.userId = userId.value;
  showAdd.value = false;
  api.manage.editUser(forms).then((res) => {
    if (res.returnCode === 200) {
      message.success(t("manage.userForm.user77"));
      getUserTable();
      getUserTotal();
    }
  });
};
// 新增用户确定
const addUserBtn = () => {
  showAdd.value = false;
  api.manage.addUser(uploadInfo.value).then((res) => {
    if (res.returnCode === 200) {
      message.success(t("manage.userForm.user78"));
      getUserTable();
      getUserTotal();
    }
  });
};
// 更多操作
const changeMore = (key, item) => {
  if (key === 1) {
    uploadUser(item);
  } else if (key === 2) {
    multipleSelection.value = [item.userId];
    api.manage
      .batchDisable({
        userId: userId.value,
        deleteUserIds: [item.userId],
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("manage.userForm.user76"));
          getUserTable();
          getUserTotal();
        }
      });
  } else if (key === 3) {
    api.manage
      .enable({
        userId: userId.value,
        reuseUserIds: [item.userId],
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("manage.userForm.user80"));
          getUserTable();
          getUserTotal();
        }
      });
  }
};
// 批量导入导出
const showExport = ref(false);
const showExportBtn = () => {
  showExport.value = true;
};
// 关闭批量导入导出
const handleCloneExport = () => {
  showExport.value = false;
};
</script>
<template>
  <div class="userTable">
    <div class="dashboard">
      <div class="companyName">{{ tableName }}</div>
      <div class="companyNum">
        {{ $t("manage.userForm.user81") }}
        <span class="num">{{ userTotal }}</span>
      </div>
      <div class="companyNum">
        {{ $t("manage.userForm.user82")
        }}<span class="num">{{ activeTotal }}</span>
      </div>
    </div>
    <div class="search">
      <div class="searchLeft">
        <!-- 状态 -->
        <div class="searchItem">
          <el-select v-model="status" clearable @change="statusBtn">
            <template #label="{ label }">
              <div style="display: flex; justify-content: space-between">
                <div>{{ $t("manage.userForm.isActive") }} :</div>
                <div>{{ label }}</div>
              </div>
            </template>
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
              <span class="dropText">{{ item.label }}</span>
            </el-option>
          </el-select>
        </div>
        <!-- 展示全部用户 -->
        <div class="searchItem">
          <el-select v-model="allUser" clearable>
            <el-option
              v-for="(item, index) in allUserList"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
              <span class="dropText">{{ item.label }}</span>
            </el-option>
          </el-select>
        </div>
        <div class="searchDiv2" @click="tabelTypeBtn">
          <div class="toolImg">
            <img src="@/assets/manage/table.svg" alt="" />
          </div>
        </div>
      </div>
      <!-- 功能区 -->
      <div style="display: flex">
        <el-button
          class="toolBox"
          style="color: #f02d63"
          @click="deleteAll"
          :disabled="$hasPermission('10009') == false"
          v-if="userTable.departmentId"
        >
          {{ $t("manage.userForm.user83") }}
        </el-button>
        <el-button
          class="toolBox"
          @click="showExportBtn"
          :disabled="$hasPermission('10005') == false"
          v-if="userTable.departmentId"
        >
          {{ $t("manage.userForm.user84") }}
        </el-button>
        <el-button
          class="toolBox addUser"
          @click="addUser"
          :disabled="$hasPermission('10005') == false"
          v-if="userTable.departmentId"
        >
          {{ $t("manage.userForm.user85") }}
        </el-button>
      </div>
    </div>
    <el-table
      :data="tableMember"
      @selection-change="handleSelectionChange"
      class="tableHeight"
    >
      <el-table-column type="selection" :selectable="selectable" />
      <el-table-column
        v-if="tabelType.userName"
        prop="userName"
        :label="$t('manage.userForm.userName')"
      >
        <template #default="scope">
          <el-tooltip popper-class="toolTip" :content="scope.row.userName">
            <div class="wid100">{{ scope.row.userName }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tabelType.isActive"
        prop="isActive"
        :label="$t('manage.userForm.user86')"
      >
        <template #default="scope">
          <el-tag type="primary" v-if="scope.row.isActive == true">{{
            t("manage.userForm.user52")
          }}</el-tag>
          <el-tag type="danger" v-else>{{
            t("manage.userForm.user53")
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tabelType.email"
        prop="email"
        :label="$t('manage.userForm.email')"
      >
        <template #default="scope">
          <el-tooltip popper-class="toolTip" :content="scope.row.email">
            <div class="wid100">{{ scope.row.email }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tabelType.departmentName"
        prop="departmentName"
        :label="$t('manage.userForm.departmentName')"
      >
        <template #default="scope">
          <el-tooltip
            popper-class="toolTip"
            :content="scope.row.departmentName"
          >
            <div class="wid100">{{ scope.row.departmentName }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tabelType.address"
        prop="address"
        :label="$t('manage.userForm.address')"
      >
        <template #default="scope">
          <el-tooltip popper-class="toolTip" :content="scope.row.address">
            <div class="wid100">{{ scope.row.address }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tabelType.phone"
        prop="phone"
        :label="$t('manage.userForm.phone')"
      >
        <template #default="scope">
          <el-tooltip popper-class="toolTip" :content="scope.row.phone">
            <div class="wid100">{{ scope.row.phone }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tabelType.roleName"
        prop="roleName"
        :label="$t('manage.userForm.roleName')"
      >
        <template #default="scope">
          <el-tooltip popper-class="toolTip" :content="scope.row.roleName">
            <div class="wid100">{{ scope.row.roleName }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tabelType.sex"
        prop="sex"
        :label="$t('manage.userForm.sex')"
      >
        <template #default="scope">
          <el-tag v-if="scope.row.sex == 0" type="warning">{{
            t("manage.userForm.user54")
          }}</el-tag>
          <el-tag v-if="scope.row.sex == 1" type="success">
            {{ t("manage.userForm.user55") }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        v-if="tabelType.operation"
        :label="$t('manage.userForm.operation')"
        class="operation"
        fixed="right"
        width="200"
      >
        <template #default="scope">
          <el-button
            link
            type="primary"
            size="small"
            @click="handleDetail(scope.row)"
            :disabled="
              scope.row.userId == 1000000 || $hasPermission('10003') == false
            "
          >
            {{ $t("manage.userForm.user17") }}
          </el-button>
          <el-button
            link
            type="primary"
            size="small"
            @click="handleReset(scope.row)"
            :disabled="
              scope.row.userId == 1000000 || $hasPermission('10007') == false
            "
          >
            {{ $t("manage.userForm.user65") }}
          </el-button>
          <el-dropdown
            @command="changeMore($event, scope.row)"
            :disabled="scope.row.userId == 1000000"
            trigger="click"
            class="changeMore"
          >
            <div class="moreIcon">
              <img src="@/assets/base/more.svg" alt="" />
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                {{ scope.row.id }}
                <el-dropdown-item
                  :disabled="$hasPermission('10007') == false"
                  :command="1"
                >
                  <span class="dropText">{{
                    $t("manage.userForm.user63")
                  }}</span>
                </el-dropdown-item>
                <el-dropdown-item
                  :command="2"
                  style="color: #f02d63"
                  :disabled="$hasPermission('10009') == false"
                  v-if="
                    scope.row.isActive == true && scope.row.userId !== 1000000
                  "
                >
                  <span class="dropText">
                    {{ $t("manage.userForm.user87") }}</span
                  >
                </el-dropdown-item>
                <el-dropdown-item
                  :command="3"
                  style="color: #f02d63"
                  :disabled="$hasPermission('10009') == false"
                  v-if="scope.row.isActive == false"
                >
                  <span class="dropText">{{
                    $t("manage.userForm.user82")
                  }}</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :background="true"
      :currentPage="currentPage"
      :pageSize="pageSize"
      :total="totalItems"
      @size-change="updatePageSize"
      @current-change="updateCurrentPage"
      class="Pagination"
    />
  </div>
  <div>
    <el-dialog
      destroy-on-close
      :z-index="100"
      v-model="showDialog"
      :show-close="false"
      class="dialog"
      align-center
    >
      <template #header="">
        <div class="my-header">
          <div>{{ $t("manage.userForm.user88") }}</div>
          <el-icon
            class="el-icon--left"
            :class="'close-icon'"
            @click="showDialog = false"
            style="cursor: pointer"
          >
            <Close />
          </el-icon>
        </div>
      </template>
      <div v-for="(item, key, index) in tabelType" :key="index">
        <el-checkbox
          :value="item"
          :checked="item"
          @change="handleChange($event, key)"
        >
          {{ $t("manage.userForm." + key) }}
        </el-checkbox>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="showDialog = false">
            {{ $t("manage.userForm.user37") }}
          </el-button>
          <el-button type="primary" @click="handleConfirm">
            {{ $t("manage.userForm.user38") }}
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
  <!-- 用户详情 -->
  <detailView
    :showDetail="showDetail"
    @handleCancel="handleCancel"
    @handleAdd="handleAdd"
    :detailInfo="detailInfo"
  >
  </detailView>
  <!-- 用户新增与修改 -->
  <addView
    :showAdd="showAdd"
    :formType="formType"
    @handleCloseUser="handleCloseUser"
    @handleAddUser="handleAddUser"
    :uploadInfo="uploadInfo"
    @addUserBtn="addUserBtn"
  >
  </addView>
  <!-- 批量导入导出 -->
  <Export
    :showExport="showExport"
    @handleCloneExport="handleCloneExport"
  ></Export>
  <!-- 密码重置 -->
  <userReset
    :showReset="showReset"
    :resetInfo="resetInfo"
    @closeReset="closeReset"
    @submitReset="submitReset"
  >
  </userReset>
  <!-- 密码重置后展示的弹窗 -->
  <userResetInfo
    :newReset="newReset"
    :resetArr="resetArr"
    @closeResetInfo="closeResetInfo"
  ></userResetInfo>
</template>

<style scoped lang="scss">
.search {
  :deep(.el-select__wrapper) {
    border-radius: 16px !important;
    height: 48px;
    width: 160px !important;
    font-size: 16px !important;
  }
}

:deep(.el-checkbox) {
  height: 30px !important;
}

:deep(.el-dialog) {
  --el-dialog-width: 600px;
  border-radius: 30px;
  padding: 30px;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Medium";
}

.searchItem {
  margin-right: 10px;
}

.operation {
  width: 200px;
}

.tableHeight {
  height: calc(100% - 170px);
}

.dialog {
  width: 200px;
}

.changeMore {
  margin-left: 5px;

  .moreIcon {
    width: 24px;
    height: 24px;

    img {
      display: block;
      width: 16px;
      height: 16px;
      margin: 4px auto;
    }
  }
}

.Pagination {
  margin-top: 20px;
}

.dashboard {
  margin-top: 20px;
  margin-bottom: 8px;
  display: flex;
  position: relative;

  .companyName {
    font-size: 22px;
    color: #3d3d3d;
    margin-right: 32px;
    font-weight: 600;
  }

  .companyNum {
    font-size: 18px;
    color: #8a8a8a;
    margin-right: 16px;
    margin-top: 4px;

    .num {
      color: #3d3d3d;
    }
  }
}

.search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;

  .searchLeft {
    display: flex;

    .date {
      height: 44px;
      line-height: 44px;
      border: #edeef6 solid 2px;
      border-radius: 14px;
      font-size: 18px;
      display: flex;
      font-size: 18px;
      text-align: center;
      padding: 0 18px;
      cursor: pointer;

      div:first-child {
        margin-right: 10px;
        color: #8a8a8a;
      }
    }
  }
}

.ellipsis {
  font-size: 16px;
  min-width: 44px;
}

.tableWidth {
  width: 40px;
}

.tableWidth1 {
  width: 10px;
}

.searchDiv {
  border: #edeef6 solid 2px;
  border-radius: 16px;
  font-size: 16px;
  padding: 0 10px;
  margin-left: 10px;
  cursor: pointer;
}

.toolBox {
  border: #edeef6 solid 2px;
  border-radius: 16px;
  font-size: 16px;
  padding: 0 10px;
  height: 42px;
  line-height: 40px;
  color: #8a8a8a;
  margin-right: 10px;
  cursor: pointer;
}

.searchSpan {
  color: #3d3d3d;
  font-size: 16px;
  width: 100%;
  display: flex;
  margin-top: 13px;
}

.searchIcon {
  margin-left: 10px;
}

.searchDiv2 {
  border: #edeef6 solid 2px;
  border-radius: 16px;
  font-size: 16px;
  padding: 6px;
  cursor: pointer;

  .toolImg {
    width: 32px;
    height: 32px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.searchInput {
  margin-right: 10px;
  height: 44px;
}

.addUser {
  background-color: #0256ff;
  color: #fff;
  margin-right: 0;
}
.userTable {
  height: calc(100% - 20px);
}
.wid100 {
  width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.dropText {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
  padding-left:10px
}
</style>
