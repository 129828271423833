export default {
    CN: {
        chat: '新的对话',
        index: '主页',
        base: 'AI知识库',
        baseDetails: 'AI知识库',
        share: '知识共享',
        shareDetails: '知识共享',
        shareView: '知识共享',
        bot: 'AI智能体',
        statistics: '仪表盘',
        manage: '平台管理',
        login: '登录',
        lookAll: '查看全部',
        recentTopics: '最近话题',
        index1: '需要我为你做些什么呢？',
        index2: '为您推荐',
        index3: '知识共享',
        index4: '长期',
        index5: '更多智能体',
        index6: '探索新知识，验证新话题',
        index7: '今日剩余资源',
        index8: '当前统计模型',
        index9: '消耗',
        index10: '令牌',
        index11: '来源',
        index12: '最多',
        index13: '生成',
        index14: '幅图像',
        index15: '用户日配额',
        index16: '上传',
        index17: '个文件',
        index18: '幅图',
        wordTool: "文档翻译",
        audioTool: "智能音频",
        imageTool: "图片转文档",
        billTool: "PDF文字提取",
    },
    HK: {
        chat: '新嘅对话',
        index: '主页',
        base: 'AI知识库',
        baseDetails: 'AI知识库',
        share: '知识共享',
        shareDetails: '知识共享',
        shareView: '知识共享',
        bot: 'AI智能体',
        statistics: '仪表盘',
        manage: '平台管理',
        login: '登录',
        lookAll: '查看全部',
        recentTopics: '最近话题',
        index1: '需要我为你做些什么呢？',
        index2: '为您推荐',
        index3: '知识共享',
        index4: '长期',
        index5: '更多智能体',
        index6: '探索新知识，验证新话题',
        index7: '今日剩余资源',
        index8: '当前统计模型',
        index9: '消耗',
        index10: '令牌',
        index11: '来源',
        index12: '最多',
        index13: '生成',
        index14: '幅图像',
        index15: '用户日配额',
        index16: '上传',
        index17: '个文件',
        index18: '幅图',
        wordTool: "文档翻译",
        audioTool: "智能音频",
        imageTool: "图片转文档",
        billTool: "PDF文字提取",
    },
    US: {
        chat: 'New Conversation',
        index: 'Home',
        base: 'AI Knowledge Base',
        baseDetails: 'AI Knowledge Base',
        share: 'Knowledge Sharing',
        shareDetails: 'Knowledge Sharing',
        shareView: 'Knowledge Sharing',
        bot: 'AI Bot',
        statistics: 'Dashboard',
        manage: 'Platform Management',
        login: 'Login',
        lookAll: 'View All',
        recentTopics: 'Recent Topics',
        index1: 'How can I assist you today?',
        index2: 'Recommendations for You',
        index3: 'Knowledge Sharing',
        index4: 'Long-term',
        index5: 'More Bots',
        index6: 'Explore New Knowledge, Verify New Topics',
        index7: 'Resources Remaining for Today',
        index8: 'Current Statistical Model',
        index9: 'Consumed',
        index10: 'Tokens',
        index11: 'Source',
        index12: 'Maximum',
        index13: 'Generated',
        index14: 'Images',
        index15: 'Daily User Quota',
        index16: 'Upload',
        index17: 'Files',
        index18: 'Images',
        wordTool: "Document Translation",
        audioTool: "Intelligent Audio",
        imageTool: "Image to Document",
        billTool: "PDF Text Extraction",
    },
};