import axios from "../server";
export default {
    // 部门查询
    getGroupList(data: any) {
        return axios({
            url: "/user-management/department/list",
            method: "POST",
            data,
        });
    },
    // 部门查询用户接口
    getUserList(data: any) {
        return axios({
            url: "/user-management/user/view",
            method: "POST",
            data,
        });
    },
    // 查看部门用户数
    getUserTotal(data: any) {
        return axios({
            url: "/user-management/user/count",
            method: "POST",
            data,
        });
    },
    // 获取部门（树形）列表
    getDepartmentList(data: any) {
        return axios({
            url: "/user-management/department/view",
            method: "POST",
            data,
        });
    },
    // 获取角色（树形）列表
    queryUserRole(data: any) {
        return axios({
            url: "/user-management/roles/view",
            method: "POST",
            data,
        });
    },
    // 获取群组（树形）列表
    queryGroupList(data: any) {
        return axios({
            url: "/user-management/usergroups/list",
            method: "POST",
            data,
        });
    },
    // 用户编辑
    editUser(data: any) {
        return axios({
            url: "/user-management/userInfo/update",
            method: "POST",
            data,
        });
    },
    // 部门删除
    deleteDepartment(data: any) {
        return axios({
            url: "/user-management/department/delete",
            method: "POST",
            data,
        });
    },
    // 部门名称修改
    updateDepartmentName(data: any) {
        return axios({
            url: "/user-management/department/name/edit",
            method: "POST",
            data,
        });
    },
    // 密码重置
    resetPassword(data: any) {
        return axios({
            url: "/user-management/user/reset_pwd",
            method: "POST",
            data,
        });
    },
    // 用户新增
    addUser(data: any) {
        return axios({
            url: "/user-management/user/add",
            method: "POST",
            data,
        });
    },
    // 批量禁用用户
    batchDisable(data: any) {
        return axios({
            url: "/user-management/user/delete",
            method: "POST",
            data,
        });
    },
    // 启用用户
    enable(data: any) {
        return axios({
            url: "/user-management/userInfo/reuse",
            method: "POST",
            data,
        });
    },
    // 部门新增
    addDepartment(data: any) {
        return axios({
            url: "/user-management/department/add",
            method: "POST",
            data,
        });
    },
    // 部门编辑
    editDepartment(data: any) {
        return axios({
            url: "/user-management/department/change",
            method: "POST",
            data,
        });
    },
    // 批量新增用户
    testFile(data: any,apiId: any) {
        return axios({
            url: "/user-management/batch-user/add",
            method: "POST",
            headers: {
                apiType: 'file',
                apiId: JSON.stringify(apiId),
            },
            data
        });
    },
    // ===============群组管理===============
    // 群组查询
    getGroupListApi(data: any) {
        return axios({
            url: "/user-management/usergroups/search",
            method: "POST",
            data,
        });
    },
    // 群组table
    getGroupTableApi(data: any) {
        return axios({
            url: "/user-management/usergroups/query",
            method: "POST",
            data,
        });
    },
    // 查看单个群组
    getGroupInfoApi(data: any) {
        return axios({
            url: "/user-management/usergroups/query",
            method: "POST",
            data,
        });
    },
    // 查看用户组
    getGroupDataApi(data: any) {
        return axios({
            url: "/user-management/usergroups",
            method: "GET",
            data,
        });
    },
    // 新增群组
    addGroupApi(data: any) {
        return axios({
            url: "/user-management/usergroups",
            method: "POST",
            data,
        });
    },
    // 编辑群组
    editGroupApi(data: any, groupId: any) {
        return axios({
            url: `/user-management/usergroups/${groupId}`,
            method: "PUT",
            data,
        });
    },
    // 移除群组
    deleteGroupApi(data: any, groupId: any) {
        return axios({
            url: `/user-management/usergroups/${groupId}/remove_users`,
            method: "POST",
            data,
        });
    },
    // 解散群组
    deleteGroupAllApi(data: any, groupId: any) {
        return axios({
            url: `/user-management/usergroups/${groupId}/delete`,
            method: "POST",
            data,
        });
    },
    // 群组增加用户
    addGroupUserApi(data: any, groupId: any) {
        return axios({
            url: `/user-management/usergroups/${groupId}/add_users`,
            method: "POST",
            data,
        });
    },
    // ===========角色管理============
    // 角色查询
    getRoleListApi(data: any) {
        return axios({
            url: "/user-management/roles/view",
            method: "POST",
            data,
        });
    },
    // 权限树
    getRoleTreeApi(data: any) {
        return axios({
            url: `/user-management/roles/tree`,
            method: "POST",
            data,
        });
    },
    // 角色禁用
    roleDisableApi(data: any) {
        return axios({
            url: "/user-management/roles/enable",
            method: "POST",
            data,
        });
    },
    // 角色编辑
    roleEditApi(data: any) {
        return axios({
            url: "/user-management/roles/change",
            method: "POST",
            data,
        });
    },
    // 角色新增
    roleAddApi(data: any) {
        return axios({
            url: "/user-management/roles/add",
            method: "POST",
            data,
        });
    },
    // 角色删除
    roleDeleteApi(data: any) {
        return axios({
            url: "/user-management/roles/delete",
            method: "POST",
            data,
        });
    },
    // 角色新增用户
    roleAddUserApi(data: any) {
        return axios({
            url: "/user-management/roles/user/add",
            method: "POST",
            data,
        });
    },
    // 角色详情移除用户
    roleRemoveUserApi(data: any) {
        return axios({
            url: "/user-management/roles/user/delete",
            method: "POST",
            data,
        });
    },
    // =======资源管理=======
    // 资源查询
    getResourceListApi(data: any) {
        return axios({
            url: "/configuration-set-future/resource/block/query",
            method: "POST",
            data,
        });
    },
    // 资源树
    getResourceTree(data: any) {
        return axios({
            url: "/configuration-set-future/resource/query",
            method: "POST",
            data,
        });
    },
    // 资源禁用
    resourceDisableApi(data: any) {
        return axios({
            url: "/configuration-set-future/resource/block/batch",
            method: "POST",
            data,
        });
    },
    // 资源启用
    resourceEnableApi(data: any) {
        return axios({
            url: "/configuration-set-future/resource/block/remove",
            method: "POST",
            data,
        });
    },
    // 查看白名单
    getWhiteListApi(data: any) {
        return axios({
            url: "/user-management/rule",
            method: "GET",
            data,
        });
    },
    // 查看单个白名单
    getSingleWhiteListApi(data:any,ruleId: any) {
        return axios({
            url: `/user-management/rule/${ruleId}`,
            method: "GET",
            data,
        });
    },
    // 配额编辑
    quotaEditApi(data: any) {
        return axios({
            url: "/user-management/department/resource/edit",
            method: "POST",
            data,
        });
    },
    // 禁用/启用当前资源平台
    enableApi(data: any) {
        return axios({
            url: "/configuration-set-future/resource/enable",
            method: "POST",
            data,
        });
    },
    // 指定资源平台状态
    resourceStatusApi(data: any) {
        return axios({
            url: "/configuration-set-future/resource/status",
            method: "POST",
            data,
        });
    },
    // 修改白名单
    editWhiteListApi(data: any, ruleId: any) {
        return axios({
            url: `/user-management/rule/${ruleId}`,
            method: "PUT",
            data,
        });
    },
    // 白名单新增
    addWhiteListApi(data: any) {
        return axios({
            url: "/user-management/rule",
            method: "POST",
            data,
        });
    },
    // 白名单批量禁用
    allWhiteListApi(data: any) {
        return axios({
            url: "/user-management/rule/batch/toggle",
            method: "PUT",
            data,
        });
    },
    // 白名单批量删除
    delWhiteListApi(data: any) {
        return axios({
            url: "/user-management/rule/batch/delete",
            method: "POST",
            data,
        });
    },
    // 给白名单添加组
    addWhiteListGroupApi(data: any, rule_id: any) {
        return axios({
            url: `/user-management/rule/${rule_id}/groups/add`,
            method: "POST",
            data,
        });
    },
    // =============知识模块=============
    // 话题查询
    getTopicListApi(data: any) {
        return axios({
            url: "/management/session/management/query",
            method: "POST",
            data,
        });
    },
    // 话题归档
    getTopicArchiveApi(data: any) {
        return axios({
            url: "/management/session/management/delete",
            method: "POST",
            data,
        });
    },
    // 智能体查询
    getAgentListApi(data: any) {
        return axios({
            url: "/management/template/management/query",
            method: "POST",
            data,
        });
    },
    // 智能体归档
    getAgentArchiveApi(data: any) {
        return axios({
            url: "/management/template/management/delete",
            method: "POST",
            data,
        });
    },
    // 知识库管理
    getKnowledgeListApi(data: any) {
        return axios({
            url: "/management/knowledge/management/query",
            method: "POST",
            data,
        });
    },
    // 知识库归档
    getKnowledgeArchiveApi(data: any) {
        return axios({
            url: "/management/knowledge/management/delete",
            method: "POST",
            data,
        });
    },
    // =============基础配置=============   
    // 基础配置资源组查询
    getBaseListApi(data: any) {
        return axios({
            url: "/configuration-set-future/resource-groups/query",
            method: "POST",
            data,
        });
    },
    resourceBy(data: any) {
        return axios({
            url: "configuration-set-future/resource-groups/query/by/resource",
            method: "POST",
            data,
        });
    },
    // 单个资源组详情
    getBaseDetailApi(data: any) {
        return axios({
            url: "/configuration-set-future/resource-group/desc",
            method: "POST",
            data,
        });
    },
    // 单个资源组修改
    updateBaseApi(data: any) {
        return axios({
            url: "/configuration-set-future/configuration/detail/update",
            method: "POST",
            data,
        });
    },
    // 资源组添加
    addBaseApi(data: any) {
        return axios({
            url: "/configuration-set-future/resource-group/add",
            method: "POST",
            data,
        });
    },
    // 资源组占用情况
    getBaseOccupyApi(data: any) {
        return axios({
            url: "/configuration-set-future/resource-department-mapper-relate/view",
            method: "POST",
            data,
        });
    },
    // 资源组部门变更
    changeBaseApi(data: any) {
        return axios({
            url: "/configuration-set-future/resource-group/departments/change",
            method: "POST",
            data,
        });
    },
    // 配置下拉框
    getBaseSelectApi(data: any) {
        return axios({
            url: `/configuration-set-future/${data}/model-type/get`,
            method: "GET",
        });
    },
    // 配置详情查看
    lookBaseDetailApi(data: any) {
        return axios({
            url: "/configuration-set-future/configuration/detail/desc",
            method: "POST",
            data,
        });
    },
    // redis配置查询
    getBaseRedisApi(data: any) {
        return axios({
            url: "/configuration-set/redis-memory/view",
            method: "POST",
            data,
        });
    },
    // redis配置修改
    changeBaseRedisApi(data: any) {
        return axios({
            url: "/configuration-set/redis-memory/set",
            method: "POST",
            data,
        });
    },
    // 个人话题时长查询
    getBaseTimeApi(data: any) {
        return axios({
            url: "/configuration-set-future/azure/reminder/view",
            method: "POST",
            data,
        });
    },
    // 个人话题时长修改
    changeBaseTimeApi(data: any) {
        return axios({
            url: "/configuration-set-future/azure/reminder/set",
            method: "POST",
            data,
        });
    },
    // 查看百度账单
    getBaseBaiduApi(data: any) {
        return axios({
            url: "/configuration-set/baidu/view",
            method: "POST",
            data,
        });
    },
    // 修改百度账单
    changeBaseBaiduApi(data: any) {
        return axios({
            url: "//configuration-set/baidu/set",
            method: "POST",
            data,
        });
    },
    // 查看阿里账单
    getBaseAliApi(data: any) {
        return axios({
            url: "/configuration-set/ali/view",
            method: "POST",
            data,
        });
    },
    // 修改阿里账单
    changeBaseAliApi(data: any) {
        return axios({
            url: "/configuration-set/ali/set",
            method: "POST",
            data,
        });
    },
    // 查看Azure账单
    getBaseBillApi(data: any) {
        return axios({
            url: "/configuration-set/azure/view",
            method: "POST",
            data,
        });
    },
    // 修改Azure账单
    changeBaseBillApi(data: any) {
        return axios({
            url: "/configuration-set/azure/set",
            method: "POST",
            data,
        });
    },
    // Azure配置增加接口
    addAzureBillApi(data: any) {
        return axios({
            url: "/configuration-set-future/Azure/configuration/add",
            method: "POST",
            data,
        });
    },
    // ali配置增加接口
    addAliBillApi(data: any) {
        return axios({
            url: "/configuration-set-future/Ali/configuration/add",
            method: "POST",
            data,
        });
    },
    // baidu 配置增加接口
    addBaiduBillApi(data: any) {
        return axios({
            url: "/configuration-set-future/Baidu/configuration/add",
            method: "POST",
            data,
        });
    },
    // 设置默认配置
    setDefaultConfigApi(data: any) {
        return axios({
            url: "/configuration-set-future/configuration/default/set",
            method: "POST",
            data,
        });
    },
    //资源组名称修改
    updateGroupNameApi(data: any) {
        return axios({
            url: "/configuration-set-future/resource-group/update",
            method: "POST",
            data,
        });
    },
    // 资源组删除
    deleteGroupItemApi(data: any) {
        return axios({
            url: "/configuration-set-future/resource-group/delete",
            method: "POST",
            data,
        });
    },
    // 资源配置删除
    deleteConfigItemApi(data: any) {
        return axios({
            url: "/configuration-set-future/configuration/delete",
            method: "POST",
            data,
        });
    },
    // ========平台授权=========
    // 模型配置激活状态
    getBaseStatusApi(data: any) {
        return axios({
            url: "/configuration-set/licenseInfo/get",
            method: "POST",
            data,
        });
    },
    // 公司logo上传
    uploadLogoApi(data: any) {
        return axios({
            url: "/personal-page/company-image/set",
            method: "POST",
            data,
        });
    },
    // 获取公司logo
    getLogoApi(data: any) {
        return axios({
            url: "/personal-page/company-image/get",
            method: "POST",
            data,
        });
    }

}