
import axios from "../server";
export default {
    // 知识库列表
    customLibraryList(data: any) {
        return axios({
            url: "/custom-library/list",
            method: "POST",
            data,
        });
    },
    // 微模型新增
    customLibraryAdd(data: any) {
        return axios({
            url: "/custom-library/add",
            method: "POST",
            data,
        });
    },
    // 微模型详情
    customLibraryDesc(data: any) {
        return axios({
            url: "/custom-library/desc",
            method: "POST",
            data,
        });
    },
    // 智能体列表
    getBot(data: any) {
        return axios({
            url: "/memory-chat/template/query",
            method: "POST",
            data,
        });
    },
    // 智能体列表
    getBaseBot(data: any) {
        return axios({
            url: "memory-chat/template/roll/list",
            method: "POST",
            data,
        });
    },
    // 置顶
    basePin(data: any) {
        return axios({
            url: `/custom-library/pin`,
            method: "POST",
            data,
        });
    },
    // 取消置顶
    basePinCancel(data: any) {
        return axios({
            url: `/custom-library/pin/cancel`,
            method: "POST",
            data,
        });
    },
    // 删除知识库
    libraryDelete(data: any, id: any) {
        return axios({
            url: `/custom-library/${id}/delete`,
            method: "POST",
            data,
        });
    },
    // 退出知识库
    libraryExit(data: any, id: any) {
        return axios({
            url: `/custom-library/${id}/exit`,
            method: "POST",
            data,
        });
    },
    //文件列表
    queryFileList(data: any, id: any) {
        return axios({
            url: `/custom-library/${id}/folder/content/query`,
            method: "POST",
            data,
        });
    },
    // 模糊查询
    queryFolderFileCompanyApi(data: any, id: any) {
        return axios({
            url: `/custom-library/${id}/folder/query`,
            method: "POST",
            data,
        });
    },
    // 父级文件夹路径回溯
    parentTraceCompanyApi(data: any, id: any) {
        return axios({
            url: `/custom-library/${id}/folder/parent/trace`,
            method: "POST",
            data,
        });
    },
    // 文件精准查询
    accuracySearch(data: any, id: any) {
        return axios({
            url: `/custom-library/${id}/file/accuracy-search`,
            method: "POST",
            data,
        });
    },
    // 获取getPdf
    pdfView(data: any, id: any) {
        return axios({
            url: `/custom-library/${id}/pdfUrl/find`,
            method: "POST",
            data,
        });
    },
    // 获取text
    getText(knowledge_library_id: any, userId: any, id: any) {
        return axios({
            url: `/custom-library/${knowledge_library_id}/text/${userId}/${id}/download`,
            method: "GET",
        });
    },
    // 获取doc
    wordView(data: any, id: any) {
        return axios({
            url: `/custom-library/${id}/fileUrl/find`,
            method: "POST",
            data,
        });
    },
    // 获取Excel
    excelView(data: any, id: any) {
        return axios({
            url: `/custom-library/${id}/excel/view`,
            method: "POST",
            data,
        });
    },
    //新建文件夹
    createFolderCompanyApi(data: any, id: any) {
        return axios({
            url: `/custom-library/${id}/folder/create`,
            method: "POST",
            data,
        });
    },
    // 上传文件
    uploadFileCompanyApi(data: any, id: any, libraryId: any) {
        return axios({
            url: `/custom-library/${libraryId}/file/upload`,
            method: "POST",
            headers: {
                apiId: JSON.stringify(id),
                apiType: 'file'
            },
            data,
        });
    },
    // 文件单个暂停
    uploadSuspend(data: any, id: any) {
        return axios({
            url: `/custom-library/${id}/upload-task/suspend`,
            method: "POST",
            data,
        });
    },
    // 开始单个文件
    uploadContinue(data: any, id: any) {
        return axios({
            url: `/custom-library/${id}/suspend-upload-task/continue`,
            method: "POST",
            data,
        });
    },
    // 文件单个取消
    uploadDelete(data: any, id: any) {
        return axios({
            url: `/custom-library/${id}/upload-task/delete`,
            method: "POST",
            data,
        });
    },
    // 暂停所有
    uploadAllSuspend(data: any, id: any) {
        return axios({
            url: `/custom-library/${id}/all-upload-task/suspend`,
            method: "POST",
            data,
        });
    },
    // 取消所有
    uploadAllDelete(data: any, id: any) {
        return axios({
            url: `/custom-library/${id}/all-upload-task/delete`,
            method: "POST",
            data,
        });
    },
    // 开始所有
    uploadAllStart(data: any, id: any) {
        return axios({
            url: `/custom-library/${id}/all-suspend-upload-task/continue`,
            method: "POST",
            data,
        });
    },
    // 文字预览
    textView(data: any, id: any) {
        return axios({
            url: `/custom-library/${id}/fileContent/preview`,
            method: "POST",
            data,
        });
    },
    // 删除文件夹
    deleteFolder(data: any, id: any) {
        return axios({
            url: `/custom-library/${id}/folder/delete`,
            method: "POST",
            data,
        });
    },
    // 删除文件
    deleteFile(data: any, id: any) {
        return axios({
            url: `/custom-library/${id}/file/delete`,
            method: "POST",
            data,
        });
    },
    //   轮询
    taskView(data: any, id: any) {
        return axios({
            url: `/custom-library/${id}/file/add/task/view`,
            method: "POST",
            data,
        });
    },
    // 查询所有上传历史记录
    getHistoryFiles(data: any, id: any) {
        return axios({
            url: `/custom-library/${id}/task/history/query`,
            method: "POST",
            data,
        });
    },
    // 清除历史记录
    clearHistory(data: any, id: any) {
        return axios({
            url: `/custom-library/${id}/task/history/delete`,
            method: "POST",
            data,
        });
    },
    // 文件描述
    fileDesc(data: any, id: any) {
        return axios({
            url: `/custom-library/file/desc`,
            method: "POST",
            data,
        });
    },
    // 过期
    fileExpire(data: any, id: any) {
        return axios({
            url: `/custom-library/file/expire`,
            method: "POST",
            data,
        });
    },
    // 验证
    importantSet(data: any) {
        return axios({
            url: `/custom-library/file/important/set`,
            method: "POST",
            data,
        });
    },
    // 编辑保存
    baseUpdata(data: any) {
        return axios({
            url: `/custom-library/update`,
            method: "POST",
            data,
        });
    },
    // 微模型人数删除
    userDelete(data: any) {
        return axios({
            url: `/custom-library/user/type/delete`,
            method: "POST",
            data,
        });
    },
    // 微模型人数新增
    userAdd(data: any) {
        return axios({
            url: `custom-library/user/type/add`,
            method: "POST",
            data,
        });
    },
    // 权威话题
    verifyQuery(data: any, id: any) {
        return axios({
            url: `/custom-library/verify/${id}/query`,
            method: "POST",
            data,
        });
    },
    // 热门
    hotQuery(data: any) {
        return axios({
            url: `/knowledge/session/vote-up-session/query`,
            method: "POST",
            data,
        });
    },
    // 收藏
    likeQuery(data: any) {
        return axios({
            url: `/knowledge/session/collect-session/query`,
            method: "POST",
            data,
        });
    },
    // 收藏
    likeAllQuery(data: any) {
        return axios({
            url: `/chat/session/session/all/collect/query`,
            method: "POST",
            data,
        });
    },
    // 全部
    allQuery(data: any) {
        return axios({
            url: `knowledge/session/all-session/query`,
            method: "POST",
            data,
        });
    },
    // 微模型历史记录
    baseHistory(data: any) {
        return axios({
            url: `/knowledge/session/conversation/detail/query`,
            method: "POST",
            data,
        });
    },
    // chat 历史
    chatHistory(data: any) {
        return axios({
            url: `/chat/session/session/detail/query`,
            method: "POST",
            data,
        });
    },
    // 新增微模型会话
    addBase(data: any) {
        return axios({
            url: `custom-library/session/create`,
            method: "POST",
            data,
        });
    },
    // 微模型拖拽回显文件
    baseFileDesc(data: any) {
        return axios({
            url: `/knowledge/session/session/file/desc`,
            method: "POST",
            data,
        });
    },
    //   移动
    companyFileMove(data: any, id: any) {
        return axios({
            url: `/custom-library/${id}/file/move`,
            method: "POST",
            data,
        });
    },
    // 更改用户类型
    userTypeChange(data: any) {
        return axios({
            url: `/custom-library/user/type/change`,
            method: "POST",
            data,
        });
    },
    // ai生出图片
    imageGenerate(data: any) {
        return axios({
            url: `chat/v4/image/generate`,
            method: "POST",
            data,
        });
    },
    imgUpLoad(data: any, id: any) {
        return axios({
            url: `chat/v4/image/upload`,
            method: "POST",
            headers: {
                apiId: JSON.stringify(id),
                apiType: 'file'
            },
            data,
        });
    },
}