<template>
  <div class="layoutDiv">
    <el-container class="layoutDiv">
      <el-aside class="asideLayout" v-if="menuType">
        <!-- logo -->
        <div class="layoutLogo">
          <img v-if="imgUrl" :src="imgUrl" alt="" class="qcImg" />
          <img
            :src="logo"
            alt=""
            :class="imgUrl ? 'logoImgActive' : 'logoImg'"
          />
          <img :src="Vector" alt="" @click="changeMenu" class="vector" />
        </div>
        <div v-for="item in menuList" :key="item.key" @click="goItem(item)">
          <!-- 新对话 -->
          <div class="menuLeftDiv" v-if="item.name == 'chat'">
            <img class="menuIcon" :src="item.icon" alt="" />
            <div class="flexTop">
              <div class="noText">
                <el-tooltip
                  popper-class="toolTip"
                  :content="$t(`menu.${item.name}`)"
                  placement="bottom"
                >
                  {{ $t(`menu.${item.name}`) }}
                </el-tooltip>
              </div>
              <div class="topItemFont">
                <img :src="ctrl" alt="" class="topItemDiv" />
                <img :src="k" alt="" class="kIcon" />
              </div>
            </div>
          </div>
          <!-- 菜单 -->
          <div
            :class="
              menuActive == item.key && routeType != 'chat'
                ? 'menuItemDivActive'
                : 'menuItemDiv'
            "
            v-if="shouldDisplay(item) && item.name != 'chat'"
          >
            <img class="menuIcon" :src="item.icon" alt="" />
            <div class="itemName">
              <el-tooltip
                popper-class="toolTip"
                :content="$t(`menu.${item.name}`)"
                placement="bottom"
              >
                {{ $t(`menu.${item.name}`) }}
              </el-tooltip>
            </div>
          </div>
        </div>
        <el-divider class="hr" />
        <!-- 最近话题 -->
        <div class="topicDiv">
          <img :src="topicPng" alt="" class="menuIcon" />
          <div>{{ $t("menu.recentTopics") }}</div>
        </div>
        <div>
          <div
            v-for="(item, index) in topicList.slice(0, 4)"
            :key="item"
            @click="changeTopic(item, index)"
            class="topicItem"
          >
            <!-- :class="{
              topicActive:
                topicActive == index &&
                (routeType == 'chat' || menuActive == '/chat'),
            }" -->
            {{ item.sessionName }}
          </div>
          <div class="topicAll" @click="goAllTalk">
            {{ $t("menu.lookAll") }}
          </div>
        </div>
      </el-aside>
      <el-aside v-else class="menuTypeAside">
        <div class="sqTitle" v-if="!imgUrl">
          <img :src="dgn" alt="" class="dgn" />
          <img :src="Vector" alt="" class="sqIcon" @click="changeMenu" />
        </div>
        <div class="sqTitle1" v-else>
          <div class="pos">
            <img :src="imgUrl" alt="" class="sqqcImg" />
            <img :src="dgn" alt="" class="sqDgn" />
          </div>
          <img :src="Vector" alt="" class="sqIcon" @click="changeMenu" />
        </div>
        <div class="padding19">
          <!-- 新增话题 -->

          <div
            class="sqAdd"
            v-for="item in menuList.slice(0, 1)"
            :key="item.key"
            @click="goItem(item)"
          >
            <el-tooltip
              popper-class="toolTip"
              :content="$t(`menu.${item.name}`)"
              placement="right"
            >
              <img :src="item.icon" alt="" class="sqmenuIcon" />
            </el-tooltip>
          </div>
          <div class="sqItem">
            <div
              :class="
                shouldDisplay(item) && item.name != 'chat' ? 'sqItemDiv' : ''
              "
              v-for="item in menuList.slice(1)"
              :key="item.key"
              @click="goItem(item)"
            >
              <el-tooltip
                popper-class="toolTip"
                :content="$t(`menu.${item.name}`)"
                placement="right"
                v-if="shouldDisplay(item) && item.name != 'chat'"
              >
                <img :src="item.icon" alt="" class="sqmenuIcon" />
              </el-tooltip>
            </div>
          </div>

          <div class="sqTopic" @click="goAllTalk">
            <el-tooltip
              popper-class="toolTip"
              :content="$t(`menu.lookAll`)"
              placement="right"
            >
              <img :src="topicPng" alt="" class="sqmenuIcon" />
            </el-tooltip>
          </div>
        </div>
      </el-aside>
      <el-container>
        <el-header class="rightBg">
          <headerIndex></headerIndex>
        </el-header>
        <el-main class="rightBg">
          <div class="itemPage">
            <router-view />
          </div>
        </el-main>
        <el-footer class="rightBg">
          <fooderIndex></fooderIndex>
        </el-footer>
      </el-container>
    </el-container>
    <newChat v-if="chatDialog" @closeChat="closeChat" type="chat"></newChat>
  </div>
</template>

<script setup>
import logo from "@/assets/logo.svg";
import ctrl from "@/assets/menu/Ctrl.svg";
import topicPng from "@/assets/menu/topic.svg";
import qcImg from "@/assets/menu/QC.svg";
import k from "@/assets/menu/K.svg";
import { ref, onMounted, onBeforeUnmount, watch } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import headerIndex from "./header/index.vue";
import fooderIndex from "./footer/index.vue";
import Vector from "@/assets/menu/leftSs.svg";
import dgn from "@/assets/menu/dgn.svg";
import newChat from "@/components/newChat/index.vue";
import api from "@/api";
const store = useStore();
const router = useRouter();
const route = useRoute();
const menuActive = ref(store.state.menuActive); //标记
const menuList = ref(store.state.menuList); //菜单列表
const menuType = ref(store.state.menuType); //菜单状态
const userId = ref(store.state.userId);
const chatDialog = ref(false); //新话题弹窗
const topicActive = ref(store.state.topicActive); //最近话题选中值
const topicList = ref([]); //最近话题列表
const routeType = ref("menu");
const roleId = ref(store.state.roleId);
const roles = ref(store.state.roles);
const imgUrl = ref("");
watch(
  () => store.state.menuType,
  (newValue, oldValue) => {
    menuType.value = store.state.menuType;
  }
);
watch(
  () => store.state.menuActive,
  (newValue, oldValue) => {
    menuActive.value = store.state.menuActive;
  }
);
watch(
  () => store.state.topicActive,
  (newValue, oldValue) => {
    topicActive.value = store.state.topicActive;
  }
);

watch(
  () => route.path,
  (newValue, oldValue) => {
    routeType.value = route.meta.type;
    getLeftTopic();
  }
);
onMounted(() => {
  getLeftTopic();
  getLogo();
  // 监听键盘事件
  document.addEventListener("keydown", handleKeydown);
});
const getLogo = () => {
  api.manage
    .getLogoApi({
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode) {
        fetch(`/openai/chat/v4/image/get/${res.data}`)
          .then((response) => response.arrayBuffer())
          .then((data) => {
            // 创建 Blob 对象
            const blob = new Blob([data], { type: "image/png" });
            // 生成 URL
            imgUrl.value = URL.createObjectURL(blob);
          })
          .catch((error) => (imgUrl.value = qcImg));
      }
    });
};
const shouldDisplay = (item) => {
  if (item.name === "statistics") {
    return hasPermission("10025") || roleId.value == 4;
  } else if (item.name === "manage") {
    return hasPermission("10000") || roleId.value == 4;
  } else {
    return true;
  }
};
// 检查用户权限
const hasPermission = (permissionId) => {
  if (!roles.value || roles.value.length === 0) {
    return false;
  }
  for (const role of roles.value) {
    if (role.includes(permissionId)) {
      return true;
    }
  }
  return false;
};
// 获取左侧最近话题
const getLeftTopic = () => {
  api.header
    .chatAllQuery({
      userId: userId.value,
    })
    .then((res) => {
      topicList.value = res.data;
    });
};
onBeforeUnmount(() => {
  // 组件销毁时移除监听器
  document.removeEventListener("keydown", handleKeydown);
});
const handleKeydown = (event) => {
  //ctrl +k
  if (event.ctrlKey && event.key === "k") {
    event.preventDefault();
    chatDialog.value = true;
  }
};
// 伸缩菜单
const changeMenu = () => {
  menuType.value = !menuType.value;
  store.commit("SET_MENUTYPE", menuType.value);
};
// 跳转
const goItem = (item) => {
  if (item.name == "chat") {
    chatDialog.value = true;
    return;
  }
  menuActive.value = item.key;
  store.commit("SET_CHATTYPE", "chat");
  store.commit("SET_MENUACTIVE", item);
  router.push(item.key);
};
// 关闭新队徽
const closeChat = () => {
  chatDialog.value = false;
};
// 切换对话
const changeTopic = (item, index) => {
  store.commit("SET_CHATTYPE", item.sessionType == 1 ? "base" : "chat"); //到时候换动态
  store.commit("SET_TOPIC", item);
  topicActive.value = index;
  store.commit("SET_TOPICITEM", index);
  store.commit("SET_MENUACTIVE", {
    name: "chat",
    key: "/chat",
    icon: require("@/assets/menu/chat.svg"),
  });
  // 判断权威
  if (item.isValid == 1) {
    router.push({
      name: "chat",
      query: {
        type: "look",
      },
    });
  } else {
    router.push({
      name: "chat",
      query: {
        type: item.isOwner == 1 ? "chat" : "look",
      },
    });
  }
};
// 去最近话题 全部
const goAllTalk = () => {
  const item = {
    name: "lookAll",
    key: "/lookAll",
    type: 1,
    iconType: false,
    active: require("@/assets/header/lookAll.svg"),
  };
  goItem(item);
};
</script>

<style lang="less" scoped>
.layoutDiv {
  width: 100%;
  height: 100%;
}

.asideLayout {
  width: 320px;
  height: 100%;
}

.layoutLogo {
  width: calc(100% - 60px);
  height: 60px;
  display: flex;
  position: relative;
  align-items: center;
  padding: 20px 30px;
}
.logoImg {
  width: 143px;
  height: 56px;
  margin-right: 76px;
}
.logoImgActive {
  width: 100px;
  height: 39px;
  margin-right: 58px;
}
.logoIcon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(-20px, -50%);
  font-size: 19px;
  color: #c6c6c6;
}

.menuIcon {
  margin-right: 20px;
  width: 28px;
}

.menuLeftDiv {
  height: 34px;
  display: flex;
  align-items: center;
  margin: 12px 20px 12px 20px;
  border-radius: 15px;
  border: 1px solid #dbdfff;
  padding: 10px 20px;
  background-color: #eceeff;
  color: #0256ff;
  font-size: 20px;
  cursor: pointer;
  font-family: "Regular";
}

.menuItemDiv {
  height: 36px;
  display: flex;
  align-items: center;
  margin: 0px 20px;
  border-radius: 15px;
  padding: 10px 20px;
  color: #3d3d3d;
  font-size: 20px;
  cursor: pointer;
  font-family: "Regular";
  margin-bottom: 5px;
}

.menuItemDivActive {
  height: 36px;
  display: flex;
  align-items: center;
  margin: 0px 20px;
  border-radius: 15px;
  padding: 10px 20px;
  color: #3d3d3d;
  font-size: 20px;
  cursor: pointer;
  font-family: "Regular";
  background-color: #edeef6;
  margin-bottom: 5px;
}

.menuItemDiv:hover {
  background-color: #edeef6;
}

.rightBg {
  background-color: #f7f8ff;
  height: 70px;
}
:deep(.el-main) {
  padding: 10px 40px;
  height: calc(100% - 120px);
}
.itemPage {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: auto;
}
.hr {
  margin: 12px 40px;
  width: calc(100% - 80px);
}
.flexTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 45px);
}

.topItemFont {
  display: flex;
  align-items: center;
  color: #a3abec;
  font-size: 12px;
  font-family: "Regular";
}

.topItemDiv {
  margin-right: 10px;
  width: 36px;
}
.kIcon {
  width: 20px;
  height: 20px;
}
:deep(.el-header, .el-footer) {
  height: 70px !important;
  padding-left: 40px;
  padding-right: 72px;
}
.itemName {
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 45px);
}
.noText {
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.vector {
  cursor: pointer;
  width: 28px;
}
.topicDiv {
  height: 34px;
  display: flex;
  align-items: center;
  margin: 0px 20px;
  border-radius: 15px;
  padding: 10px 20px;
  color: #3d3d3d;
  font-size: 20px;
  cursor: pointer;
  font-family: "Regular";
  margin-bottom: 5px;
}
.topicItem {
  height: 26px;
  border-radius: 20px;
  padding: 12px 15px;
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  margin: 0px 20px 0px 62px;
  cursor: pointer;
  max-width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.topicAll {
  height: 26px;
  border-radius: 20px;
  padding: 12px 15px;
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Medium";
  margin: 0px 20px 0px 62px;
  cursor: pointer;
}
.topicActive {
  background-color: #edeef6;
}
.qcImg {
  width: 56px;
  height: 56px;
  margin-right: 18px;
}
.menuTypeAside {
  width: 138px;
  height: 100%;
  background-color: #f7f8ff;
}
.dgn {
  width: 36px;
  height: 36px;
}
.sqIcon {
  width: 28px;
  margin-left: 30px;
  cursor: pointer;
}
.sqTitle {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 27px;
}
.sqTitle1 {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 21px;
}
.sqAdd {
  width: 52px;
  height: 52px;
  background-color: #eceeff;
  border-radius: 16px;
  box-shadow: 0px 0px 8px 0px #a3acec;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  cursor: pointer;
}
.sqTopic {
  width: 52px;
  height: 52px;
  background-color: #fefefe;
  border-radius: 16px;
  box-shadow: 0px 0px 8px 0px #a3acec;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.sqmenuIcon {
  width: 28px;
}
.sqItem {
  width: 52px;
  border-radius: 16px;
  background-color: #fefefe;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  margin-bottom: 24px;
}
.sqItemDiv {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.padding19 {
  padding-left: 19px;
}
.sqqcImg {
  width: 48px;
  height: 48px;
}
.sqDgn {
  width: 24px;
  position: absolute;
  bottom: -8px;
  right: -12px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 0px 4px #ffffff;
}
.pos {
  position: relative;
}
</style>
