<template>
  <div
    v-for="(item, index) in props.list"
    :key="index"
    :class="{
      itemDiv: index == 0,
      itemBottomBorder: index != 0,
      cur: !props.deleteType,
    }"
    @click="goLookView(item)"
  >
    <div class="itemFont22">
      {{ item.title || item.sessionName }}
    </div>
    <div class="itemFont20">
      {{ item.firstAnswer || item.message }}
    </div>
    <div class="itemHeight36">
      <div class="flexCenter">
        <div
          class="itemTx"
          v-if="item.userName"
          :style="'background-color:' + getRandomColor1()"
        >
          {{ item.userName.slice(0, 1) }}
        </div>
        <el-tooltip
          popper-class="toolTip"
          class="box-item"
          :content="item.userName"
          v-if="item.userName"
        >
          <div class="itemFont20s">
            {{ item.userName }}
          </div>
        </el-tooltip>
        <img :src="topTime" alt="" class="itemTime" v-if="item.updateTime" />
        <div class="itemFont20s" v-if="item.updateTime">
          {{ getYear(item.updateTime) }}
        </div>
        <img
          :src="topYes"
          alt=""
          class="itemTime"
          v-if="props.type != 'yz' && item.isValid == 1"
        />
        <div class="itemFont20s" v-if="props.type != 'yz' && item.isValid == 1">
          {{
            item.expireDate == null
              ? t("base.base176")
              : getYear(item.expireDate)
          }}
        </div>
        <img
          :src="topic2"
          alt=""
          class="itemTime"
          v-if="
            props.type != 'yz' &&
            props.type != 'sq' &&
            item.result != null &&
            item.sessionType != 2
          "
        />
        <el-tooltip
          popper-class="toolTip"
          class="box-item"
          :content="String(item.result)"
          v-if="
            props.type != 'yz' &&
            props.type != 'sq' &&
            item.result != null &&
            item.sessionType != 2
          "
        >
          <div class="itemFont20s">
            {{ Math.floor(item.result) }}
          </div>
        </el-tooltip>
      </div>
      <div class="flexCenter">
        <div class="helloNext" v-if="item.knowledge">
          <img :src="detailsLogo" alt="" class="rightIcon" />
          <el-tooltip
            popper-class="toolTip"
            class="box-item"
            :content="item.knowledge.knowledgeName"
          >
            <div class="baseItem">{{ item.knowledge.knowledgeName }}</div>
          </el-tooltip>
        </div>
        <div class="helloNext" v-if="item.knowledge_name">
          <img :src="detailsLogo" alt="" class="rightIcon" />
          <el-tooltip
            popper-class="toolTip"
            class="box-item"
            :content="item.knowledge_name"
          >
            <div class="baseItem">{{ item.knowledge_name }}</div>
          </el-tooltip>
        </div>
        <div
          class="helloNext"
          v-if="(props.type == 'yz' || props.type == 'sq') && item.libraryName"
        >
          <img :src="detailsLogo" alt="" class="rightIcon" />
          <div>{{ item.libraryName }}</div>
        </div>
        <el-button
          class="yzBtn"
          v-if="props.type == 'yz'"
          @click.stop="goShare(item)"
        >
          <div>{{ t("base.base66") }}</div>
          <img class="jbImg" :src="jbImg" alt="" />
        </el-button>
        <div @click.stop="">
          <el-dropdown
            @command="changeDrop($event, item, index)"
            trigger="click"
            v-if="props.type != 'yz' && props.type != 'sq'"
          >
            <div class="moreDiv">
              <img :src="moreImg" alt="" class="moreImg" />
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  :command="1"
                  v-if="!props.deleteType && item.isCollected == 0"
                >
                  <div class="flexDrapCenter">
                    <img :src="xfscImg" alt="" class="drapListImg" />
                    <span class="drapText color1">{{ t("base.base100") }}</span>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item
                  :command="2"
                  v-if="!props.deleteType && item.isCollected == 1"
                >
                  <div class="flexDrapCenter">
                    <img :src="scNoSvg" alt="" class="drapListImg" />
                    <span class="drapText color2">{{ t("base.base198") }}</span>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item
                  :command="3"
                  v-if="!props.deleteType && item.isOwner == 1"
                >
                  <div class="flexDrapCenter">
                    <img :src="cmmImg" alt="" class="drapListImg" />
                    <span class="drapText color1">{{ t("base.base272") }}</span>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item
                  :command="4"
                  v-if="!props.deleteType && item.isOwner == 1"
                >
                  <div class="flexDrapCenter">
                    <img :src="hszImg" alt="" class="drapListImg" />
                    <span class="drapText color2">{{ t("base.base236") }}</span>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item :command="5" v-if="props.deleteType">
                  <div class="flexDrapCenter">
                    <img :src="hfgdImg" alt="" class="drapListImg" />
                    <span class="drapText color1">{{ t("base.base273") }}</span>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
  <div class="releaseDiv" v-if="saveDialog">
    <div class="flexBetween">
      <div class="dialogTitle">{{ t("base.base272") }}</div>
      <img class="closeImg" :src="closeFileView" alt="" @click="cancel" />
    </div>
    <el-input
      :placeholder="t('base.base274')"
      v-model="saveName"
      class="topicInput"
    />
    <div class="flexEnd">
      <el-button class="cancelBtn" @click="cancel">{{
        t("base.base45")
      }}</el-button>
      <el-button class="okBtn" @click="ok">{{ t("base.base122") }}</el-button>
    </div>
  </div>
</template>

<script setup>
import topTime from "@/assets/index/time.svg";
import topic2 from "@/assets/index/hot.svg";
import topYes from "@/assets/index/yes.svg";
import detailsLogo from "@/assets/base/detailsLogo.svg";
import moreImg from "@/assets/base/moreLook.svg";
import jbImg from "@/assets/look/jb.svg";
import hszImg from "@/assets/look/hsz.svg";
import hfgdImg from "@/assets/look/hfgd.svg";
import xfscImg from "@/assets/look/xfsc.svg";
import cmmImg from "@/assets/look/cmm.svg";
import scNoSvg from "@/assets/chat/noSc.svg";
import { ref } from "vue";
import { useStore } from "vuex";
import { getYear, getRandomColor1 } from "@/utils/utils";
import api from "@/api";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import closeFileView from "@/assets/base/closeFileView.svg";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const emit = defineEmits(["goShare", "goLookView", "reshList"]);
const props = defineProps(["type", "deleteType", "list", "like"]);
const store = useStore();
const router = useRouter();
const userId = ref(store.state.userId);
const saveName = ref("");
const saveDialog = ref(false);
const saveItem = ref({});
const cancel = () => {
  saveDialog.value = false;
};
const ok = () => {
  if (saveItem.value.sessionType == 1 || saveItem.value.knowledge_library_id) {
    api.chat
      .baseUpdataName({
        userId: userId.value,
        sessionId: saveItem.value.sessionId,
        sessionName: saveName.value,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("base.base275"));
          saveItem.value.sessionName = saveName.value;
          saveDialog.value = false;
        }
      });
  } else {
    api.chat
      .putLeftList({
        userId: userId.value,
        id: saveItem.value.sessionId,
        topicName: saveName.value,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("base.base275"));
          saveItem.value.sessionName = saveName.value;
          saveDialog.value = false;
        }
      });
  }
};
// 选择下拉
const changeDrop = (e, item, index) => {
  // 收藏话题 0收藏 1取消
  if (e == 1 || e == 2) {
    console.log(item);
    api.chat[item.sessionType != 1 ? "chatCollect" : "baseCollect"]({
      userId: userId.value,
      sessionId: item.sessionId,
      operationType: e == 1 ? 0 : 1,
    }).then((res) => {
      if (res.returnCode == 200) {
        message.success(e == 1 ? t("base.base220") : t("base.base15"));
        emit("reshList");
      }
    });
  } else if (e == 4 || e == 5) {
    let typeId;
    if (item.sessionType == 1 || item.knowledge_library_id) {
      typeId = 0;
    } else {
      typeId = 1;
    }
    api.chat
      .sessionDelete({
        userId: userId.value,
        sessionInfo: [
          {
            sessionId: item.sessionId,
            typeId: typeId,
          },
        ],
        operationType: e == 4 ? 0 : 2,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("base.base213"));
          props.list.splice(index, 1);
          // router.push({
          //   name: "lookAll",
          //   query: {
          //     type: 1,
          //   },
          // });
        }
      });
  } else if (e == 3) {
    console.log(item);
    saveName.value = item.sessionName;
    saveItem.value = item;
    saveDialog.value = true;
  }
};
// 去验证
const goShare = (item) => {
  emit("goShare", item);
};
// 去详情查看
const goLookView = (item) => {
  console.log(item);
  if (!props.deleteType) {
    emit("goLookView", item);
  }
};
</script>

<style lang="scss" scoped>
.itemDiv {
  height: 146px;
  padding: 18px 19px 21px 19px;
  border-top: 2px solid #edeef6;
  border-bottom: 2px solid #edeef6;
}
.itemDiv:hover {
  background-color: #f7f8ff;
}
.itemBottomBorder {
  height: 148px;
  padding: 18px 19px 21px 19px;
  border-bottom: 2px solid #edeef6;
}
.itemBottomBorder:hover {
  background-color: #f7f8ff;
}
.cur {
  cursor: pointer;
}
.itemFont22 {
  font-size: 22px;
  color: #3d3d3d;
  font-family: "Semibold";
  margin-bottom: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.itemFont20 {
  font-size: 20px;
  color: #8a8a8a;
  font-family: "Regular";
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 限制行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 56px;
}
.itemHeight36 {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.itemTx {
  width: 22px;
  height: 22px;
  margin-right: 6px;
  border-radius: 50%;
  font-size: 16px;
  color: #f7f8ff;
  text-align: center;
  font-family: "Regular";
  line-height: 22px;
}
.itemFont20s {
  font-size: 18px;
  font-family: "Regular";
  color: #8a8a8a;
  margin-right: 15px;
  max-width: 185px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.itemTime {
  width: 18px;
  margin: 0px 10px 0px 0px;
}
.helloNext {
  height: 36px;
  border: 1px solid #dbdfff;
  border-radius: 12px;
  padding: 4px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Medium";
  margin-right: 11px;
}
.rightIcon {
  width: 23px;
  margin-right: 6px;
}
.yzBtn {
  border-radius: 12px;
  height: 36px;
  margin-right: 14px;
  border: 0px;
  background-color: #eceeff;
  font-size: 18px;
  color: #0256ff;
  font-family: "Regular";
  display: flex;
  align-items: center;
  justify-content: center;
}
.yzBtn:hover {
  background-color: #dbdfff;
}
.jbImg {
  width: 11px;
}
.drapListImg {
  width: 25px;
  margin-right: 3px;
}
.drapText {
  font-size: 18px;
  font-family: "Regular";
}
.color1 {
  color: #0256ff;
}
.color2 {
  color: #f02d63;
}
.flexDrapCenter {
  display: flex;
  align-items: center;
}
.moreImg {
  width: 22px;
}
.moreDiv {
  display: flex;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #f7f8ff;
}
.releaseDiv {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 540px;
  background-color: #fefefe;
  border-radius: 30px;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  z-index: 110;
  padding: 30px;
}
.flexEnd {
  display: flex;
  justify-content: flex-end;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dialogTitle {
  font-size: 22px;
  color: #3d3d3d;
  font-family: "Medium";
}
.closeImg {
  width: 15px;
  cursor: pointer;
}
.cancelBtn {
  height: 48px;
  border: 2px solid #edeef6;
  color: #f02d63;
  border-radius: 16px;
  font-size: 20px;
  font-family: "Regular";
}
.okBtn {
  height: 48px;
  background-color: #3376ff;
  border-radius: 16px;
  border: 2px solid #3376ff;
  color: #fefefe;
  font-family: "Regular";
  font-size: 20px;
}
.topicInput {
  margin: 20px 0px;
  :deep(.el-input__wrapper) {
    height: 44px;
    border: 1px solid #edeef6;
    font-size: 18px;
    color: #8a8a8a;
    font-family: "Regular";
    border-radius: 16px;
  }
}
.contentLook {
  height: 100%;
  overflow: auto;
}
.baseItem {
  max-width: 220px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
