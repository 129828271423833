export default {
    CN: {
        // ... 其他部分保持不变
        login1: '企业租户',
        login2: '登录NextAI Platform',
        login3: 'SSO登录',
        login4: '找回密码',
        login5: '激活',
        login6: '返回',
        login7: '请输入您的账号',
        login8: '请输入您的密码',
        login9: '登录',
        login10: '请输入您的企业邮箱账号',
        login11: '下一步',
        login12: '请输入NextAI发送的验证码',
        login13: '重新发送',
        login14: '剩余',
        login15: '秒',
        login16: '再次输入新密码',
        login17: '完成',
        login18: '请输入您的激活码',
        login19: '我已阅读并同意',
        login20: '免责声明',
        login21: '前往激活',
        login22: '更多登录方式',
        login23: '发送成功',
        login24: '密码长度不能少于6位',
        login25: '两次密码不一致',
        login26: '重置密码成功',
        login27: '激活成功',
        login28: '登录成功',
        login29: 'NextAI平台服务协议',
        login30: '我已阅读',
        login31: '请先阅读并勾选免责声明',
        login32: '今日剩余资源中展示你所在资源组中的默认资源用量，你可以通过你所在部门信息，向资源管理员询问你所在的资源组及默认资源配置。',
        login33: '文本生成模型',
        login34: '回复模式',
        login35: '回复令牌消耗',
        login36: '提示词令牌消耗',
        login37: '用户提问令牌消耗',
        login38: '联网检索事件',
        login39: '时间',
        login40: '话题',
        login41:'该问答已被否决不可查看',
        login42:'正在上传中...',
        login43:'文档数量:',
        login44:'对话数量:',
        login45:'成员数量:',
        login46:'知识库详情',
        login47:'文件存储方式',
        login48:'云存储',
        login49:'本地存储',
    },
    HK: {
        login1: '企业租戶',
        login2: '登入NextAI Platform',
        login3: 'SSO登入',
        login4: '找回密碼',
        login5: '啟動',
        login6: '返回',
        login7: '請輸入您的帳號',
        login8: '請輸入您的密碼',
        login9: '登入',
        login10: '請輸入您的企業郵箱帳號',
        login11: '下一步',
        login12: '請輸入NextAI發送的驗證碼',
        login13: '重新發送',
        login14: '剩餘',
        login15: '秒',
        login16: '再次輸入新密碼',
        login17: '完成',
        login18: '請輸入您的啟動碼',
        login19: '我已閱讀並同意',
        login20: '免责声明',
        login21: '前往啟動',
        login22: '更多登入方式',
        login23: '發送成功',
        login24: '密碼長度不能少於6位',
        login25: '兩次密碼不一致',
        login26: '重置密碼成功',
        login27: '啟動成功',
        login28: '登入成功',
        login29: 'NextAI平台服務協議',
        login30: '我已閱讀',
        login31: '請先閱讀並勾選免责声明',
        login32: '今日剩餘資源中展示你所在資源組中的默認資源用量，你可以通過你所在部門資訊，向資源管理員詢問你所在的資源組及默認資源配置。',
        login33: '文本生成模型',
        login34: '回復模式',
        login35: '回復令牌消耗',
        login36: '提示詞令牌消耗',
        login37: '用戶提問令牌消耗',
        login38: '聯網檢索事件',
        login39: '時間',
        login40: '話題',
        login41:'該問答已被否決不可查看',
        login42:'正在上傳中...',
        login43:'文件數量:',
        login44:'對話數量:',
        login45:'成員數量:',
        login46: '知識庫詳情',
        login47: '文件存儲方式',
        login48: '雲存儲',
        login49: '本地存儲',
    },
    US: {
        login1: 'Enterprise Tenant',
        login2: 'Log in to NextAI Platform',
        login3: 'SSO Login',
        login4: 'Retrieve Password',
        login5: 'Activate',
        login6: 'Return',
        login7: 'Please enter your account',
        login8: 'Please enter your password',
        login9: 'Login',
        login10: 'Please enter your enterprise email account',
        login11: 'Next Step',
        login12: 'Please enter the verification code sent by NextAI',
        login13: 'Resend',
        login14: 'Remaining',
        login15: 'seconds',
        login16: 'Re-enter the new password',
        login17: 'Complete',
        login18: 'Please enter your activation code',
        login19: 'I have read and agree to',
        login20: 'Disclaimer',
        login21: 'Go to Activate',
        login22: 'More Login Methods',
        login23: 'Sent Successfully',
        login24: 'Password length must be at least 6 characters',
        login25: 'The two passwords do not match',
        login26: 'Password Reset Successful',
        login27: 'Activation Successful',
        login28: 'Login Successful',
        login29: 'NextAI Platform Service Agreement',
        login30: 'I have read',
        login31: 'Please read and check the Disclaimer first',
        login32: "Today's remaining resources display the default resource usage in your resource group. You can inquire about your resource group and default resource configuration by using your department information with the resource administrator.",
        login33: 'LLM model',
        login34: 'response mode',
        login35: 'response token/s',
        login36: 'query token/s',
        login37: 'prompt token/s',
        login38: 'internet search event/s',
        login39: 'time',
        login40: 'Topic',
        login41:'The Q&A has been denied and is not viewable',
        login42:'Upgrading...',
        login43:'Number of Documents:',
        login44:'Number of conversations.:',
        login45:'Number of members:',
        login46: 'knowledge base details',
        login47: 'How files are stored',
        login48: 'cloud storage',
        login49: 'local storage',
    },
};