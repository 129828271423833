<script setup>
import { ref, defineProps, defineEmits } from 'vue'
import { useI18n } from "vue-i18n";
const { t } = useI18n()
const props = defineProps({
    warningShow: Boolean,
});
const emit = defineEmits(['handleWarning','goClean']);
const form = ref({
    name: ''
});

const handleClose = () => {
    emit('handleWarning');
}
const handleSave = () => {
    emit('goClean', form.value);
};
</script>
<template>
    <div>
        <el-dialog :z-index="100" destroy-on-close v-model="props.warningShow" align-center :close-on-click-modal="false" :show-close="false">
            <template #header="">
                <div class="my-header">
                    <div>{{ $t('manage.baseForm.base60') }}</div>
                    <el-icon class="el-icon--left" :class="'close-icon'" @click="handleClose" style="cursor: pointer;">
                        <Close />
                    </el-icon>
                </div>
            </template>
            <div class="my-body">{{ $t('manage.baseForm.base61') }}</div>
            <template #footer>
                <div class="dialog-footer">
                    <div @click="handleClose" class="cancel">{{ $t('manage.baseForm.base5') }}</div>
                    <div @click="handleSave" class="confirm">{{ $t('manage.baseForm.base62') }}</div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<style scoped lang="scss">
:deep(.el-dialog) {
    --el-dialog-width: 460px;
    border-radius: 30px;
    padding: 20px;
}
:deep(.el-input__wrapper) {
    height: 38px !important;
    width: 380px !important;
    border-radius: 12px;
}
.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
    color: #3d3d3d;
    font-family: "Medium";
}
.close-icon {
    font-size: 20px;
}
.my-body{
    font-size: 16px;
    color: #8A8A8A;
}
.dialog-footer {
    display: flex;
    justify-content: end;
    font-size: 15px;

    .cancel {
        color: #F02D63;
        padding: 8px 20px;
        border-radius: 16px;
        border: 1px solid #EDEEF6;
        cursor: pointer;
    }

    .confirm {
        color: #fff;
        background-color: #3376FF;
        margin-left: 10px;
        padding: 8px 20px;
        border-radius: 16px;
        cursor: pointer;
    }
}
</style>