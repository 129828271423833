<template>
  <div
    :class="
      (props.historyDialog && props.rightType) || lookDialog
        ? 'bottomIcon'
        : 'flexCenter'
    "
  >
    <div class="center">
      <div class="fillCenter" v-if="props.item.answers.length > 1">
        <img
          :src="props.item.chatActive != 1 ? leftFill : leftNoFill"
          alt=""
          class="fillImg"
          @click="changeItemPage(false)"
        />
        <span
          :class="props.item.chatActive == 1 ? 'fillColor' : ''"
          class="fillText"
          >{{ props.item.chatActive }}</span
        >
        <span
          class="fillText"
          :class="
            props.item.chatActive == props.item.answers.length
              ? 'fillColor'
              : ''
          "
          >/{{ props.item.answers.length }}</span
        >
        <img
          :src="
            props.item.chatActive == props.item.answers.length
              ? rightNoFill
              : rightFill
          "
          alt=""
          class="fillImg"
          @click="changeItemPage(true)"
        />
      </div>
      <!--           props.item.answers[props.item.chatActive - 1].strategy == 0
 -->
      <el-button
        :disabled="getLoading()"
        class="detailsBtn color1 btnImg"
        v-if="props.routeType == 'chat' && chatType == 'base'"
        @click="releteTalk(1)"
      >
        <img :src="setSearch" alt="" class="btnImg wid17" />
        <div>{{ t("base.base191") }}</div>
      </el-button>
      <el-button
        :disabled="getLoading()"
        class="detailsBtn color2 btnImg"
        v-if="props.routeType == 'chat' && chatType == 'base'"
        @click="releteTalk(2)"
      >
        <img :src="setSearch" alt="" class="btnImg wid17" />
        <div>{{ t("base.base192") }}</div>
      </el-button>
      <el-button
        :disabled="getLoading()"
        class="detailsBtn color3 marginRight"
        @click="openRequestverify"
        v-if="chatType == 'base'"
      >
        <img :src="setCap" alt="" class="btnImg wid21" />
        <div>{{ t("base.base193") }}</div>
      </el-button>
    </div>
    <div
      :class="
        props.historyDialog && props.rightType
          ? 'marginBottom center'
          : 'center'
      "
    >
      <el-tooltip
        popper-class="toolTip"
        class="box-item"
        :content="t('base.base194')"
      >
        <el-button class="iconMargin" @click="copyFn">
          <img :src="copy" alt="" class="wid36" />
        </el-button>
      </el-tooltip>
      <el-tooltip
        popper-class="toolTip"
        class="box-item"
        :content="t('base.base195')"
        v-if="props.routeType == 'chat'"
      >
        <el-button
          class="iconMargin"
          @click="releteTalk(0)"
          :disabled="getLoading()"
        >
          <img :src="relete" alt="" class="wid36" />
        </el-button>
      </el-tooltip>
      <el-tooltip
        popper-class="toolTip"
        class="box-item"
        :content="t('base.base196')"
      >
        <el-button class="iconMargin" @click="itemQa" :disabled="getLoading()">
          <img :src="cToa" alt="" class="wid36" />
        </el-button>
      </el-tooltip>
      <el-tooltip
        popper-class="toolTip"
        class="box-item"
        :content="
          props.item.isCollected == 0 ? t('base.base197') : t('base.base198')
        "
      >
        <el-button
          class="iconMargin"
          @click="itemLike"
          v-if="props.item.isCollected == 0"
        >
          <img :src="setSq" alt="" class="wid36" />
        </el-button>
        <el-button class="iconMargin" @click="itemLike" v-else>
          <img :src="qxsc" alt="" class="wid36" />
        </el-button>
      </el-tooltip>
      <el-tooltip
        popper-class="toolTip"
        class="box-item"
        :content="t('base.base199')"
      >
        <el-button class="iconMargin" @click="openHistory">
          <img :src="history" alt="" class="wid36" />
        </el-button>
      </el-tooltip>
      <el-tooltip popper-class="toolTip" placement="top">
        <template #content v-if="chatType == 'base'">
          {{ t("base.base200") }}<br />
          {{ t("login.login33") }}:{{ descObj.model }}<br />
          {{ t("login.login34") }}:
          {{
            props.item.answers[props.item.chatActive - 1].strategy == 0
              ? t("base.base202")
              : props.item.answers[props.item.chatActive - 1].strategy == 1
              ? t("base.base191")
              : t("base.base192")
          }}<br />
          {{ t("login.login35") }}: {{ descObj.answerTokens }}<br />
          {{ t("login.login37") }}:{{ descObj.questionTokens }}<br />
          {{ t("login.login36") }}: {{ descObj.realQuestionTokens }}<br />
          {{ t("login.login38") }}: {{ descObj.internetCount }}<br />
          {{ t("login.login39") }}: {{ getTime(descObj.createdTime) }}<br />
        </template>
        <template #content v-else>
          {{ t("base.base200") }}<br />
          {{ t("login.login33") }}:{{ descObj.model }}<br />
          {{ t("login.login35") }}: {{ descObj.answerTokens }}<br />
          {{ t("login.login37") }}:{{ descObj.questionTokens }}<br />
          {{ t("login.login36") }}: {{ descObj.realQuestionTokens }}<br />
          {{ t("login.login38") }}: {{ descObj.internetCount }}<br />
          {{ t("login.login39") }}: {{ getTime(descObj.createdTime) }}<br />
        </template>
        <el-button class="iconMargin" @mouseover="getDesc">
          <img :src="gantan" alt="" class="wid36" />
        </el-button>
      </el-tooltip>
      <el-tooltip
        popper-class="toolTip"
        class="box-item"
        :content="t('base.base201')"
        v-if="props.routeType == 'chat'"
      >
        <el-button class="iconMargin" @click="deleteItem(props.item)">
          <img :src="cd" alt="" class="wid36" />
        </el-button>
      </el-tooltip>
      <div class="lineIcon">
        <img :src="line" alt="" class="widLine" />
      </div>
      <el-tooltip
        popper-class="toolTip"
        class="box-item"
        :content="
          props.item.isVoteUp == 0 ? t('base.base203') : t('base.base204')
        "
      >
        <el-button
          class="iconMargin"
          @click="itemUp"
          v-if="props.item.isVoteUp == 0"
        >
          <img :src="dz" alt="" class="wid36" />
        </el-button>
        <el-button class="iconMargin" @click="itemUp" v-else>
          <img :src="ydz" alt="" class="wid36" />
        </el-button>
      </el-tooltip>
      <el-tooltip
        popper-class="toolTip"
        class="box-item"
        :content="
          props.item.isVoteUp == 0 ? t('base.base205') : t('base.base206')
        "
      >
        <el-button
          class="iconMargin"
          @click="itemDown"
          v-if="props.item.isVoteDown == 0"
        >
          <img :src="qxdz" alt="" class="wid36" />
        </el-button>
        <el-button class="iconMargin" @click="itemDown" v-else>
          <img :src="ydc" alt="" class="wid36" />
        </el-button>
      </el-tooltip>
    </div>
    <!-- 验证 -->
    <div v-if="requestverify" class="capDialogClass">
      <div class="capTitle">
        <div>{{ t("base.base132") }}</div>
        <img class="crossSvug" :src="crossSvug" alt="" @click="closeCap" />
      </div>
      <div class="labelItem">
        <span class="capLabel">{{ t("base.base208") }}</span>
      </div>
      <div>
        <el-select
          v-model="peopleSel"
          multiple
          :placeholder="t('base.base209')"
        >
          <el-option
            v-for="items in seleteUserInfo"
            :key="items.ownerId"
            :label="items.ownerName"
            :value="items.ownerId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="labelItem">{{ t("base.base210") }}</div>
      <el-input
        :placeholder="t('base.base211')"
        show-word-limit
        class="catText"
        type="textarea"
        v-model="leftNotes"
      />
      <div class="capBtnDiv">
        <el-button class="capCancel" @click="closeCap">{{
          t("base.base45")
        }}</el-button>
        <el-button
          class="capSave"
          :loading="requestverifyLoading"
          @click="saveCap"
          >{{ t("base.base212") }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import setSearch from "@/assets/chat/setSearch.svg";
import setCap from "@/assets/chat/setCap.svg";
import copy from "@/assets/chat/copy.svg";
import relete from "@/assets/chat/relete.svg";
import cToa from "@/assets/chat/cToa.svg";
import setSq from "@/assets/chat/setSq.svg";
import history from "@/assets/chat/history.svg";
import gantan from "@/assets/chat/gantan.png";
import cd from "@/assets/chat/cd.svg";
import line from "@/assets/chat/line.svg";
import dz from "@/assets/chat/dz.svg";
import qxdz from "@/assets/chat/qxdz.svg";
import ydz from "@/assets/chat/ydz.svg";
import ydc from "@/assets/chat/ydc.svg";
import qxsc from "@/assets/chat/qxsc.svg";
import leftFill from "@/assets/chat/leftFill.svg";
import rightFill from "@/assets/chat/rightFill.svg";
import leftNoFill from "@/assets/chat/leftNoFill.svg";
import rightNoFill from "@/assets/chat/rightNoFill.svg";
import crossSvug from "@/assets/chat/cross.svg";
import { getTime, throttle } from "@/utils/utils";
import { ref, watch, nextTick } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { encryptApi, decryptApi } from "@/utils/crypto";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import api from "@/api";
const router = useRouter();
const store = useStore();
const chatType = ref(store.state.chatType);
const userId = ref(store.state.userId);
const topicItem = ref(store.state.topicItem);
const requestverify = ref(false); //请求重新验证
const peopleSel = ref([]);
const seleteUserInfo = ref([]);
const requestverifyLoading = ref(false);
const leftNotes = ref("");
const emit = defineEmits(["openHistory", "scrollBottom", "getTalk"]);
const props = defineProps([
  "rightType",
  "historyDialog",
  "lookDialog",
  "item",
  "model",
  "routeType",
  "chatList",
]);
const descObj = ref({
  answerTokens: null,
  createdTime: "",
  internetCount: null,
  model: "",
  questionTokens: null,
  realQuestionTokens: null,
});
watch(
  () => store.state.chatType,
  (newValue, oldValue) => {
    chatType.value = newValue;
  }
);
watch(
  () => store.state.topicItem,
  (newValue, oldValue) => {
    topicItem.value = newValue;
  }
);
const getLoading = () => {
  console.log(props.chatList.some((item) => item.loading === true))
  return props.chatList.some((item) => item.loading === true);
};
// 删除会话
const deleteItem = (item) => {
  api.chat[
    chatType.value == "base"
      ? "baseConversationDelete"
      : "chatConversationDelete"
  ]({
    conversationId: item.conversationId,
    userId: userId.value,
    operation_type: 0,
  }).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("base.base213"));
      emit("getTalk");
    }
  });
  console.log(item);
};
// 保存
const saveCap = () => {
  requestverifyLoading.value = true;
  let verifyNums = peopleSel.value
    .map((ownerId) => {
      const item = seleteUserInfo.value.find(
        (item) => item.ownerId === ownerId
      );
      return item ? { ownerId: item.ownerId, ownerType: item.ownerType } : null;
    })
    .filter((item) => item !== null);
  api.share
    .postRequestverify({
      userId: userId.value,
      conversationIds: [props.item.conversationId],
      verifyNums: verifyNums,
      sessionName: topicItem.value.sessionName,
      sessionId: topicItem.value.sessionId,
      leftNotes: leftNotes.value,
      onlyQA: true,
    })
    .then((res) => {
      requestverifyLoading.value = false;
      if (res.returnCode == 200) {
        message.success(t("base.base214"));
        requestverify.value = false;
      }
    });
};
// 关闭严重
const closeCap = () => {
  requestverify.value = false;
};
// 打开验证
const openRequestverify = () => {
  peopleSel.value = [];
  leftNotes.value = "";
  api.base
    .customLibraryDesc({
      userId: userId.value,
      libraryId: topicItem.value.knowledge_library_id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        seleteUserInfo.value = res.data.userInfo.filter(
          (item) => item.type !== 2
        );
        requestverify.value = true;
      }
    });
};
// 获取详情
const getDesc = throttle(() => {
  api.chat[chatType.value == "chat" ? "chatDesc" : "baseDesc"]({
    answerId: props.item.answers[props.item.chatActive - 1].answerId,
    conversationId: props.item.conversationId,
    userId: userId.value,
  }).then((res) => {
    if (res.returnCode == 200) {
      descObj.value = res.data;
    }
  });
}, 1000);
// 重新生成
const releteTalk = (strategy) => {
  props.item.resonType = false
  props.item.answers.push({
    answerContent: "",
    answerId: "",
    strategy: strategy,
  });
  props.item.chatActive = props.item.answers.length;
  let time = Math.round(new Date() / 1000);
  let obj = {};
  if (chatType.value == "chat") {
    obj = {
      userId: userId.value,
      model: props.model,
      topicId: topicItem.value.sessionId,
      conversationId: props.item.conversationId,
      strategy: strategy,
    };
  } else {
    obj = {
      userId: userId.value,
      model: props.model,
      libraryId: topicItem.value.knowledge_library_id,
      conversationId: props.item.conversationId,
      sessionId: topicItem.value.sessionId,
      strategy: strategy,
    };
  }
  let postObj = {
    time: time,
    idempotentEncryData: encryptApi(JSON.stringify({ ...obj, time: time })),
  };
  let lang = store.state.lang;
  if (lang === "CN") {
    lang = "zh-CN";
  } else if (lang === "HK") {
    lang = "zh-HK";
  } else if (lang === "US") {
    lang = "EN";
  }
  if (chatType.value == "chat") {
    regenerateFn(postObj, lang, "/openai/chat/v4/normal/regenerate");
  } else {
    regenerateFn(postObj, lang, "/openai/chat/v4/knowledge/regenerate");
  }
};
// 重新生成chat
const regenerateFn = async (postObj, lang, url) => {
  try {
    props.item.loading = true;
    let controller = new AbortController();
    const response = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + store.state.token,
        "Accept-Language": lang,
      },
      body: JSON.stringify(postObj),
      signal: controller.signal,
    });
    if (response.status === 500) {
      message.warning(t("base.base182"));
      props.item.answers[props.item.answers.length - 1].answerContent =
        t("base.base182");
      props.item.loading = false;
      return;
    }
    if (response.status === 504) {
      message.warning(t("base.base183"));
      props.item.answers[props.item.answers.length - 1].answerContent =
        t("base.base183");
      props.item.loading = false;
      return;
    }
    const reader = response.body.getReader();
    let flag = true;
    let data = "";
    while (flag) {
      const { done, value } = await reader.read();
      const rawData = new TextDecoder().decode(value);
      const regex = /<start>(.*?)<end>/g;
      let matches;
      const extractedData = [];
      while ((matches = regex.exec(rawData)) !== null) {
        extractedData.push(matches[1]);
      }
      const decryptedData = extractedData.map((data) => decryptApi(data));
      data += decryptedData.map((item) => item).join("");
      props.item.answers[props.item.answers.length - 1].answerContent = data;
      nextTick(() => {
        emit("scrollBottom");
      });
      if (done) {
        props.item.loading = false;
        if (
          !data.includes("returnCode") &&
          !data.includes("!(end of answer)")
        ) {
          nextTick(() => {
            store.commit("SET_TALK", {});
          });
        } else if (data.includes("!(end of answer)")) {
          message.warning(t("base.base184"));
          props.item.answers[props.item.answers.length - 1].answerContent =
            t("base.base184");
        } else if (data.includes("returnCode")) {
          let newData = JSON.parse(data);
          message.warning(newData.returnMessage);
          if (newData.returnCode == 423 || newData.returnCode == 412) {
            router.push("/login");
          } else {
            props.item.chatActive = props.item.answers.length - 1;
            props.item.answers.splice(props.item.chatActive, 1);
            // props.item.answers[props.item.answers.length - 1].answerContent =
            //   newData.returnMessage;
          }
        }
        break;
      }
    }
  } catch (err) {
    props.item.loading = false;
  }
};
// 加减页数
const changeItemPage = (type) => {
  if (type && props.item.chatActive < props.item.answers.length) {
    props.item.chatActive++;
  }
  if (!type && props.item.chatActive > 1) {
    props.item.chatActive--;
  }
};
// 打开历史记录
const openHistory = () => {
  if (chatType.value == "chat") {
    api.base
      .chatHistory({
        userId: userId.value,
        conversationId: props.item.conversationId,
        topicId: topicItem.value.sessionId,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          if (res.data.length == 0) {
            message.warning(t("base.base215"));
            return;
          }
          res.data.chatActive = 1;
          emit("openHistory", res.data);
        }
      });
  } else {
    api.base
      .baseHistory({
        userId: userId.value,
        conversationId: props.item.conversationId,
        libraryId: topicItem.value.knowledge_library_id,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          if (res.data.length == 0) {
            message.warning(t("base.base215"));
            return;
          }
          res.data.chatActive = 1;
          emit("openHistory", res.data);
        }
      });
  }
};
// 复制
const copyFn = async () => {
  if (!navigator.clipboard) {
    message.warning("浏览器不支持拷贝到剪贴板");
    return;
  }
  try {
    await navigator.clipboard.writeText(
      props.item.answers[props.item.chatActive - 1].answerContent
    );
    message.success(t("base.base216"));
  } catch (err) {
    message.warning(t("base.base217"));
  }
};
// 翻译
const itemQa = () => {
  let text = JSON.parse(
    JSON.stringify(props.item.answers[props.item.chatActive - 1].answerContent)
  );
  props.item.answers[props.item.chatActive - 1].answerContent = "";
  api.chat[chatType.value == "chat" ? "chatTranslate" : "baseTranslate"]({
    userId: userId.value,
    conversationId: props.item.conversationId,
    answerId: props.item.answers[props.item.chatActive - 1].answerId,
  }).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("base.base218"));
      props.item.answers[props.item.chatActive - 1].answerContent = res.data;
    } else {
      props.item.answers[props.item.chatActive - 1].answerContent = text;
    }
  });
};
// 收藏
const itemLike = () => {
  api.chat[chatType.value == "chat" ? "chatLike" : "baseLike"]({
    userId: userId.value,
    conversationId: props.item.conversationId,
    operationType: props.item.isCollected == 1 ? 1 : 0,
  }).then((res) => {
    if (res.returnCode == 200) {
      message.success(
        props.item.isCollected == 1 ? t("base.base219") : t("base.base220")
      );
      props.item.isCollected = props.item.isCollected == 1 ? 0 : 1;
    }
  });
};
// 点赞
const itemUp = () => {
  api.chat[chatType.value == "chat" ? "chatUp" : "baseUp"]({
    userId: userId.value,
    conversationId: props.item.conversationId,
    operationType: props.item.isVoteUp == 1 ? 1 : 0,
  }).then((res) => {
    if (res.returnCode == 200) {
      message.success(
        props.item.isVoteUp == 1 ? t("base.base221") : t("base.base222")
      );
      props.item.isVoteUp = props.item.isVoteUp == 1 ? 0 : 1;
    }
  });
};
// 点踩
const itemDown = () => {
  api.chat[chatType.value == "chat" ? "chatDown" : "baseDown"]({
    userId: userId.value,
    conversationId: props.item.conversationId,
    operationType: props.item.isVoteDown == 1 ? 1 : 0,
  }).then((res) => {
    if (res.returnCode == 200) {
      // if (props.item.isVoteDown == 0) {
      //   props.item.tipDialog = true;
      // }
      message.success(
        props.item.isVoteDown == 1 ? t("base.base223") : t("base.base224")
      );
      props.item.isVoteDown = props.item.isVoteDown == 1 ? 0 : 1;
    }
  });
};
</script>

<style lang="scss" scoped>
.detailsBtn {
  display: flex;
  border-radius: 30px;
  font-size: 18px;
  color: #fefefe;
  font-family: "Regular";
  padding: 5px 12px;
  height: 36px;
  cursor: pointer;
  margin-left: 0px !important;
}
.color1 {
  background-color: #38aee5;
}
.color2 {
  background-color: #767bfa;
}
.color3 {
  background-color: #3376ff;
}
.btnImg {
  margin-right: 8px;
}
.flexCenter {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.iconMargin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border: 0px;
  margin-left: 0px !important;
}
.lineIcon {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconMargin:hover {
  background-color: #edeef6;
  border-radius: 12px;
}
.marginRight {
  margin-right: 20px;
}
.center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.bottomIcon {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  margin-bottom: 30px;
}
.marginBottom {
  margin-bottom: 20px;
}
.wid17 {
  width: 17px;
}
.wid21 {
  width: 21px;
}
.wid36 {
  width: 36px;
}
.widLine {
  width: 5px;
  height: 25px;
}
.fillImg {
  width: 24px;
  cursor: pointer;
}
.fillText {
  font-size: 18px;
  font-family: "Semibold";
  color: #8a8a8a;
}
.fillCenter {
  display: flex;
  align-items: center;
  margin-right: 12px;
}
.fillColor {
  color: #d9d9d9;
}
.capDialogClass {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 566px;
  height: 370px;
  border-radius: 16px;
  background-color: #f7f8ff;
  z-index: 10;
  padding: 17px;
  cursor: default;
}

.capTitle {
  font-size: 22px;
  color: #3d3d3d;
  font-family: "Medium";
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  height: 30px;
}

.crossSvug {
  width: 24px;
  cursor: pointer;
}

.labelItem {
  font-size: 18px;
  font-family: "Medium";
  color: #3d3d3d;
  margin-bottom: 12px;
  margin-top: 24px;
}

.capLabel {
  position: relative;
  margin-right: 20px;
}

.capLabel::after {
  content: "*";
  position: absolute;
  color: #f02d63;
  right: -15px;
}

.catText {
  margin-bottom: 24px;

  :deep(.el-textarea__inner) {
    height: 110px;
    min-height: 110px;
    max-height: 110px;
    border-radius: 16px;
    border: 2px solid #edeef6;
    padding: 12px 20px;
    resize: none;
    outline: none;
    font-family: "Regular";
    font-size: 18px;
  }
}

.capBtnDiv {
  text-align: right;
}

.capCancel {
  height: 48px;
  color: #f02d63;
  border: 2px solid #edeef6;
  border-radius: 16px;
  font-size: 20px;
  font-family: "Regular";
  padding: 10px 20px;
}

.capSave {
  background-color: #3376ff;
  border: 0px;
  border-radius: 16px;
  height: 48px;
  font-size: 20px;
  font-family: "Regular";
  color: #fefefe;
  padding: 10px 20px;
}
:deep(.el-button.is-disabled, .el-button.is-disabled:hover) {
  background-color: none;
  color: none;
}
</style>
