<template>
  <div class="addContent">
    <div class="titleFlex">
      <div class="addTitle">AI知识库详情</div>
      <img :src="addClose" alt="" class="addClose" @click="closeAdd" />
    </div>
    <div class="addBody">
      <!-- 选择模式 -->
      <div class="flexBetWeen marginBtomm20px">
        <el-button
          v-for="(item, index) in navList"
          :key="item"
          class="itemDivNavActive"
          @click="changeNav(index)"
        >
          <img :src="item.url" alt="" class="navImg" />
          <div :class="form.type == index ? 'itemTextActive' : 'itemText'">
            {{ t(item.name) }}
          </div>
        </el-button>
      </div>
      <!-- 表单 -->
      <div>
        <span class="addLabel nameLabel">{{ t("base.base21") }}</span>
      </div>
      <div class="itemInput marginBtomm20px">{{ form.name }}</div>
      <div class="addLabel">{{ t("base.base23") }}</div>
      <div class="itemInput marginBtomm20px">{{ form.desc }}</div>
      <div class="addLabel marginBtomm20px">{{ t("base.base25") }}</div>
      <div class="flexBetWeen marginBtomm20px">
        <div
          v-for="(item, index) in upTypeList"
          :key="index"
          :class="fileActive == index ? 'bottomFileActive' : 'bottomFile'"
        >
          <div class="flexCenter">
            <img class="fileImg" :src="item.icon" alt="" />
            <div :class="fileActive == index ? 'itemTextActive' : 'itemText'">
              {{ t(item.name) }}
            </div>
          </div>
          <div :class="fileActive == index ? 'fileTipActive' : 'fileTip'">
            {{ t(item.tip) }}
          </div>
        </div>
      </div>
      <div class="marginBtomm20px flexBetWeen">
        <span class="addLabel nameLabel">{{ t("base.base26") }}</span>
      </div>
      <div class="marginBtomm20px flexBetWeen">
        <el-button
          v-for="item in ptList"
          :key="item.id"
          :class="getPt(item.id) ? 'ptDivItemActive' : 'ptDivItem'"
        >
          <img :src="item.icon" alt="" class="ptImg" />
        </el-button>
      </div>
      <div class="marginBtomm20px addLabel">{{ t("base.base28") }}</div>
      <div class="flexCenter marginBtomm20px">
        <el-tooltip
          popper-class="toolTip"
          class="box-item"
          :content="t('base.base28')"
        >
          <div :class="form.image_path ? 'txCenter' : 'step2Img'">
            <img
              v-if="form.imageName == null || form.imageName == ''"
              :src="bImg"
              alt=""
              class="stepImgIcon"
            />
            <img
              v-else
              :src="'/openai/chat/v4/image/get/' + form.imageName"
              alt=""
              class="stepImgIcons"
            />
          </div>
        </el-tooltip>
      </div>
      <div class="marginBtomm20px">
        <span class="addLabel"
          >{{ t("base.base30") }}
          <el-tooltip popper-class="toolTip" :content="t('base.base305')">
            <el-icon class="tipIcon"><Warning /></el-icon>
          </el-tooltip>
        </span>
      </div>
      <div class="itemInput marginBtomm20px">
        {{ form.promptName }}
      </div>
      <div class="marginBtomm20px flexBetweenAdd">
        <span class="addLabel">{{ t("base.base32") }} </span>
      </div>
      <el-row class="rightTable">
        <el-col :span="16" class="tableNmae">{{ t("base.base34") }}</el-col>
        <el-col :span="8" class="tableNmae"
          >{{ t("base.base35") }}
          <el-tooltip
            popper-class="toolTip"
            class="box-item"
            raw-content
            :content="t('base.base288')"
          >
            <el-icon class="tipIcon"><Warning /></el-icon>
          </el-tooltip>
        </el-col>
      </el-row>
      <el-row
        class="tableItem marginBtomm20px"
        v-for="item in form.shareUserIds"
        :key="item.id"
      >
        <el-col :span="16" class="flexCenter padding10" v-if="item.name">
          <div class="userImg" :style="'background-color:' + getRandomColor1()">
            {{ item.name?.slice(0, 1) }}
          </div>
          <span class="tableTitleNmae">{{ item.name }}</span>
        </el-col>
        <el-col :span="16" class="flexCenter padding10" v-else>
          <span class="tableTitleNmae">-</span>
        </el-col>
        <el-col :span="8">
          <div class="tableRoleType" v-if="item.type == 0">
            <div class="tableLogo">{{ t("base.base37").slice(0, 1) }}</div>
            <div class="tableRole">{{ t("base.base38") }}</div>
          </div>
          <div class="tableRoleType1" v-if="item.type == 1">
            <div class="tableLogo1">{{ t("base.base39") }}</div>
            <div class="tableRole1">{{ t("base.base40") }}</div>
          </div>
          <div class="tableRole" v-if="item.type == 2">
            {{ t("base.base41") }}
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="addBottomBtn">
      <el-button class="closeBtn" @click="closeDetail">{{
        t("base.base45")
      }}</el-button>
      <el-button
        class="nextBtn"
        @click="save"
        v-if="editObj.knowledge_role_id == 0"
      >
        {{ t("base.base5") }}
      </el-button>
    </div>
  </div>
  <div class="mask"></div>
</template>

<script setup>
import zhImg from "@/assets/base/zh.svg";
import upFile from "@/assets/base/upFile.svg";
import bImg from "@/assets/base/b.svg";
import addClose from "@/assets/base/addClose.svg";
import azure from "@/assets/base/azure.svg";
import aliyun from "@/assets/base/aliyun.svg";
import baidu from "@/assets/base/baidu.svg";
import { getRandomColor1 } from "@/utils/utils";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import api from "@/api";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const props = defineProps(["editObj"]);
const emit = defineEmits(["closeDetail", "openEdit"]);
const store = useStore();
const fileActive = ref(0);
const userId = ref(store.state.userId);
const navList = ref([
  {
    name: "base.base13",
    url: zhImg,
  },
]);
const upTypeList = ref([
  {
    name: "base.base48",
    icon: upFile,
    tip: "base.base49",
  },
]);
const ptList = ref([
  {
    id: "Azure",
    icon: azure,
  },
  {
    id: "Ali",
    icon: aliyun,
  },
  {
    id: "Baidu",
    icon: baidu,
  },
]);
const form = ref({
  userId: store.state.userId,
  name: "", //微模型名称
  desc: "", //微模型描述
  type: 0, //微模型类型
  promptId: null, //提示词ID
  shareUserIds: [], //知识库成员
  resourcePlatform: [], //资源平台
  imageName: "",
});
const botPageId = ref(0); //智能体页数
const botPerPage = ref(10); //智能体分页
const templatePattern = ref(""); //智能体模糊查询
const botList = ref([]);
onMounted(() => {
  getBotList();
  form.value.name = props.editObj.knowledge_name;
  form.value.desc = props.editObj.knowledge_desc;
  form.value.type = props.editObj.knowledge_type;
  form.value.promptId = props.editObj.prompt_value.promptId;
  form.value.imageName = props.editObj.image_path;
  form.value.promptName = props.editObj.prompt_value.promptName;
  if (props.editObj.userInfo.length != 0) {
    props.editObj.userInfo.forEach((item) => {
      form.value.shareUserIds.push({
        userId: item.ownerId,
        id: item.ownerType == 2 ? item.ownerId : Number(item.ownerId),
        type: item.type, //0 拥有者 1 创作者 2 普通用户
        userName: item.ownerName,
        name: item.ownerName,
        ownerType: item.ownerType, //0个人 1部门 2群组
      });
    });
  }
  if (props.editObj.aliEmbeddingOn == 1) {
    form.value.resourcePlatform.push("Ali");
  }
  if (props.editObj.azureEmbeddingOn == 1) {
    form.value.resourcePlatform.push("Azure");
  }
  if (props.editObj.baiduEmbeddingOn == 1) {
    form.value.resourcePlatform.push("Baidu");
  }
});
// 获取智能体列表
const getBotList = () => {
  api.base
    .getBot({
      userId: userId.value,
      pageId: botPageId.value,
      perPage: botPerPage.value,
      templatePattern: templatePattern.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        botList.value = res.data;
        if (props.addType == "edit") {
          const hasTemplateId2086 = botList.value.some(
            (template) =>
              template.templateId === props.editObj.prompt_value.promptId
          );
          if (!hasTemplateId2086) {
            botList.value.push({
              templateDesc: "",
              templateId: props.editObj.prompt_value.promptId,
              templateName: props.editObj.prompt_value.promptName,
              userResults: 0,
            });
          }
        }
      }
    });
};
// 判断有没有这个id 有就返回true  class判断
const getPt = (id) => {
  // 查找数组中是否存在该id
  const index = form.value.resourcePlatform.findIndex((item) => item === id);
  if (index !== -1) {
    return true;
  } else {
    return false;
  }
};
// 取消
const closeAdd = () => {
  emit("closeDetail");
};
// 切换选择
const changeNav = (index) => {
  form.value.type = index;
};
const closeDetail = () => {
  emit("closeDetail");
};
// 保存
const save = () => {
  emit("openEdit");
};
</script>

<style lang="less" scoped>
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 110;
  opacity: 0.2;
}
.addContent {
  position: fixed;
  width: 540px;
  height: 700px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  z-index: 111;
  padding: 30px;
  border-radius: 30px;
}
.addTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Medium";
}
.addBottomBtn {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
}
.closeBtn {
  height: 44px;
  border: 2px solid #edeef6;
  border-radius: 16px;
  font-size: 18px;
  color: #f02d63;
  font-family: "Regular";
  padding: 12px 20px;
}
.nextBtn {
  height: 48px;
  border-radius: 16px;
  color: #fefefe;
  background-color: #3376ff;
  border: 0px;
  font-size: 18px;
  font-family: "Regular";
  padding: 12px 20px;
}
.addBody {
  height: calc(100% - 140px);
  overflow: auto;
  padding: 5px 5px;
}
.navImg {
  width: 28px;
  height: 28px;
  margin-right: 5px;
}
.itemText {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
}
.itemTextActive {
  font-size: 18px;
  color: #0256ff;
  font-family: "Medium";
}
.itemDivNav {
  width: 163px;
  height: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 18px;
  border: 2px solid #ffffff;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  cursor: pointer;
}
.itemDivNavActive {
  // width: 163px;
  height: 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 18px;
  border: 2px solid #eceeff;
  background-color: #eceeff;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  cursor: pointer;
}
:deep(.el-button.is-disabled:hover) {
  box-shadow: 0px 0px 8px 0px #dbdfff;
  border: 2px solid #eceeff;
  background-color: #eceeff;
}
.flexBetWeen {
  display: flex;
  align-items: center;
}
.marginBtomm20px {
  margin-bottom: 12px;
}
.addLabel {
  font-size: 18px;
  font-family: "Regular";
  color: #3d3d3d;
  position: relative;
}
.nameLabel::before {
  position: absolute;
  content: "*";
  color: #f02d63;
  right: -15px;
}
.itemInput {
  margin-top: 10px;
  font-size: 18px;
  font-family: "Regular";
}
:deep(.el-input .el-input__count) {
  color: #8a8a8a;
  font-size: 18px;
}
.fileImg {
  width: 23px;
  margin-right: 6px;
}
.fileTip {
  font-size: 16px;
  color: #8a8a8a;
  font-family: "Regular";
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.fileTipActive {
  font-size: 16px;
  color: #a3acec;
  font-family: "Regular";
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.bottomFileActive {
  width: calc(49.5% - 44px);
  height: 52px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #eceeff;
  padding: 14px 20px;
  border-radius: 18px;
  cursor: pointer;
}
.bottomFile {
  width: calc(49.5% - 44px);
  height: 79px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 20px;
  border-radius: 18px;
  border: 2px solid #d9d9d9;
  cursor: pointer;
}
.step2Img {
  width: 80px;
  height: 80px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3376ff;
  overflow: hidden;
}
.txCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.stepImgIcon {
  width: 60px;
}
.stepImgIcons {
  width: 100%;
  height: 100%;
}
.tipIcon {
  margin-left: 10px;
  font-size: 16px;
}
.elSelete {
  :deep(.el-select__wrapper) {
    font-size: 18px;
    height: 44px;
    border-radius: 18px;
    border: 2px solid #d9d9d9;
    color: #3d3d3d;
    box-shadow: 0px 0px 0px;
  }
}
.rightTable {
  height: 60px;
  background-color: #f7f8ff;
  line-height: 60px;
  border-top: 2px solid #edeef6;
  border-bottom: 2px solid #edeef6;
  font-size: 18px;
  color: #8a8a8a;
  :deep(.el-col-8) {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
}
.tableItem {
  height: 60px;
  line-height: 60px;
  border-bottom: 2px solid #edeef6;
  font-size: 18px;
  color: #8a8a8a;
  :deep(.el-col-8) {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
}
.tableIcon {
  margin-right: 15px;
  width: 24px;
}
.tableTitleNmae {
  color: #3d3d3d;
  font-size: 18px;
  width: calc(100% - 46px);
  display: inline-block;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.tableLogo {
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  background-color: #0256ff;
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  font-family: "Regular";
}
.tableRole {
  color: #0256ff;
  font-size: 18px;
  font-family: "Regular";
  margin-left: 10px;
}
.tableRole2 {
  color: #3d3d3d;
  font-size: 18px;
  font-family: "Regular";
  margin-left: 10px;
}
.tableLogo1 {
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  background-color: #ffc300;
  border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  font-family: "Regular";
}
.tableRole1 {
  color: #ffc300;
  font-size: 18px;
  font-family: "Regular";
  margin-left: 10px;
}
.tableRoleType {
  display: flex;
  width: 115px;
  height: 40px;
  align-items: center;
  background-color: #b2ccfe;
  border-radius: 8px;
  justify-content: center;
}
.tableRoleType1 {
  display: flex;
  width: 115px;
  height: 40px;
  align-items: center;
  background-color: #feecb2;
  border-radius: 8px;
  justify-content: center;
}
.titleFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  margin-bottom: 20px;
}
.addClose {
  width: 14px;
  cursor: pointer;
}
.addUserBtn {
  height: 36px;
  border-radius: 12px;
  background-color: #3376ff;
  color: #ffffff;
  font-size: 18px;
  font-family: "Regular";
  border: 0px;
  padding: 6px 20px;
}
.flexBetweenAdd {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ptTip {
  color: #8a8a8a;
  font-size: 16px;
  font-family: "Medium";
  max-width: 300px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ptImg {
  height: 25px;
}
.ptDivItem {
  width: 167px;
  height: 56px;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: none;
}
.ptDivItemActive {
  width: 167px;
  height: 56px;
  box-shadow: 0px 0px 8px 0px #dbdfff;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #dbdfff;
}
.deleUserBtn {
  height: 31px;
  border-radius: 8px;
  background-color: #f7f8ff;
  border: 0px;
  font-size: 18px;
  color: #f02d63;
  font-family: "Regular";
}
.moreUser {
  margin-left: 10px;
  font-size: 20px;
}
.userImg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  font-family: "Semibold";
  margin-right: 6px;
}
.tableNmae {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  padding-left: 10px;
}
.padding10 {
  padding-left: 10px;
}
:deep(.el-input__wrapper) {
  border-radius: 16px;
  border: 2px solid #edeef6;
  box-shadow: 0px 0px 0px;
}
</style>
