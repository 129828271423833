export function getRandomColor() {
    let r = Math.floor(Math.random() * 256); // 生成0-255的随机整数
    let g = Math.floor(Math.random() * 256);
    let b = Math.floor(Math.random() * 256);
    return `rgb(${r},${g},${b})`; // 返回rgb格式的颜色值
}
// 颜色数组
const colors = [
    {"Blue": "#3376FF"},
    {"Aoi": "#38AEE5"},
    {"Teal": "#11D7B2"},
    {"Green": "#14AE5C"},
    {"Yellow": "#FFC300"},
    {"Orange": "#FFAB5B"},
    {"Red": "#F02D63"},
    {"Violet": "#767BFA"},
    {"Grey": "#A8B7C5"},
];
export function getRandomColor1() {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return Object.values(colors[randomIndex])[0];
}
// 节流
export function throttle<T extends (...args: any[]) => any>(func: T, limit: number): (...args: Parameters<T>) => void {
    let inThrottle: number | null = null;
    return function (...args: Parameters<T>) {
        if (!inThrottle) {
            func(...args);
            inThrottle = window.setTimeout(() => {
                inThrottle = null;
            }, limit);
        }
    };
}
// 防抖
export function debounce<T extends (...args: any[]) => any>(func: T, delay: number): (...args: Parameters<T>) => void {
    let timeout: any
    return function (...args: Parameters<T>) {
        clearTimeout(timeout);
        timeout = window.setTimeout(() => {
            func(...args);
        }, delay);
    };
}
// 返回年月日
export function getTime(t: any) {
    if (t == null) return
    let newtime = t.replace("GMT", " ");
    let date = new Date(newtime);
    let year = date.getFullYear();
    let m = date.getMonth() + 1;
    let d = date.getDate();
    let newm = m < 10 ? "0" + m : m;
    let newday = d < 10 ? "0" + d : d;
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    let formattedHours = hours < 10 ? '0' + hours : hours.toString();
    let formattedMinutes = minutes < 10 ? '0' + minutes : minutes.toString();
    let formattedSeconds = seconds < 10 ? '0' + seconds : seconds.toString();
    let returntime =
        year +
        "-" +
        newm +
        "-" +
        newday +
        " " +
        formattedHours +
        ":" +
        formattedMinutes +
        ":" +
        formattedSeconds;
    return returntime;
}
export function getYear(t: any) {
    if (t == null) return
    let newtime = t.replace("GMT", " ");
    let date = new Date(newtime);
    let year = date.getFullYear();
    let m = date.getMonth() + 1;
    let d = date.getDate();
    let newm = m < 10 ? "0" + m : m;
    let newday = d < 10 ? "0" + d : d;
    let returntime =
        year +
        "-" +
        newm +
        "-" +
        newday
    return returntime;
}