<template>
  <div>
    <div v-html="renderMdText(props.message)" class="mdTextBox"></div>
  </div>
</template>

<script setup>
import MarkdownIt from "markdown-it";
import MarkdownItHighlightjs from "markdown-it-highlightjs";
import "highlight.js/styles/a11y-dark.css";
import "katex/dist/katex.min.css";
import markdownItKatexGpt from "./index";
import { onUpdated, nextTick, onMounted } from "vue";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const props = defineProps(["message"]);
const markdownRender = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true,
})
  .use(MarkdownItHighlightjs)
  .use(markdownItKatexGpt);
onMounted(() => {
  nextTick(() => {
    const codeBlocks = document.querySelectorAll("pre code");
    codeBlocks.forEach((block) => {
      const preElement = block;
      const parentElement = preElement.parentNode;
      const firstDiv = parentElement.querySelector("div");
      if (!firstDiv) {
        var childDiv2 = document.createElement("div");
        childDiv2.textContent = "copy code";
        childDiv2.setAttribute("class", "copyText");
        parentElement.insertBefore(childDiv2, preElement);
        // 给div添加点击事件
        childDiv2.onclick = function () {
          const textArea = document.createElement("textarea");
          textArea.value = preElement.innerText;
          document.body.appendChild(textArea);
          textArea.select();
          document.execCommand("copy");
          document.body.removeChild(textArea);
          message.success(t("base.base216"));
        };
      }
    });
  });
});
const renderMdText = (text) => {
  if (text == null) return;
  text = text.replace(/\align\*/g, "aligned");
  return markdownRender.render(text);
};
</script>

<style lang="less" scoped>
.mdTextBox {
  font-size: 18px;
  color: #000;
  font-family: "Regular";
  max-width: 100%;
  overflow: auto;
  user-select: text;
  padding: 10px;
  padding-bottom: 0px;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

:deep(p) {
  margin-top: 0px;
  margin-bottom: 5px;
}

:deep(code) {
  border-radius: 3px;
  max-width: 100%;
  overflow: auto;
  padding-top: 35px !important;
}

:deep(table) {
  border-collapse: collapse;
  width: 100%;
}

:deep(table) {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

:deep(th) {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  background-color: #f2f2f2;
}
:deep(td) {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
:deep(pre) {
  position: relative;
}

:deep(.copyText) {
  cursor: pointer;
  position: absolute;
  color: #ffffff;
  font-family: "Regular";
  right: 5px;
  font-size: 14px;
}

:deep(.dellImg) {
  width: 100%;
}
</style>
