<template>
  <div>
    <div
      v-for="(idea, index) in props.ideas"
      :key="index"
      @click="saveMessage(idea)"
    >
      <div class="name">{{ idea }}</div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
const props = defineProps({
  ideas: Array,
});
const emit = defineEmits(["saveMessage"]);
const saveMessage = (item) => {
  emit("saveMessage", item);
};
const ideas = props.ideas;
const number = props.number;
</script>
<style scoped lang="less">
.name {
  padding: 8px 12px;
  border: #edeef6 2px solid;
  border-radius: 16px;
  color: #3d3d3d;
  font-size: 18px;
  min-width: 30px;
  margin-bottom: 10px;
  display: inline-block;
  font-family: "Regular";
  cursor: pointer;
}

.icon {
  color: #3d3d3d;
  font-size: 16px;
  margin-left: 10px;
}

.moreSpan {
  margin-top: 4px;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;
  color: #3d3d3d;
  font-size: 14px;
}
</style>
