<script setup>
import { ref, defineProps, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const emit = defineEmits(["handleCancel", "handleUpload"]);
const props = defineProps({
  show: Boolean,
  groupDetail: Object,
});
const show = ref(props.show);
watch(
  () => props.show,
  (newVal) => {
    show.value = newVal;
  }
);
onMounted(() => {});

const title = ref(t("manage.groupForm.group9"));

// 关闭
const handleCancel = () => {
  show.value = !show.value;
  emit("handleCancel");
};
//同意
const handleUpload = () => {
  show.value = !show.value;
  emit("handleUpload");
};
</script>

<template>
  <div>
    <!-- 部门详情 -->
    <el-dialog
      destroy-on-close
      :z-index="100"
      v-model="show"
      align-center
      :close-on-click-modal="false"
      :show-close="false"
    >
      <template #header="">
        <div class="my-header">
          <div>{{ title }}</div>
          <el-icon
            class="el-icon--left"
            :class="'close-icon'"
            @click="handleCancel"
            style="cursor: pointer"
          >
            <Close />
          </el-icon>
        </div>
      </template>
      <div class="content">
        <el-divider />
        <el-form :model="props.groupDetail" class="form" label-position="top">
          <el-form-item
            prop="groupName"
            :label="$t('manage.groupForm.group16')"
          >
            <div class="tit">{{ props.groupDetail.groupName }}</div>
          </el-form-item>
          <el-form-item
            prop="groupDescription"
            :label="$t('manage.groupForm.group17')"
          >
            <div class="tit" v-if="props.groupDetail.groupDescription == null">
              --
            </div>
            <div class="tit" v-else>
              {{ props.groupDetail.groupDescription }}
            </div>
          </el-form-item>
          <el-form-item prop="users" :label="$t('manage.groupForm.group18')">
            <el-table :data="props.groupDetail.users" class="table">
              <el-table-column
                prop="userName"
                :label="$t('manage.userForm.userName')"
              />
              <el-table-column
                prop="email"
                :label="$t('manage.userForm.email')"
              >
                <template #default="scope">
                  <el-tooltip popper-class="toolTip" :content="scope.row.email">
                    <div class="wid100">{{ scope.row.email }}</div>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                prop="departmentName"
                :label="$t('manage.userForm.user2')"
              >
                <template #default="scope">
                  <el-tooltip
                    popper-class="toolTip"
                    :content="scope.row.departmentName"
                  >
                    <div class="wid100">{{ scope.row.departmentName }}</div>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <div @click="handleCancel" class="cancel">
            {{ t("manage.userForm.user24") }}
          </div>
          <div @click="handleUpload" class="confirm">
            {{ t("manage.groupForm.group10") }}
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:deep(.el-dialog) {
  --el-dialog-width: 600px;
  border-radius: 30px;
  // height: 800px;
  padding: 30px;
}

:deep(.el-divider) {
  margin: 1px 0 10px !important;
  width: calc(100%) !important;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Medium";
}

.close-icon {
  font-size: 20px;
}

.content {
  font-size: 18px;
  color: #8a8a8a;

  .name {
    color: #3d3d3d;
    font-size: 24px;
    margin: auto 6px auto 18px;
    font-weight: 500;
  }

  .avatar {
    height: 50px;
    width: 50px;
  }

  .tag {
    display: flex;
    justify-content: space-between;
    background-color: #b8e6cd;
    color: #14ae5c;
    padding: 0px 6px;
    height: 30px;
    line-height: 30px;
    border-radius: 6px;
    margin: auto 0;

    .tag-icon {
      width: 18px;
      height: 18px;
      display: block;
      margin-top: 3px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .tag-text {
      margin-left: 4px;
    }
  }

  .tag_cuo {
    display: flex;
    justify-content: space-between;
    background-color: #fbd4df;
    color: #f02d63;
    padding: 0px 6px;
    height: 30px;
    line-height: 30px;
    border-radius: 6px;
    margin: auto 0;

    .tag-icon {
      width: 18px;
      height: 18px;
      display: block;
      margin-top: 3px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .tag-text {
      margin-left: 4px;
    }
  }

  .reset {
    color: #0256ff;
    margin: auto 10px;
  }
}

.form {
  margin-top: 10px;
}

.form-name {
  font-size: 18px;
  color: #3d3d3d;

  .el-tag {
    display: flex;
    padding: 2px 33px 2px 2px;
    height: 35px;
    line-height: 35px;
    border-radius: 30px;
    font-size: 18px;
    margin-top: 12px;

    .tag-img {
      width: 30px;
      height: 30px;
      margin-right: 6px;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }

  .tit {
    margin-bottom: 10px;
    font-weight: 600;
  }

  .comtent {
    margin-bottom: 12px;
  }
}

.dialog-footer {
  display: flex;
  justify-content: end;
  font-size: 15px;

  .cancel {
    color: #f02d63;
    padding: 8px 20px;
    border-radius: 16px;
    border: 1px solid #edeef6;
    cursor: pointer;
  }

  .confirm {
    color: #fff;
    background-color: #3376ff;
    margin-left: 10px;
    padding: 8px 20px;
    border-radius: 16px;
    cursor: pointer;
  }
}
.wid100{
  width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
