<template>
  <iframe
    style="width: 100%; height: 100%"
    :src="offUrl + encodeURIComponent(props.pptUrl)"
    frameborder="0"
  ></iframe>
  <!-- <VueOfficePptx :src="ppt" @rendered="renderedHandler" /> -->
</template>

<script setup>
//引入VueOfficeDocx组件
import VueOfficePptx from "@vue-office/pptx";
import { ref } from "vue";
const props = defineProps(["pptUrl"]);
const offUrl = ref("https://view.officeapps.live.com/op/embed.aspx?src=");
// const ppt = ref(
//   "https://501351981.github.io/vue-office/examples/dist/static/test-files/test.pptx"
// );
const renderedHandler = () => {
  console.log("渲染完成");
};
</script>

<style lang="scss" scoped></style>
