export default {
    mounted(el: any, binding: any) {
        // 延迟执行，确保元素已渲染完成
        setTimeout(() => {
            // 查找 el 元素中的 .el-select-dropdown .el-select-dropdown__wrap 元素
            const selectWrapper = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
            if (selectWrapper) {
                // 给 selectWrapper 添加 scroll 事件监听器
                selectWrapper.addEventListener('scroll', function () {
                    const scrollHeight = selectWrapper.scrollHeight; // 获取元素的滚动高度
                    const scrollTop = selectWrapper.scrollTop; // 获取元素的滚动条垂直位置
                    const clientHeight = selectWrapper.clientHeight; // 获取元素的可见高度

                    // 如果滚动到接近底部的位置（距离底部小于等于 5 像素）
                    if (scrollTop + clientHeight >= scrollHeight - 5) {
                        // 调用指令的绑定值（通常是一个方法）
                        binding.value();
                    }
                });
            }
        }, 0); // 延迟时间为 0，确保代码在当前同步代码执行完成后执行
    },

    // 指令被解绑时调用的钩子
    unmounted(el: any) {
        // 查找 el 元素中的 .el-select-dropdown .el-select-dropdown__wrap 元素
        const selectWrapper = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap');
        if (selectWrapper && el._scrollHandler) {
            // 移除 scroll 事件监听器
            selectWrapper.removeEventListener('scroll', el._scrollHandler);
            // 清除引用
            delete el._scrollHandler;
        }
    }
};
