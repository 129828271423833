<template>
  <div :class="navActive == 0 ? 'tokenContent' : 'tokenContent1'" v-if="!show">
    <div>
      <!-- nav -->
      <div class="flexCenter height40">
        <div
          :class="navActive == index ? 'navTextAc' : 'navText'"
          v-for="(item, index) in navList"
          :key="item"
          @click="changeNav(index)"
        >
          {{ t(item) }}
        </div>
        <div class="navTip">
          <span class="navTipX">*</span>
          {{ t("statistics.name5") }}
        </div>
      </div>
      <!-- 菜单栏 -->
      <div class="flexBetWeen top15">
        <div class="flexCenter">
          <div class="roleDiv">
            <div class="roleTitle">{{ t("statistics.name6") }}</div>
            <el-dropdown @command="changeSearch($event, 1)">
              <span class="el-dropdown-link">
                {{ t(ztPtList[ptValue].label) }}
                <el-icon class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    v-for="item in ztPtList"
                    :key="item.value"
                    :command="item.value"
                    >{{ t(item.label) }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <div class="roleDiv" v-if="navActive == 0">
            <div class="roleTitle">{{ t("statistics.name7") }}</div>
            <el-dropdown
              @command="changeSearch($event, 2)"
              :max-height="200"
              v-if="zyzList.length != 0"
            >
              <span class="el-dropdown-link">
                {{ t(zyzList[zyzValue].name) }}
                <el-icon class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    v-for="(item, index) in zyzList"
                    :key="item.id"
                    :command="index"
                    >{{ item.name }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <div class="roleDiv" v-if="navActive == 0">
            <div class="roleTitle">{{ t("statistics.name8") }}</div>
            <el-dropdown @command="changeSearch($event, 3)">
              <span class="el-dropdown-link">
                {{ t(timeList[timeActive].name) }}
                <el-icon class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    v-for="item in timeList"
                    :key="item.value"
                    :command="item.value"
                    >{{ t(item.name) }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <el-date-picker
            v-if="timeType"
            v-model="billTime"
            :disabledDate="disabledDate"
            type="month"
            value-format="YYYY-MM"
            :placeholder="t('statistics.name9')"
            @change="getAzure"
          />
          <div class="roleDiv" v-if="navActive == 0">
            <div class="roleTitle">{{ t("statistics.name10") }}</div>
            <el-dropdown @command="changeSearch($event, 4)">
              <span class="el-dropdown-link">
                {{ t(ldList[ldActive].name) }}
                <el-icon class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    v-for="item in ldList"
                    :key="item.value"
                    :command="item.value"
                    :disabled="
                      (item.value == 0 && ptValue != 0) ||
                      (item.value != 0 && ptValue == 0)
                    "
                    >{{ t(item.name) }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <el-input
            v-if="navActive == 1"
            class="nameInput"
            v-model="namePattern"
            @input="getToken"
            :placeholder="t('statistics.name11')"
          ></el-input>
          <el-date-picker
            v-if="navActive == 1"
            v-model="tokenTime"
            type="daterange"
            value-format="YYYY-MM-DD"
            :range-separator="t('statistics.name12')"
            :start-placeholder="t('statistics.name13')"
            :end-placeholder="t('statistics.name14')"
            @change="getToken"
          />
        </div>
        <!-- <div class="exportBtn">导出成本统计</div> -->
      </div>
    </div>
    <div v-if="navActive == 0">
      <!-- 内容图 -->
      <el-row :gutter="20">
        <el-col :span="12" class="top15">
          <bigView
            :title="t('statistics.name15')"
            :item="billList"
            v-if="billList.cost != null"
            :money="billList.cost"
            :type="true"
            @openEchar="openEchar"
          >
          </bigView>
        </el-col>
        <el-col :span="12" class="top15">
          <bigView
            :title="t('statistics.name16')"
            :item="billList"
            v-if="billList.avg_user_cost != null"
            :money="billList.avg_user_cost"
            :type="false"
            @openEchar="openEchar"
          >
          </bigView>
        </el-col>
        <template v-if="billList.detail && billList.detail.length != 0">
          <template v-if="billList.imgType">
            <template v-for="(item, index) in billList.detail" :key="index">
              <el-col
                :span="8"
                class="top15"
                v-if="item.input_cost != null && item.output_cost != null"
              >
                <midelView
                  :item="item"
                  :title="item.functionType"
                  :left="item.input_cost"
                  :right="item.output_cost"
                  @openEchar="openEchar"
                ></midelView>
              </el-col>
              <el-col :span="4" v-else class="top15">
                <smillView
                  :item="item"
                  :title="item.functionType"
                  :money="item.cost"
                  @openEchar="openEchar"
                ></smillView
              ></el-col>
            </template>
          </template>
          <template v-for="(item, index) in billList.detail" :key="index">
            <template v-if="billList.imgType && item.imgType">
              <template v-for="(items, indexs) in item.detail" :key="indexs">
                <el-col
                  :span="8"
                  v-if="items.input_cost != null && items.output_cost != null"
                  class="top15"
                >
                  <midelView
                    :item="items"
                    :title="items.name"
                    :left="items.input_cost"
                    :right="items.output_cost"
                    @openEchar="openEchar"
                  ></midelView>
                </el-col>
                <el-col :span="4" v-else class="top15">
                  <smillView
                    :item="items"
                    :title="items.name"
                    :money="items.cost"
                    @openEchar="openEchar"
                  ></smillView
                ></el-col>
              </template>
            </template>
          </template>
        </template>
      </el-row>
      <div class="flexCenter top15">
        <div class="bottomDiv" v-if="leftOption.series.length != 0">
          <div class="flexCenter bottomTitle">
            <img class="echarImg" :src="echarImg" alt="" />
            <div class="echartText">{{ t("statistics.name17") }}</div>
          </div>
          <div id="leftEchart" class="leftEchart"></div>
        </div>
        <div class="rightDiv" v-if="pieChartOption.series[0].data.length != 0">
          <div class="flexCenter bottomTitle">
            <img class="echarImg" :src="echarImg" alt="" />
            <div class="echartText">{{ t("statistics.name18") }}</div>
          </div>
          <div id="rightEchart" class="leftEchart"></div>
        </div>
      </div>
    </div>
    <div v-else class="top15 tableDiv">
      <el-table
        :data="tableData"
        border
        class="tableNav"
        v-loading="tokenLoading"
      >
        <el-table-column :label="t('base.base126')">
          <template #default="scope">
            <span class="classTableItem">{{ scope.row.userName }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="t('header.header32')">
          <template #default="scope">
            <span class="classTableItem">{{ scope.row.departmentName }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="t('statistics.name19')">
          <template #default="scope">
            <el-row
              v-for="item in scope.row.modeldetail"
              :key="item.model"
              class="bottom12"
            >
              <el-col :span="12" class="classTableItem">{{
                item.questionTokens
              }}</el-col>
              <el-col :span="12" class="tokenNumber">
                <img
                  :src="ptValue == 0 ? AzureAI : ptValue == 2 ? aliAI : baiduAI"
                  alt=""
                  class="ai"
                />
                <el-tooltip popper-class="toolTip" :content="item.model">
                  {{ item.model }}
                </el-tooltip>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column :label="t('statistics.name20')">
          <template #default="scope">
            <el-row
              v-for="item in scope.row.modeldetail"
              :key="item.model"
              class="bottom12"
            >
              <el-col :span="12" class="classTableItem">{{
                item.messageTokens
              }}</el-col>
              <el-col :span="12" class="tokenNumber">
                <img
                  :src="ptValue == 0 ? AzureAI : ptValue == 2 ? aliAI : baiduAI"
                  alt=""
                  class="ai"
                />
                <el-tooltip popper-class="toolTip" :content="item.model">
                  {{ item.model }}
                </el-tooltip>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column :label="t('statistics.name21')">
          <template #default="scope">
            <el-row
              v-for="item in scope.row.modeldetail"
              :key="item.model"
              class="bottom12"
            >
              <el-col :span="12" class="classTableItem">{{
                item.realQuestionTokens
              }}</el-col>
              <el-col :span="12" class="tokenNumber">
                <img
                  :src="ptValue == 0 ? AzureAI : ptValue == 2 ? aliAI : baiduAI"
                  alt=""
                  class="ai"
                />
                <el-tooltip popper-class="toolTip" :content="item.model">
                  {{ item.model }}
                </el-tooltip>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column :label="t('statistics.name22')">
          <template #default="scope">
            <span class="classTableItem">{{ scope.row.embeddingTokens }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="pageDiv">
        <el-pagination
          :current-page="pageId"
          :page-size="perPage"
          :default-page-size="perPage"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="changeSize"
          @current-change="changePage"
        />
      </div>
    </div>
  </div>
  <div v-else class="tokenContent1"></div>
  <tipDialog
    v-if="show"
    @closeDialog="closeDialog"
    @saveDialog="saveDialog"
  ></tipDialog>
</template>

<script setup>
import bigView from "./components/big.vue";
import midelView from "./components/midel.vue";
import smillView from "./components/smill.vue";
import echarImg from "@/assets/statistics/echar.svg";
import AzureAI from "@/assets/manage/AzureAI.svg";
import aliAI from "@/assets/manage/aliAI.svg";
import baiduAI from "@/assets/manage/baiduAI.svg";
import tipDialog from "./components/dialog.vue";
import { useRouter } from "vue-router";
import { getYear } from "@/utils/utils";
import { onBeforeRouteLeave } from "vue-router";
import * as echarts from "echarts";
import { ref, onMounted } from "vue";
import api from "@/api";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
const {t} = useI18n()
const store = useStore();
const tableData = ref();
const navList = ref(["statistics.name23", "statistics.name24"]);
const navActive = ref(0);
const pageId = ref(1);
const perPage = ref(10);
const total = ref(100);
const userId = ref(store.state.userId);
const tokenTime = ref([]);
const billTime = ref(null);
const show = ref(true);
const pieChartOption = ref({
  tooltip: {
    trigger: "item",
  },
  legend: {
    top: "5%",
    left: "center",
    type:'scroll'
  },
  grid: {
    top: "15%",
    containLabel: true,
  },
  series: [
    {
      type: "pie",
      textStyle: {},
      radius: ["50%", "60%"],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: "center",
      },
      labelLine: {
        show: false,
      },
      data: [],
    },
  ],
});
const leftOption = ref({
  // 图例悬浮
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross",
      label: {
        backgroundColor: "#6a7985",
      },
    },
  },
  //图例
  legend: {
    data: [],
    type:'scroll'
  },
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: [
    {
      type: "category",
      boundaryGap: false,
      // x轴
      data: [],
    },
  ],
  yAxis: [
    {
      type: "value",
    },
  ],
  series: [],
});
const ztPtList = ref([
  {
    value: 0,
    label: "statistics.name25",
  },
  {
    value: 1,
    label: "statistics.name26",
  },
  {
    value: 2,
    label: "statistics.name27",
  },
]);
const zyzList = ref([]); //资源组列表
const ptValue = ref(0); //切换资源平台
const zyzValue = ref(0);
const timeActive = ref(0);
const time = ref(null);
const billList = ref({});
const timeList = ref([
  {
    name: "statistics.name28",
    value: 0,
  },
  {
    name: "statistics.name29",
    value: 1,
  },
]);
const timeType = ref(false);
const ldList = ref([
  {
    name: "statistics.name30",
    value: 0,
  },

  {
    name: "statistics.name31",
    value: 1,
  },
]);
const ldActive = ref(0);
const echarName = ref("");
const namePattern = ref("");
const tokenLoading = ref(false);
const router = useRouter();
onMounted(() => {
  show.value = true;
});
onBeforeRouteLeave((to, from, next) => {
  window.onresize = null;
  next();
});
// 打开echar
const getServe = (data) => {
  const uniqueGroups = Array.from(new Set(data.map((item) => item.groupName))); // 去重groupName
  return uniqueGroups.map((groupName) => {
    return {
      name: groupName,
      type: "line",
      // stack: "Total",
      showSymbol: false,
      lineStyle: {
        width: 0,
      },
      areaStyle: {},
      data: data
        .filter((item) => item.groupName === groupName)
        .map((item) => Number(item.cost.toFixed(2))), // 筛选出相同groupName的cost并放入data数组
    };
  });
};
// 不同意
const closeDialog = () => {
  router.go(-1);
};
//确定同意
const saveDialog = () => {
  show.value = false;
  getZyz();
};
// 禁用时间
const disabledDate = (time) => {
  const today = new Date();
  const twelveMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 12);
  return (
    time.getTime() > today.getTime() ||
    time.getTime() < twelveMonthsAgo.getTime()
  );
};
const openEchar = (item, list) => {
  // 折线图
  leftOption.value.legend.data = [];
  leftOption.value.xAxis[0].data = [];
  leftOption.value.series = [];
  pieChartOption.value.series[0].data = [];
  leftOption.value.legend.data = item.map((item) => item.groupName);
  leftOption.value.legend.data = [...new Set(leftOption.value.legend.data)];
  leftOption.value.xAxis[0].data = item.map((item) => getYear(item.date));
  leftOption.value.xAxis[0].data = [...new Set(leftOption.value.xAxis[0].data)];
  leftOption.value.series = getServe(item);
  if (list) {
    pieChartOption.value.series[0].data = list.map((item) => {
      return {
        value: Number(item.cost.toFixed(2)),
        name: item.groupName,
      };
    });
  }
  console.log(leftOption.value.series, "左");
  console.log(pieChartOption.value.series[0].data, "右");
  if (leftOption.value.series.length != 0) {
    echartInit();
    window.onresize = () => {
      echartInit();
    };
  }
  if (pieChartOption.value.series[0].data.length != 0) {
    rightEchartInit();
    window.onresize = () => {
      echartInit();
    };
  }
};
// 获取azure账单
const getAzure = () => {
  if (timeActive.value == 0 || billTime.value == null) {
    let currentDate = new Date();
    let thirtyDaysBefore = new Date(
      currentDate.setDate(currentDate.getDate() - 30)
    );
    billTime.value =
      thirtyDaysBefore.getFullYear() +
      "-" +
      (thirtyDaysBefore.getMonth() + 1).toString().padStart(2, "0");
    timeActive.value = 0;
    timeType.value = false;
  }
  api.chat[
    ptValue.value == 0
      ? "azureBill"
      : ptValue.value == 2
      ? "aliBill"
      : "baiduBill"
  ]({
    userId: userId.value,
    month: billTime.value,
    resourceGroupId: zyzList.value[zyzValue.value].id,
  }).then((res) => {
    if (res.returnCode == 200) {
      if (res.data.length != 0) {
        billList.value = res.data;
        openEchar(billList.value.daily, billList.value.detail_cost);
      } else {
        billList.value = {
          avg_user_cost: 0,
          cost: 0,
          daily: [],
          detail: [],
        };
      }
    }
  });
};
// 获取资源组
const getZyz = () => {
  api.manage
    .resourceBy({ userId: userId.value, resourceType: ptValue.value })
    .then((res) => {
      if (res.returnCode == 200) {
        zyzList.value = zyzList.value.concat(res.data);
        zyzValue.value = 0;
        getAzure();
      }
    });
};
// 切换搜索项
const changeSearch = (e, index) => {
  // 切换资源平台
  if (index == 1) {
    ptValue.value = e;
    if (e == 0) {
      ldActive.value = 0;
    } else {
      ldActive.value = 1;
    }
    if (navActive.value == 1) {
      getToken();
    } else {
      getAzure();
    }
  } else if (index == 2) {
    zyzValue.value = e;
    getAzure();
  } else if (index == 3) {
    // 等于1  就为null
    timeActive.value = e;
    if (e == 1) {
      timeType.value = true;
    } else {
      time.value = null;
      timeType.value = false;
    }
    getAzure();
  } else {
    ldActive.value = e;
  }
};
// 分页
const changeSize = (e) => {
  pageId.value = 1;
  perPage.value = e;
  getToken();
};
const changePage = (e) => {
  pageId.value = e;
  getToken();
};
// 切换nav
const changeNav = (index) => {
  navActive.value = index;
  ptValue.value = 0;
  namePattern.value = "";
  zyzValue.value = 0;
  timeActive.value = 0;
  time.value = null;
  tokenTime.value = [];
  timeType.value = false;
  if (index == 0) {
    getAzure();
  } else {
    getToken();
  }
};
const getToken = () => {
  tokenLoading.value = true;
  api.chat
    .tokenBill({
      resourceType: ptValue.value,
      namePattern: namePattern.value,
      userId: userId.value,
      startTime: tokenTime.value ? tokenTime.value[0] : null,
      endTime: tokenTime.value ? tokenTime.value[1] : null,
      pageId: pageId.value,
      perPage: perPage.value,
    })
    .then((res) => {
      tokenLoading.value = false;
      if (res.returnCode == 200) {
        tableData.value = res.data;
        total.value = res.totalNum;
      }
    })
    .catch((err) => {
      tokenLoading.value = false;
    });
};
const echartInit = () => {
  setTimeout(() => {
    echarts.dispose(document.getElementById("leftEchart"));
    let leftDom = document.getElementById("leftEchart");
    let leftChart = echarts.init(leftDom);
    leftChart.setOption(leftOption.value);
    window.addEventListener("resize", function () {
      setTimeout(() => {
        leftChart.resize();
      }, 100);
    });
  }, 100);
};
const rightEchartInit = () => {
  setTimeout(() => {
    echarts.dispose(document.getElementById("rightEchart"));
    let rightDom = document.getElementById("rightEchart");
    let rightChart = echarts.init(rightDom);
    rightChart.setOption(pieChartOption.value);
    window.addEventListener("resize", function () {
      setTimeout(() => {
        rightChart.resize();
      }, 100);
    });
  }, 100);
};
</script>

<style lang="scss" scoped>
.tokenContent {
  width: calc(100% - 60px);
  padding: 30px;
  margin: 0px;
  background-color: #fefefe;
  border-radius: 30px;
  box-shadow: 0px 0px 16px 0px #dbdfff7a;
  min-height: calc(100% - 60px);
}
.tokenContent1 {
  width: calc(100% - 60px);
  padding: 30px;
  margin: 0px;
  background-color: #fefefe;
  border-radius: 30px;
  box-shadow: 0px 0px 16px 0px #dbdfff7a;
  height: calc(100% - 60px);
}
.navTextAc {
  font-size: 20px;
  color: #0256ff;
  font-family: "Medium";
  margin-right: 24px;
  border-bottom: 3px solid #0256ff;
  padding-bottom: 6px;
  cursor: pointer;
}
.navText {
  font-size: 20px;
  color: #8a8a8a;
  font-family: "Medium";
  margin-right: 24px;
  border-bottom: 3px solid #8a8a8a;
  padding-bottom: 6px;
  cursor: pointer;
}
.flexCenter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.navTip {
  color: #8a8a8a;
  font-family: "Medium";
  width: calc(100% - 320px);
}
.navTipX {
  color: #f02d63;
  margin-right: 10px;
}
.roleDiv {
  border-radius: 16px;
  border: 2px solid #edeef6;
  display: flex;
  align-items: center;
  height: 24px;
  padding: 10px 20px;
  justify-content: space-between;
  margin-right: 12px;
}
.roleTitle {
  color: #8a8a8a;
  font-size: 18px;
  font-family: "Regular";
}
:deep(.el-dropdown) {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 10px;
  display: flex;
  align-items: center;
}
.exportBtn {
  border: 2px solid #edeef6;
  border-radius: 16px;
  background-color: #fefefe;
  padding: 10px 20px;
  font-size: 18px;
  color: #3376ff;
  font-family: "Regular";
  text-align: center;
  cursor: pointer;
}
.flexBetWeen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top15 {
  margin-top: 15px;
}
.bottomDiv {
  background-color: #f7f8ff;
  padding: 30px;
  border-radius: 30px;
  height: 451px;
  width: calc(66% - 70px);
  margin-right: 20px;
}
.rightDiv {
  background-color: #f7f8ff;
  padding: 30px;
  border-radius: 30px;
  height: 451px;
  width: calc(33% - 70px);
}
.echarImg {
  width: 24px;
  margin-right: 8px;
}
.bottomTitle {
  height: 30px;
}
.echartText {
  font-size: 20px;
  font-family: "Medium";
  color: #3d3d3d;
}
.leftEchart {
  height: calc(100% - 40px);
  margin-top: 10px;
}
.tableNav {
  height: calc(100% - 50px);
}
.pageDiv {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
}
.height40 {
  height: 40px;
}
.tableDiv {
  height: calc(100% - 120px);
}
.nameInput {
  width: 300px;
  height: 48px;
  border-radius: 16px;
  margin-right: 12px;
  :deep(.el-input__wrapper) {
    border-radius: 16px;
    box-shadow: 0px 0px 0px;
    font-size: 18px;
    font-family: "Regular";
  }
}
.classTableItem {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
:deep(.el-table--border th.el-table__cell) {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  background-color: #f7f8ff;
  height: 57px;
}
:deep(.el-range-editor.el-input__wrapper) {
  height: 44px;
  border: 2px solid #edeef6;
  box-shadow: 0px 0px 0px;
  border-radius: 16px;
}
:deep(.el-date-editor .el-range-input) {
  font-size: 18px;
  font-family: "Regular";
}
.ai {
  width: 16px;
  margin-right: 6px;
}
.tokenNumber {
  font-size: 16px;
  font-family: "Medium";
  color: #8a8a8a;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.bottom12 {
  margin-bottom: 12px;
}
:deep(.el-date-editor.el-input, .el-date-editor.el-input__wrapper) {
  height: 48px;
  border-radius: 16px;
  margin-right: 12px;
}
:deep(.el-input__wrapper) {
  border: 2px solid #edeef6;
  box-shadow: 0px 0px 0px;
  border-radius: 16px;
}
:deep(.el-input__inner) {
  font-size: 18px;
  font-family: "Regular";
}
</style>
