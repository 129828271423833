export default {
    CN: {
        table: {
            operationTime: '操作时间',
            userName: '用户名',
            email: '邮箱',
            ip: 'IP地址',
            department: '部门',
            operationType: '操作类型',
            operationModule: '所属模块',
            operationResult: '操作结果',
            operationContent: '操作内容',
        },
    },
    HK: {
        table: {
            operationTime: '操作時間',
            userName: '用戶名',
            email: '郵箱',
            ip: 'IP地址',
            department: '部門',
            operationType: '操作類型',
            operationModule: '所屬模組',
            operationResult: '操作結果',
            operationContent: '操作內容',
        },
    },
    US: {
        table: {
            operationTime: 'Operation Time',
            userName: 'Username',
            email: 'Email',
            ip: 'IP Address',
            department: 'Department',
            operationType: 'Operation Type',
            operationModule: 'Module',
            operationResult: 'Operation Result',
            operationContent: 'Operation Content',
        },
    },
};
