<template>
  <div class="tagBetWeen">
    <div class="tagCenterFlex">
      <img class="tabImg" :src="props.tagList[props.tagActive].icon" alt="" />
      <el-tooltip
        class="box-item"
        effect="dark"
        :content="
          props.tagActive == 0
            ? t('base.base266')
            : props.tagActive == 1
            ? t('base.base267')
            : t('base.base29')
        "
        placement="top-start"
      >
        <div class="tagTitle">
          {{
            props.tagActive == 0
              ? t("base.base266")
              : props.tagActive == 1
              ? t("base.base267")
              : t("base.base29")
          }}
        </div>
      </el-tooltip>

      <el-select
        :placeholder="t('base.base268')"
        class="inputSelete"
        v-if="props.tagActive == 0"
        v-model="baseId"
        @change="changeBaseId"
        v-selectLoadMore="goBase"
        :teleported="false"
      >
        <div style="overflow: hidden">
          <el-option
            v-for="item in baseList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </div>
      </el-select>
      <el-select
        :placeholder="t('base.base268')"
        class="inputSelete"
        v-if="props.tagActive == 1"
        v-model="templateId"
        @change="changeTemplateId"
        v-selectLoadMore="goBot"
        :teleported="false"
      >
        <el-option
          v-for="item in templateList"
          :key="item.templateId"
          :label="item.templateName"
          :value="item.templateId"
        />
      </el-select>
    </div>
    <div class="sdFlex">
      <div v-if="props.tagActive == 2" class="sdDivBtn">
        <img :src="sdImg" class="sdImgBtn" alt="" />
        <div>{{ t("bot.bot31") }}</div>
      </div>
      <img :src="inputClose" alt="" @click="closeTag" class="inputClose" />
    </div>
  </div>
</template>

<script setup>
import sdImg from "@/assets/input/sd.svg";
import inputClose from "@/assets/input/inputClose.svg";
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import api from "@/api";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useStore();
const props = defineProps(["tagList", "tagActive"]);
const emit = defineEmits(["closeTag", "changeBaseId", "changeTemplateId"]);
const userId = ref(store.state.userId);
const baseId = ref("");
const baseList = ref([]);
const templateId = ref(null);
const templateList = ref([]);
const pageId = ref(1);
const perPage = ref(10);
onMounted(() => {
  getBase();
  getTemplate();
});
watch(
  () => props.tagActive,
  (newValue, oldValue) => {
    if (props.tagActive == 0) {
      templateId.value = null;
    } else if (props.tagActive == 2) {
      baseId.value = null;
    }
  }
);
const closeTag = () => {
  emit("closeTag");
};
const goBase = () => {
  pageId.value++;
  getBase();
};
const goBot = () => {
  pageId.value++;
  getTemplate();
};
const getBase = () => {
  api.base
    .customLibraryList({
      userId: userId.value,
      namePattern: "",
      pageId: pageId.value,
      perPage: perPage.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        if (pageId.value == 1) {
          baseList.value = res.data;
        } else {
          baseList.value = baseList.value.concat(res.data);
        }
      }
    });
};
const getTemplate = () => {
  api.chat
    .getTemplate({
      userId: userId.value,
      pageId: pageId.value,
      perPage: perPage.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        if (pageId.value == 1) {
          templateList.value = res.data;
        } else {
          templateList.value = templateList.value.concat(res.data);
        }
      }
    });
};
const changeTemplateId = () => {
  emit(
    "changeTemplateId",
    templateId.value,
    templateList.value.filter((item) => item.templateId == templateId.value)
  );
};
const changeBaseId = () => {
  emit(
    "changeBaseId",
    baseId.value,
    baseList.value.filter((item) => item.id == baseId.value)
  );
};
</script>

<style lang="scss" scoped>
.tagBetWeen {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 17px 10px 27px;
  height: 38px;
  background-color: #f7f8ff;
  border-radius: 18px 18px 0px 0px;
  border: 2px solid #d9d9d9;
  border-bottom: 0px;
}
.tagCenterFlex {
  display: flex;
  align-items: center;
  width: calc(100% - 300px);
}
.tabImg {
  width: 25px;
  margin-right: 3px;
}
.tagTitle {
  font-size: 18px;
  font-family: "Medium";
  color: #3d3d3d;
  margin-right: 12px;
  max-width: calc(100% - 230px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.inputSelete {
  max-width: 205px;
  :deep(.el-select__wrapper) {
    border-radius: 12px;
    border: 2px solid #dbdfff;
    box-shadow: 0px 0px 0px 0px #ffffff;
    font-size: 18px;
    color: #3d3d3d;
    font-family: "Medium";
    height: 36px;
    max-width: 205px;
    background-color: transparent;
  }
}
.sdFlex {
  display: flex;
  align-items: center;
}
.sdImgBtn {
  width: 20px;
  margin-right: 3px;
}
.sdDivBtn {
  display: flex;
  align-items: center;
  background-color: #dbdfff;
  border-radius: 12px;
  padding: 6px;
  height: 24px;
  color: #0256ff;
  font-size: 18px;
  font-family: "Medium";
  margin-right: 30px;
  cursor: pointer;
}
.inputClose {
  width: 24px;
  cursor: pointer;
}
</style>
