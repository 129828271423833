<template>
  <iframe
    :srcdoc="props.htmlData"
    class="chunkHeight100"
    frameborder="0"
  ></iframe>
</template>

<script setup>
const props = defineProps(["htmlData"]);
</script>

<style lang="scss" scoped>
.chunkHeight100 {
  width: 100%;
  height: 100%;
}
</style>
