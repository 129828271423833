<script setup>
import { ref, onMounted, watch, nextTick } from "vue";
import baseAdd from "../components/baseAdd.vue";
import baseDelete from "../components/baseDelete.vue";
import baseWarning from "../components/baseWarning.vue";
import AddResoureView from "../components/baseAddResoure.vue";
import baseBill from "../components/baseBill.vue";
import baseBill2 from "../components/baseBill2.vue";
import baseBill3 from "../components/baseBill3.vue";
import baseSaml from "../components/baseSaml.vue";
import { useStore } from "vuex";
import api from "@/api/index";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useStore();
const userId = ref(store.state.userId);
const checkTrue = ref(true); //右侧树形参数
// 进度条
const redisNumber = ref(null);
const tipNumber = ref(null);
const loading = ref(true);
const loading2 = ref(false);
onMounted(() => {
  getMember();
  getRedis();
  getReminder();
});
// 资源组查询
const newMemberList = ref([]);
const getMember = () => {
  loading2.value = true;
  api.manage
    .getBaseListApi({
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        newMemberList.value = res.data;
        loading2.value = false;
      }
    });
};
const defaultProps = ref({
  children: "children",
  label: "name",
  disabled: "occupy",
  id: "id",
});
const departmentList = ref([]);
const rightDialog = ref(false);
const newObject = ref({});
const departIds = ref({
  userId: userId.value,
  operationType: "change",
  departmentIds: [],
});
watch(
  () => departIds.value,
  (newVal) => {
    departIds.value = newVal;
  }
);
// 关闭每一项 --修改部门组
const closeGroupItem = () => {
  rightDialog.value = false;
  api.manage.changeBaseApi(departIds.value).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("manage.baseForm.base63"));
      getMember();
    }
  });
};
const newGroupId = ref(null);
const newGroupName = ref("");
const newGroupList = ref([]);
// 点击资源组每一项
const handleItem = (item) => {
  departmentList.value = [];
  rightDialog.value = false;
  newGroupId.value = item.id;
  newGroupName.value = item.name;
  departIds.value.resourceGroupId = item.id;
  nextTick(() => {
    rightDialog.value = true;
    getItemList();
    // 部门树占用
    api.manage
      .getBaseOccupyApi({
        userId: userId.value,
        resourceGroupId: item.id,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          departmentList.value = res.data;
        }
      });
  });
};
// 资源组刷新
const getItemList = () => {
  api.manage
    .getBaseDetailApi({
      userId: userId.value,
      resourceGroupId: newGroupId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        newObject.value = res.data;
        newGroupList.value = res.data.groupDepartmentInfo;
        departIds.value.departmentIds = res.data.groupDepartmentInfo.map(
          (item) => item.departmentId
        );
        loading.value = false;
      }
    });
};
// 新建资源组
const showEdit = ref(false);
const handleAdd = () => {
  showEdit.value = true;
};
const handleClose = () => {
  showEdit.value = false;
};
const handleSave = (data) => {
  showEdit.value = false;
  data.userId = userId.value;
  api.manage.addBaseApi(data).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("manage.baseForm.base64"));
      getMember();
    }
  });
};
const formType = ref("add");
// 添加资源配置弹窗
const showAddResource = ref(false);
const addResource = () => {
  showAddResource.value = true;
  formType.value = "add";
  resoureInfo.value = {};
};
const resoureInfo = ref({});
// 单个资源配置点击
const editResoure = (item) => {
  showAddResource.value = true;
  api.manage
    .lookBaseDetailApi({
      resourceType: item.resourceType,
      functionTypeId: item.functionTypeId,
      configurationId: item.configurationId,
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        resoureInfo.value = res.data;
        formType.value = "edit";
      }
    });
};
// 设置默认
const setFomr = ref({
  userId: userId.value,
  functionTypeId: "",
  resourceType: "",
  configurationId: "",
  resourceGroupId: "",
});
watch(
  () => setFomr.value,
  (newVal) => {
    setFomr.value = newVal;
  }
);
const setDefault = (item) => {
  setFomr.value.functionTypeId = item.functionTypeId;
  setFomr.value.resourceType = item.resourceType;
  setFomr.value.configurationId = item.configurationId;
  setFomr.value.resourceGroupId = item.resourceGroupId;
  api.manage.setDefaultConfigApi(setFomr.value).then((res) => {
    if (res.returnCode == 200) {
      message.success(t("manage.baseForm.base65"));
      getItemList();
    }
  });
};
// 标题上的修改资源组名称
const showName = ref(true);
const editName = () => {
  showName.value = false;
};
const saveName = () => {
  api.manage
    .updateGroupNameApi({
      userId: userId.value,
      groupId: newGroupId.value,
      groupName: newGroupName.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("manage.baseForm.base63"));
        getMember();
        getItemList();
      }
    });
  showName.value = true;
};
// redis配置查询
const getRedis = () => {
  api.manage
    .getBaseRedisApi({
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        redisNumber.value = res.data;
      }
    });
};
const changeStatus = ref(true);
const changeBtn = () => {
  changeStatus.value = !changeStatus.value;
};
const changeRedis = () => {
  api.manage
    .changeBaseRedisApi({
      userId: userId.value,
      redisMemorySize: redisNumber.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("manage.baseForm.base63"));
        changeStatus.value = true;
      }
    });
};
// 个人时长查询
const newDays = ref(null);
const timeStatus = ref(true);
const getReminder = () => {
  api.manage
    .getBaseTimeApi({
      userId: userId.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        newDays.value = res.data.days;
        tipNumber.value = ((30 - newDays.value) / 30) * 100;
      }
    });
};
const timeBtn = () => {
  timeStatus.value = !timeStatus.value;
};
// 修改个人时长
const changeTime = () => {
  api.manage
    .changeBaseTimeApi({
      userId: userId.value,
      days: newDays.value,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("manage.baseForm.base63"));
        tipNumber.value = ((30 - newDays.value) / 30) * 100;
        timeStatus.value = true;
      }
    });
};
// 删除资源组弹窗
const showDelete = ref(false);
const handleDelete = () => {
  getItemList();
  showDelete.value = true;
};
const handleDelete2 = (item) => {
  newGroupId.value = item.id;
  getItemList();
  showDelete.value = true;
};
// 放弃删除资源弹窗
const handleDeleteCancel = () => {
  showDelete.value = false;
};
// 确认删除资源弹窗
const handleSubmit = () => {
  showDelete.value = false;
  if (newObject.value?.configurationInfo.length == 0) {
    // 调用删除资源组接口
    api.manage
      .deleteGroupItemApi({
        userId: userId.value,
        resourceGroupId: newGroupId.value,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("manage.baseForm.base66"));
          showDelete.value = false;
          rightDialog.value = false;
          getMember();
        }
      });
  } else {
    warningShow.value = true;
  }
};
// 无法删除资源弹窗
const warningShow = ref(false);
// 放弃删除资源组
const handleWarning = () => {
  warningShow.value = false;
};
// 前往清空资源组
const goClean = () => {
  warningShow.value = false;
  rightDialog.value = true;
};
// 关闭资源配置
const closeDialog = () => {
  rightDialog.value = false;
};

// 关闭添加资配置组弹窗
const handleCancel = () => {
  showAddResource.value = false;
};
// 确认添加资源配置弹窗
const handleAddResoure = () => {
  showAddResource.value = false;
  getItemList();
};
// 确认修改资源配置按钮
const saveResoure = (data) => {
  showAddResource.value = false;
  api.manage.updateBaseApi(data).then((res) => {
    if (res.returnCode === 200) {
      message.success(t("manage.baseForm.base63"));
      getItemList();
    }
  });
};
// 删除按钮
const toolDelete = (item) => {
  api.manage
    .deleteConfigItemApi({
      userId: userId.value,
      configurationId: item.configurationId,
      functionTypeId: item.functionTypeId,
      resourceType: item.resourceType,
    })
    .then((res) => {
      if (res.returnCode === 200) {
        message.success(t("manage.baseForm.base66"));
        getItemList();
      }
    });
};
// 三个平台配置
const moreAzure = require("@/assets/manage/Azure.svg");
const moreAli = require("@/assets/manage/ali.svg");
const moreBaidu = require("@/assets/manage/baidu.svg");
// 资源账单
const billList = ref([
  {
    url: moreAzure,
  },
  // {
  //   url: moreAli,
  // },
  // {
  //   url: moreBaidu,
  // },
]);
// 资源平台账单配置弹窗
const showBill = ref(false);
const showBill2 = ref(false);
const showBill3 = ref(false);
const billBtn = (index) => {
  if (index === 0) {
    showBill.value = true;
  } else if (index === 1) {
    showBill2.value = true;
  } else if (index === 2) {
    showBill3.value = true;
  }
};
// 资源平台/关闭
const handleBill = () => {
  showBill.value = false;
};
const handleBill2 = () => {
  showBill2.value = false;
};
const handleBill3 = () => {
  showBill3.value = false;
};
const handleBillAdd = (form) => {
  showBill.value = false;
  api.manage.changeBaseBillApi(form).then((res) => {
    if (res.returnCode === 200) {
      message.success(t("manage.baseForm.base63"));
    }
  });
};
const handleBillAdd2 = (form) => {
  showBill2.value = false;
  api.manage.changeBaseAliApi(form).then((res) => {
    if (res.returnCode === 200) {
      message.success(t("manage.baseForm.base63"));
    }
  });
};
const handleBillAdd3 = (form) => {
  showBill3.value = false;
  api.manage.changeBaseBaiduApi(form).then((res) => {
    if (res.returnCode === 200) {
      message.success(t("manage.baseForm.base63"));
    }
  });
};
// SAML认证
const showSaml = ref(false);
const samlBtn = () => {
  showSaml.value = true;
};
const handleSaml = () => {
  showSaml.value = false;
};
const handleSamlAdd = () => {
  // 完成SAML认证
  showSaml.value = false;
};

const customColor = ref("#11D7B2");
// 开关
const value1 = ref(false);
</script>

<template>
  <div class="main" v-loading="loading2">
    <div class="leftBox">
      <div class="info">
        <div>{{ $t("manage.baseForm.base67") }}</div>
        <div class="intro">{{ $t("manage.baseForm.base68") }}</div>
      </div>
      <div class="groupBox">
        <div
          class="groupItem"
          @click="handleAdd"
          v-if="$hasPermission('10054') == true"
        >
          <div class="tit">
            <div class="tit_img">
              <img src="@/assets/manage/addGroup.svg" alt="" />
            </div>
            <div class="tit_name" style="color: #0256ff">
              {{ $t("manage.baseForm.base69") }}
            </div>
          </div>
          <div class="range1">
            {{ $t("manage.baseForm.base70") }}
          </div>
        </div>
        <template v-if="$hasPermission('10052') == true">
          <div
            class="groupItem"
            v-for="item in newMemberList"
            :key="item"
            @dblclick="handleItem(item)"
          >
            <div class="tit">
              <div class="tit_img">
                <img src="@/assets/manage/groupItem.svg" alt="" />
              </div>
              <div class="tit_name">{{ item.name }}</div>
            </div>
            <div class="range1">
              <div class="right_range">{{ $t("manage.baseForm.base71") }}:</div>
              <baseName :avatars="item?.departments" :number="2"></baseName>
            </div>
            <div
              class="delete"
              @click="handleDelete2(item)"
              v-if="$hasPermission('10056') == true"
            >
              <img src="@/assets/manage/delete.svg" alt="" />
            </div>
          </div>
        </template>
      </div>
      <div class="info">
        <div>
          Redis {{ $t("manage.baseForm.base72") }}
          <img
            v-if="changeStatus && $hasPermission('10060') == true"
            @click="changeBtn"
            class="editImg"
            src="@/assets/manage/editImg.svg"
            alt=""
          />
        </div>
        <div
          class="intro"
          style="display: flex; justify-content: space-between"
        >
          <div class="editBtn2">{{ $t("manage.baseForm.base73") }}</div>
          <div v-if="!changeStatus" @click="changeRedis" class="editBtn1">
            {{ $t("manage.baseForm.base74") }}
          </div>
        </div>
        <div class="progress">
          <div class="left">
            <el-progress
              :percentage="redisNumber"
              :color="customColor"
              size="mini"
            >
              <span></span>
            </el-progress>
          </div>
          <div class="right">
            <el-input
              v-model="redisNumber"
              :placeholder="$t('manage.baseForm.base75')"
              type="number"
              max="29"
              min="1"
              :disabled="changeStatus"
              onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode)))"
            >
              <template #append>GB</template>
            </el-input>
          </div>
        </div>
      </div>
      <div class="info">
        <div>
          {{ $t("manage.baseForm.base76") }}
          <img
            v-if="timeStatus && $hasPermission('10061') == true"
            @click="timeBtn"
            class="editImg"
            src="@/assets/manage/editImg.svg"
            alt=""
          />
        </div>
        <div
          class="intro"
          style="display: flex; justify-content: space-between"
        >
          <div class="editBtn2">{{ $t("manage.baseForm.base77") }}</div>
          <div v-if="!timeStatus" @click="changeTime" class="editBtn1">
            {{ $t("manage.baseForm.base74") }}
          </div>
        </div>
        <div class="progress">
          <div class="left">
            <el-progress :percentage="tipNumber" :color="customColor">
              <span></span>
            </el-progress>
          </div>
          <div class="right">
            <el-input
              v-model="newDays"
              type="number"
              max="29"
              min="1"
              :disabled="timeStatus"
              onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode)))"
            >
              <template #append>{{ $t("manage.baseForm.base78") }}</template>
            </el-input>
          </div>
        </div>
      </div>
      <div class="info" v-if="$hasPermission('10058') == true">
        <div>{{ $t("manage.baseForm.base79") }}</div>
        <div class="intro">{{ $t("manage.baseForm.base80") }}</div>
        <div class="more">
          <div
            class="item"
            v-for="(item, index) in billList"
            :key="index"
            @click="billBtn(index)"
          >
            <img :src="item.url" alt="" />
          </div>
        </div>
      </div>
      <div class="info">
        <div>
          {{ $t("manage.baseForm.base81") }}
          <el-switch v-model="value1" disabled />
        </div>
        <div class="intro">{{ $t("manage.baseForm.base82") }}</div>
      </div>
      <div class="info end" v-if="value1">
        <div class="more">
          <div class="item1" @dblclick="samlBtn">
            <img src="@/assets/manage/SAML.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="rightDialog && $hasPermission('10053') == true"
      class="rightDialog"
    >
      <div class="rightBox" v-loading="loading">
        <div class="name">
          <div style="display: flex">
            <div class="name_img">
              <img src="@/assets/manage/groupItem.svg" alt="" />
            </div>
            <div class="name_text" v-if="showName">
              {{ newObject?.groupBasicInfo?.groupName }}
            </div>
            <el-input
              v-else
              v-model="newGroupName"
              :placeholder="t('manage.baseForm.base83')"
              @blur="saveName"
            ></el-input>
            <el-button
              link
              :disabled="$hasPermission('10055') == false"
              class="edit_img"
              @click="editName"
            >
              <img src="@/assets/manage/editImg.svg" alt="" />
            </el-button>
            <el-button
              class="edit_img"
              link
              @click="handleDelete"
              :disabled="$hasPermission('10056') == false"
            >
              <img src="@/assets/manage/delete.svg" alt="" />
            </el-button>
          </div>
          <div>
            <el-icon class="close" @click="closeDialog">
              <Close />
            </el-icon>
          </div>
        </div>
        <div class="rangeBox">
          <div class="item_box">
            <div class="tit">{{ $t("manage.baseForm.base84") }}</div>
            <el-tree-select
              v-model="departIds.departmentIds"
              clearable
              multiple
              :data="departmentList"
              show-checkbox
              :check-strictly="checkTrue"
              check-on-click-node
              class="treeClass"
              :placeholder="t('manage.baseForm.base85')"
              :props="defaultProps"
              node-key="id"
            >
              <template #default="{ data: { name } }">
                <span style="float: left">{{ name }}</span>
              </template>
            </el-tree-select>
            <div class="tit">
              <div>{{ $t("manage.baseForm.base86") }}</div>
              <el-button
                :disabled="$hasPermission('10054') == false"
                class="addBox"
                @click="addResource"
              >
                {{ $t("manage.baseForm.base87") }}
              </el-button>
            </div>
            <div
              v-for="(item, index) in newObject.configurationInfo"
              :key="index"
            >
              <div class="tit_tip">
                {{ item.name }}{{ $t("manage.baseForm.base88") }}
              </div>
              <div
                class="mo_item"
                v-for="(items, indexs) in item.value"
                :key="indexs"
              >
                <div class="top">
                  <!-- A图片 -->
                  <div class="icon1" v-if="items.resourceType == 0">
                    <img src="@/assets/manage/AzureAI.svg" alt="" />
                  </div>
                  <!-- ali图片 -->
                  <div class="icon2" v-if="items.resourceType == 2">
                    <img src="@/assets/manage/aliAI.svg" alt="" />
                  </div>
                  <!-- baidu图片 -->
                  <div class="icon1" v-if="items.resourceType == 1">
                    <img src="@/assets/manage/baiduAI.svg" alt="" />
                  </div>
                  <div class="span">{{ items.modelType }}</div>
                </div>
                <div class="button" v-if="items.isDefault == 1">
                  {{ items.name }}
                  <div class="ren">{{ $t("manage.baseForm.base89") }}</div>
                </div>
                <div class="tool_box">
                  <div v-if="items.isDefault == 0">
                    <el-button class="set" link @click="setDefault(items)">
                      {{ $t("manage.baseForm.base90") }}
                    </el-button>
                  </div>
                  <el-button
                    link
                    :disabled="$hasPermission('10055') == false"
                    class="tool"
                    @click="editResoure(items)"
                  >
                    <img src="@/assets/manage/editImg.svg" alt="" />
                  </el-button>
                  <el-button
                    link
                    :disabled="$hasPermission('10056') == false"
                    class="tool"
                    @click="toolDelete(items)"
                  >
                    <img src="@/assets/manage/delete.svg" alt="" />
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="footer"
          @click="closeGroupItem"
          v-if="$hasPermission('10055') == true"
        >
          {{ $t("manage.baseForm.base74") }}
        </div>
      </div>
    </div>
  </div>
  <!-- 新建资源组 -->
  <baseAdd
    :show="showEdit"
    @handleClose="handleClose"
    @handleSave="handleSave"
  ></baseAdd>
  <!-- 删除资源组弹窗 -->
  <baseDelete
    :showDelete="showDelete"
    @handleSubmit="handleSubmit"
    @handleDeleteCancel="handleDeleteCancel"
  >
  </baseDelete>
  <!-- 无法删除资源弹窗 -->
  <baseWarning
    :warningShow="warningShow"
    @handleWarning="handleWarning"
    @goClean="goClean"
  ></baseWarning>
  <!-- 添加资源组配置弹窗 -->
  <AddResoureView
    :showAddResource="showAddResource"
    :formType="formType"
    :resoureInfo="resoureInfo"
    :newGroupId="newGroupId"
    @handleCancel="handleCancel"
    @handleAddResoure="handleAddResoure"
    @saveResoure="saveResoure"
  >
  </AddResoureView>
  <!-- 资源平台账单配置弹窗 -->
  <baseBill
    :showBill="showBill"
    @handleBill="handleBill"
    @handleBillAdd="handleBillAdd"
  >
  </baseBill>
  <baseBill2
    :showBill2="showBill2"
    @handleBill2="handleBill2"
    @handleBillAdd2="handleBillAdd2"
  >
  </baseBill2>
  <baseBill3
    :showBill3="showBill3"
    @handleBill3="handleBill3"
    @handleBillAdd3="handleBillAdd3"
  >
  </baseBill3>
  <!-- SAML认证 -->
  <baseSaml
    :showSaml="showSaml"
    @handleSaml="handleSaml"
    @handleSamlAdd="handleSamlAdd"
  ></baseSaml>
</template>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:deep(.el-progress-bar__outer) {
  height: 10px !important;
}

:deep(.el-select__wrapper) {
  min-height: 40px;
  border-radius: 16px;
}

:deep(.el-select__tags-text) {
  color: #3d3d3d;
}

:deep(.el-tag) {
  background-color: #dbdfff;
}

:deep(.el-select__input) {
  color: #3d3d3d;
}

.main {
  width: 100%;
  height: 100%;
  padding: 30px;
  background-color: #fff;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
}

.footer {
  width: 100%;
  height: 40px;
  border-radius: 16px;
  text-align: center;
  line-height: 40px;
  background-color: #767bfa;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.close {
  color: #8a8a8a;
  font-size: 22px;
  cursor: pointer;
}

.close:hover {
  color: #0256ff;
}

.leftBox {
  width: 710px;
  margin-right: 40px;

  .info {
    font-size: 16px;
    margin-bottom: 16px;
    color: #3d3d3d;

    .intro {
      font-size: 15px;
      color: #8a8a8a;
      font-weight: 400;
      margin-top: 4px;
    }

    .more {
      display: flex;
      justify-content: space-between;
      margin-top: 14px;

      .item {
        width: 225px;
        height: 56px;
        border-radius: 18px;
        position: relative;
        display: inline-block;

        img {
          width: 100%;
          height: 100%;
          border-radius: 18px;
        }
      }
      .item1 {
        width: 225px;
        height: 56px;
        border-radius: 18px;
        position: relative;
        display: inline-block;

        img {
          width: 100%;
          height: 100%;
          border-radius: 18px;
        }
      }
    }

    .progress {
      display: flex;
      width: 100%;
      height: 56px;
      border-radius: 20px;
      padding: 10px 18px;
      margin-top: 14px;
      background-color: #f7f8ff;
      box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);

      .left {
        width: 510px;
        margin: auto 0;
        margin-right: 20px;
      }
    }
  }
}
.item::after {
  content: "";
  position: absolute;
  top: 18px;
  right: 38px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #11d7b2;
}
.end {
  padding-bottom: 30px !important;
}

.editImg {
  margin-bottom: -4px;
  display: inline-block;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.mo_item {
  position: relative;
  width: 100%;
  height: 70px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding: 8px 15px;

  .top {
    display: flex;
    margin-bottom: 8px;

    .icon1 {
      margin-top: 4px;
      margin-right: 5px;
      width: 16px;
      height: 16px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .icon2 {
      width: 16px;
      height: 11px;
      margin-right: 5px;
      margin-top: 4px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .span {
      color: #8a8a8a;
      font-size: 16px;
    }
  }

  .button {
    color: #3d3d3d;
    font-size: 16px;
    display: flex;

    .ren {
      color: #14ae5c;
      background-color: #b8e6cd;
      border-radius: 4px;
      padding: 1px 4px;
      margin-left: 8px;
      font-size: 14px;
    }
  }

  .tool_box {
    position: absolute;
    display: flex;
    justify-content: end;
    top: 25px;
    right: 15px;

    .tool {
      background-color: #f7f8ff;
      width: 26px;
      height: 26px;
      margin-left: 6px;
      border-radius: 6px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .set {
      font-size: 14px;
      padding: 3px 9px;
      background-color: #f7f8ff;
      border-radius: 6px;
      color: #3376ff;
      cursor: pointer;
    }
  }
}

.rightDialog {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
  z-index: 900;
}

.editBtn1 {
  margin-left: 10px;
  height: 28px;
  padding: 4px 8px;
  background-color: #767bfa;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 10px;
  font-size: 12px;
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.1);
}

.editBtn2 {
  width: 500px;
}

.rightBox {
  width: 460px;
  height: 720px;
  background-color: #f7f8ff;
  border-radius: 18px;
  padding: 30px;
  box-shadow: 4px 5px 10px 4px rgba(0, 0, 0, 0.1);

  .name {
    display: flex;
    justify-content: space-between;

    .name_img {
      width: 28px;
      height: 28px;
      border-radius: 8px;
      margin-right: 8px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }

    .name_text {
      max-width: 260px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #3d3d3d;
      font-size: 19px;
      margin-right: 8px;
    }

    .edit_img {
      width: 20px;
      height: 20px;
      margin-top: 3px;
      margin-right: 4px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .rangeBox {
    margin-top: 20px;
    height: 560px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 20px;

    .item_box {
      .tit {
        font-size: 18px;
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;

        .addBox {
          color: #3376ff;
          background-color: #eceeff;
          padding: 5px 12px;
          border-radius: 12px;
          cursor: pointer;
        }
      }

      .tit_tip {
        font-size: 16px;
        color: #8a8a8a;
        margin-bottom: 10px;
      }
    }

    .treeClass {
      margin-bottom: 12px;
    }
  }
}

.groupBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;

  .groupItem {
    width: 347px;
    height: 80px;
    border-radius: 16px;
    background-color: #f7f8ff;
    padding: 12px 20px;
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);

    .tit {
      display: flex;
      color: #3d3d3d;

      .tit_img {
        width: 28px;
        height: 28px;
        border-radius: 8px;
        margin-right: 12px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
        }
      }

      .tit_name {
        font-size: 18px;
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .range {
      margin-top: 6px;
      font-size: 17px;
      color: #8a8a8a;
    }

    .range1 {
      display: flex;
      margin-top: 6px;
      font-size: 17px;
      color: #8a8a8a;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .range1_item {
        margin-left: 6px;
      }

      .right_range {
        margin-right: 6px;
      }
    }

    .delete {
      position: absolute;
      top: 22px;
      right: 18px;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: none;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }
}

.groupItem:hover {
  background-color: #eceeff;

  .delete {
    display: block;
  }
}
</style>
