<template>
  <div class="flexCenter">
    <el-dropdown placement="top" class="marginRgiht12" @command="changeqxd">
      <div class="imgDiv">
        <div class="imgCenter">
          <img :src="fbl" alt="" class="fbl" />
          <span class="imgDrow">{{ t('base.base254') }}</span>
        </div>
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item
            v-for="item in qxdList"
            :key="item.value"
            :command="item.value"
          >
            <img :src="imgIcon" alt="" />
            <span class="itemDrap">{{ t(item.name) }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <el-dropdown placement="top" class="marginRgiht12" @command="changebl">
      <div class="imgDiv">
        <div class="imgCenter">
          <img :src="cc" alt="" class="cc" />
          <span class="imgDrow">{{ t('base.base255') }}</span>
        </div>
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item
            v-for="item in blList"
            :key="item.value"
            :command="item.value"
          >
            <img :src="imgIcon" alt="" class="imgIcon"/>
            <span class="itemDrap">{{ item.name }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <el-dropdown placement="top" class="marginRgiht12" @command="changeStyle">
      <div class="imgDiv">
        <div class="imgCenter">
          <img :src="fg" alt="" class="fg" />
          <span class="imgDrow">{{ t('base.base256') }}</span>
        </div>
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item
            v-for="item in styleList"
            :key="item.value"
            :command="item.value"
          >
            <img :src="imgIcon" alt="" />
            <span class="itemDrap">{{ t(item.name) }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script setup>
import fbl from "@/assets/input/fbl.svg";
import cc from "@/assets/input/cc.svg";
import fg from "@/assets/input/fg.svg";
import imgIcon from "@/assets/input/imgIcon.svg";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
// 清晰度
const qxdList = ref([
  {
    name: "base.base257",
    value: 1,
  },
  {
    name: "base.base258",
    value: 0,
  },
]);
// 切换清晰度
const changeqxd = (e) => {
  console.log(e);
};
// 比例
const blList = ref([
  {
    name: "1:1",
    value: 3,
  },
  {
    name: "9:16",
    value: 1,
  },
  {
    name: "16:9",
    value: 2,
  },
]);
// 切换风格
const changebl = (e) => {
  console.log(e);
};
// 比例
const styleList = ref([
  {
    name: "base.base259",
    value: 0,
  },
  {
    name: "base.base260",
    value: 1,
  },
]);
// 切换比例
const changeStyle = (e) => {
  console.log(e);
};
</script>

<style lang="scss" scoped>
.marginRgiht12 {
  margin-right: 12px;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.imgDiv {
  height: 32px;
  border: 1px solid #dbdfff;
  border-radius: 10px;
  padding: 2px;
  display: flex;
  align-items: center;
}
.imgCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5px 4.5px 3.5px 4.5px;
  height: calc(100% - 6px);
  width: calc(100% - 9px);
  background-color: #eceeff;
  border-radius: 8px;
}
.fbl {
  width: 16px;
  margin-right: 5px;
}
.imgDrow {
  font-size: 18px;
  color: #0256ff;
  font-family: "Medium";
}
.itemDrap {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
  margin-left: 2px;
}
.cc {
  width: 19px;
  margin-right: 5px;
}
.fg {
  width: 25px;
  margin-right: 5px;
}
.imgIcon{
  width: 25px;
}
</style>
