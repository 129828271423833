<template>
  <div class="chatContent">
    <div :class="lookDialog ? 'fileIndex' : 'chatIndex'">
      <!-- gpt切换 -->
      <el-select class="chatSelete" v-model="model" v-if="routeType == 'chat'">
        <el-option
          v-for="item in modelList"
          :key="item.value"
          :label="item.label"
          :value="item.label"
        />
      </el-select>
      <!-- 聊天 -->
      <div
        :class="{
          width10101:
            (rightType && !historyDialog && !lookDialog) ||
            (!rightType && historyDialog && !lookDialog), //只展示右侧一个清空
          width1010: !rightType && !lookDialog, //没有展示
          width10102: rightType && historyDialog && !lookDialog, //俩同时
          width10103: !rightType && !historyDialog && lookDialog,
        }"
        ref="leftDiv"
      >
        <div
          class="talkDiv"
          :style="'height:' + qaHeight + 'px'"
          ref="containerRef"
        >
          <!-- 默认 -->
          <messageView
            :message="templateCall ? templateCall : t('base.base186')"
            class="messageView"
          ></messageView>
          <botQuestion :ideas="idea" @saveMessage="saveMessage"></botQuestion>
          <div v-for="(item, index) in chatList" :key="index">
            <!-- qa -->
            <qaIndex
              type="chat"
              :item="item"
              :id="'talk' + item.conversationId"
              @openFileView="openFileView"
            >
              <template v-slot:question>
                <div class="flexCenterEnd">
                  <el-tooltip
                    popper-class="toolTip"
                    class="box-item"
                    :content="t('base.base194')"
                  >
                    <div class="lineIcon" @click="copyFn(item)">
                      <img :src="copySvg" alt="" class="wid36" />
                    </div>
                  </el-tooltip>

                  <!-- <el-tooltip popper-class="toolTip"
                    class="box-item"
                    content="编辑"
                    v-if="routeType == 'chat'"
                  >
                    <div class="lineIcon" @click="openEdit(item)">
                      <img :src="editSvg" alt="" class="wid36" />
                    </div>
                  </el-tooltip> -->
                </div>
              </template>
            </qaIndex>
            <!-- 操作类 -->
            <setVue
              v-if="item.conversationId"
              @openHistory="openHistory"
              @scrollBottom="scrollBottom"
              :routeType="routeType"
              :rightType="rightType"
              :item="item"
              :model="model"
              :lookDialog="lookDialog"
              :historyDialog="historyDialog"
              :chatList="chatList"
              @getTalk="getTalk"
            ></setVue>
            <!-- 编辑 -->
            <div class="editFlex" v-if="item.editType">
              <img
                :src="closeHistory"
                alt=""
                class="historyCloseIcon"
                @click="closeEdit(item)"
              />
              <div class="editDiv">
                <el-input
                  :placeholder="t('base.base229')"
                  class="editInput"
                  v-model="editMessage"
                ></el-input>
                <img
                  :src="sendSvg"
                  v-if="editMessage.length == 0"
                  alt=""
                  class="sendImg"
                />
                <img :src="sendYes" v-else class="sendImg" />
              </div>
            </div>
            <!-- 点踩提醒 -->
            <div class="tipSetDiv" v-if="item.tipDialog">
              <div class="tipTitleFlex">
                <div class="tipCenter">
                  <img :src="qxdz" alt="" class="qxdz" />
                  <div class="tipTitle">{{ t("base.base230") }}</div>
                </div>
                <img
                  :src="closeHistory"
                  alt=""
                  class="historyCloseIcon"
                  @click="item.tipDialog = false"
                />
              </div>
              <div style="display: flex">
                <div
                  @click="tipActive = index"
                  :class="tipActive == index ? 'tipActive' : 'tipNoActive'"
                  v-for="(item, index) in tipList"
                  :key="item"
                >
                  {{ t(item) }}
                </div>
              </div>
              <div class="flexBetween" v-if="tipActive == 1 || tipActive == 3">
                <el-input
                  class="tipInput"
                  :placeholder="t('base.base231')"
                ></el-input>
                <el-button type="primary" class="tipSaveBtn">{{
                  t("base.base212")
                }}</el-button>
              </div>
            </div>
            <!-- 提示语句 -->
            <!-- <div
              class="tipStart"
              v-if="
                index == chatList.length - 1 &&
                chatList[chatList.length - 1].conversationId &&
                routeType == 'chat'
              "
            >
              <tipView
                class="tipDiv"
                v-for="item in messageList"
                :key="item"
                @click="saveMessage(item)"
                >{{ item }}</tipView
              >
            </div> -->
          </div>
        </div>
        <div ref="inputBottomDiv">
          <inputIndex
            v-if="routeType == 'chat'"
            ref="inputRef"
            :rightType="rightType"
            :lookDialog="lookDialog"
            :historyDialog="historyDialog"
            :chatList="chatList"
            @sendMessage="sendMessage"
            @getDivHeight="getDivHeight"
            @openFileView="openFileView"
            @openBaseFile="openBaseFile"
            @clearChatList="clearChatList"
          ></inputIndex>
        </div>
      </div>
      <!-- 历史记录 -->
      <transition name="expand-transition">
        <div
          v-if="historyDialog"
          :class="{
            historyDialog: rightType && historyDialog, //俩都展示
            hisOne: !rightType && historyDialog, //志站是一个
          }"
        >
          <div class="historyTopDiv">
            <div>{{ t("base.base199") }}</div>
            <div @click="historyDialog = false" class="closeDiv">
              <img :src="closeHistory" class="historyCloseIcon" alt="" />
            </div>
          </div>
          <div class="hisToryItem">
            <!-- 提问 -->
            <div class="hisBetween">
              <div class="hisCenter">
                <div
                  class="txImg"
                  :style="'background-color:' + getRandomColor1()"
                >
                  {{ userName.slice(0, 1) }}
                </div>
                <div class="userNmae">
                  {{ userName }} {{ t("base.base232") }}
                </div>
              </div>
              <div class="historyTime">
                {{ getTime(historyList.questionCreateTime) }}
              </div>
            </div>
            <!-- 问题 -->
            <div class="hisQ hisCenter">
              {{ historyList.question }}
            </div>
            <!-- 回大人 -->
            <div class="hisBetween">
              <div class="hisCenter">
                <div
                  class="txImg"
                  :style="'background-color:' + getRandomColor1()"
                >
                  N
                </div>
                <div class="userNmae">{{ t("base.base233") }}</div>
              </div>
              <div class="historyTime">
                {{ getTime(historyList.answerCreatTime) }}
              </div>
            </div>
            <!-- 回答 -->
            <div class="hisQ">
              <div class="fillCenter" v-if="historyList.answers.length > 1">
                <img
                  :src="historyList.chatActive != 1 ? leftFill : leftNoFill"
                  alt=""
                  class="fillImg"
                  @click="changeItemPage(false)"
                />
                <span
                  :class="historyList.chatActive == 1 ? 'fillColor' : ''"
                  class="fillText"
                  >{{ historyList.chatActive }}</span
                >
                <span
                  class="fillText"
                  :class="
                    historyList.chatActive == historyList.answers.length
                      ? 'fillColor'
                      : ''
                  "
                  >/{{ historyList.answers.length }}</span
                >
                <img
                  :src="
                    historyList.chatActive == historyList.answers.length
                      ? rightNoFill
                      : rightFill
                  "
                  alt=""
                  class="fillImg"
                  @click="changeItemPage(true)"
                />
              </div>
              <div
                :class="
                  historyList.answers.length > 1
                    ? 'text92 historyItemText'
                    : 'historyItemText'
                "
              >
                {{
                  historyList.answers[historyList.chatActive - 1].answerContent
                }}
              </div>
            </div>
            <!-- Ai微模型来源 -->
            <div
              class="userNmae marginHis10"
              v-if="
                historyList.answers[historyList.chatActive - 1].answerResource
                  .fileResource.length != 0
              "
            >
              {{ t("base.base234") }}
            </div>
            <div
              v-for="(items, index) in historyList.answers[
                historyList.chatActive - 1
              ].answerResource.fileResource"
              :key="index"
              class="hisItemText"
            >
              <div class="dcflex">
                <span class="marginRightHistory">[{{ index + 1 }}]</span>
                <span class="marginRightHistory">{{ items.fileName }}</span>
                <!-- <div class="marginRightHistory">Word</div>
                  <div class="marginRightHistory">3.2MB</div> -->
              </div>
            </div>
            <div
              class="userNmae marginHis10"
              v-if="
                historyList.answers[historyList.chatActive - 1].answerResource
                  .sessionResource.length != 0
              "
            >
              {{ t("base.base311") }}
            </div>
            <div
              v-for="(items, index) in historyList.answers[
                historyList.chatActive - 1
              ].answerResource.sessionResource"
              :key="index"
              class="hisItemText"
            >
              <div class="dcflex">
                <span class="marginRightHistory">[{{ index + 1 }}]</span>
                <span class="marginRightHistory">{{ items.sessionName }}</span>
                <!-- <div class="marginRightHistory">Word</div>
                  <div class="marginRightHistory">3.2MB</div> -->
              </div>
            </div>
            <div
              class="userNmae marginHis10"
              v-if="
                historyList.answers[historyList.chatActive - 1].answerResource
                  .internetResource.length != 0
              "
            >
              {{ t("base.base235") }}
            </div>
            <div
              v-for="(items, index) in historyList.answers[
                historyList.chatActive - 1
              ].answerResource.internetResource"
              :key="index"
              class="hisItemText"
            >
              <div class="dcflex">
                <span class="marginRightHistory">[{{ index + 1 }}]</span>
                <span class="marginRightHistory">{{ items.internetUrl }}</span>
                <!-- <div class="marginRightHistory">Word</div>
                  <div class="marginRightHistory">3.2MB</div> -->
              </div>
            </div>
          </div>
        </div>
      </transition>
      <!-- 右侧展开 -->
      <!-- 默认关闭展示 -->
      <div
        v-if="!rightType && !historyDialog && !lookDialog"
        @click="rightType = !rightType"
        class="rightIcon"
      >
        <img :src="sqLeftSvg" alt="" class="wid18" />
      </div>
      <!-- 展开div -->
      <transition name="expand-transition">
        <div v-if="rightType" class="rightDivAbs">
          <div class="flexRightCenter">
            <el-button
              class="sc"
              v-if="chatType == 'chat'"
              :loading="deleteLoading"
              @click="deleteSession"
            >
              <img :src="rightGd" alt="" class="marginRight8 rightGd" />
              <div class="gdText">{{ t("base.base236") }}</div>
            </el-button>
            <el-button
              class="sc"
              v-if="chatType == 'base' && topicItem.isCollected == 0"
              @click="likeTalk(0)"
            >
              <img :src="scSvg" alt="" class="marginRight8 wid14" />
              <div>{{ t("base.base100") }}</div>
            </el-button>
            <el-button
              class="color3 sc"
              v-if="chatType == 'base' && topicItem.isCollected == 1"
              @click="likeTalk(1)"
            >
              <img :src="scNoSvg" alt="" class="marginRight8 wid14" />
              <div>{{ t("base.base198") }}</div>
            </el-button>
            <el-button
              class="color2 sc"
              @click="openCap"
              v-if="chatType == 'base'"
              :disabled="getLoading()"
            >
              <img :src="setCap" alt="" class="marginRight8 wid21" />
              <div>{{ catType ? t("base.base237") : t("base.base238") }}</div>
            </el-button>
            <!-- 撤回验证请求 -->
            <!-- <div class="color3 sc" @click="openCap" v-if="chatType == 'base'">
              <img :src="setNoCap" alt="" class="marginRight8 wid21" />
              <div>{{ t('base.base239') }}</div>
            </div> -->
            <el-dropdown
              @command="deleteSession"
              v-if="chatType == 'base' && routeType == 'chat'"
            >
              <div class="iconRightCenter">
                <img :src="moreSvg" alt="" class="wid18" />
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item :command="1">{{
                    t("base.base236")
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <div class="iconRightCenter" @click="rightType = !rightType">
              <img :src="sqRightSvg" alt="" class="wid20" />
            </div>
            <!-- 弹窗验证 -->
            <div v-if="capDialog" class="capDialogClass">
              <div class="capTitle">
                <div>{{ t("base.base238") }}</div>
                <img
                  class="crossSvug"
                  :src="crossSvug"
                  alt=""
                  @click="closeCap"
                />
              </div>
              <div class="labelItem">
                <span class="capLabel">{{ t("base.base240") }}</span>
                <span class="labelTip">{{ t("base.base241") }}</span>
              </div>
              <div>
                <el-select
                  v-model="treeData"
                  multiple
                  :placeholder="t('base.base242')"
                >
                  <el-option
                    v-for="item in chatList"
                    :key="item.conversationId"
                    :label="item.question"
                    :value="item.conversationId"
                  >
                    <el-tooltip
                      popper-class="toolTip"
                      class="box-item"
                      :content="item.question"
                    >
                      <span>{{
                        item.question.length > 35
                          ? item.question.slice(0, 35) + "..."
                          : item.question
                      }}</span>
                    </el-tooltip>
                  </el-option>
                </el-select>
              </div>
              <div class="labelItem">
                <span class="capLabel">{{ t("base.base208") }}</span>
              </div>
              <div>
                <el-select
                  v-model="peopleSel"
                  multiple
                  :placeholder="t('base.base209')"
                >
                  <el-option
                    v-for="item in seleteUserInfo"
                    :key="item.ownerId"
                    :label="item.ownerName"
                    :value="item.ownerId"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="labelItem">{{ t("base.base210") }}</div>
              <el-input
                :placeholder="t('base.base211')"
                show-word-limit
                class="catText"
                type="textarea"
                v-model="leftNotes"
              />
              <div class="capBtnDiv">
                <el-button class="capCancel" @click="closeCap">{{
                  t("base.base45")
                }}</el-button>
                <el-button
                  class="capSave"
                  :loading="requestverifyLoading"
                  @click="saveCap"
                  >{{ t("base.base212") }}</el-button
                >
              </div>
            </div>
          </div>
          <div class="hrefDiv">
            <el-anchor
              :container="containerRef"
              direction="vertical"
              type="underline"
              :offset="30"
              @click="handleClick"
            >
              <el-anchor-link
                :href="'#talk' + item.conversationId"
                :title="item.question"
                v-for="(item, index) in chatList"
                :key="index"
              />
            </el-anchor>
          </div>
        </div>
      </transition>
    </div>
    <!-- 文件预览 -->
    <fileView
      v-if="lookDialog"
      type="chat"
      class="fileViewDiv"
      :fileItem="fileItem"
      :chunkDialog="true"
      :chunkList="chunkList"
      @closeFileView="closeFileView"
    ></fileView>
    <!-- 微模型选择文件 -->
    <baseFileView
      :saveList="saveList"
      :type="false"
      v-if="baseFileDialog"
      @cancel="baseFileDialog = false"
      @ok="saveBase"
    ></baseFileView>
  </div>
</template>

<script setup>
import copySvg from "@/assets/chat/copy.svg";
import editSvg from "@/assets/chat/edit.svg";
import sendSvg from "@/assets/chat/send.svg";
import sendYes from "@/assets/chat/sendYes.svg";
import inputIndex from "./input.vue";
import setVue from "./set.vue";
import tipView from "@/components/tip/index.vue";
import scSvg from "@/assets/chat/sc.svg";
import rightGd from "@/assets/chat/rightGd.svg";
import setCap from "@/assets/chat/setCap.svg";
import qxdz from "@/assets/chat/dzTip.svg";
import setNoCap from "@/assets/chat/setNoCap.svg";
import scNoSvg from "@/assets/chat/noSc.svg";
import moreSvg from "@/assets/chat/more.svg";
import leftFill from "@/assets/chat/leftFill.svg";
import rightFill from "@/assets/chat/rightFill.svg";
import leftNoFill from "@/assets/chat/leftNoFill.svg";
import rightNoFill from "@/assets/chat/rightNoFill.svg";
import sqLeftSvg from "@/assets/chat/sqLeft.svg";
import sqRightSvg from "@/assets/chat/sqRight.svg";
import closeHistory from "@/assets/chat/closeHistory.svg";
import crossSvug from "@/assets/chat/cross.svg";
import qaIndex from "@/components/qa/index.vue";
import fileView from "@/components/fileView/index.vue";
import messageView from "@/components/message/index.vue";
import { encryptApi, decryptApi } from "@/utils/crypto";
import baseFileView from "@/components/chat/baseFile.vue";
import { ref, watch, onMounted, onUpdated, nextTick } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { getRandomColor1, getTime } from "@/utils/utils";
import api from "@/api";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const store = useStore();
const model = ref("GPT-4o"); //gpt值
const modelList = ref(store.state.modelList); //模型列表
const userId = ref(store.state.userId);
const userName = ref(store.state.userName);
const capDialog = ref(false); //验证弹窗状态
const chatType = ref(store.state.chatType);
const treeData = ref([]); //要验证的话题
const peopleSel = ref(); //要验证的人
const lookDialog = ref(false); //预览状态
const fileItem = ref({}); //文件预览item
const historyDialog = ref(false); //历史弹窗
const historyList = ref({}); //历史记录
// 右侧
const containerRef = ref(null); //ref值计算用
const chatList = ref([]); //历史聊天记录内容
const rightType = ref(false); //右侧展示值 如果大于1条就展开右侧 没有就关闭
const catType = ref(false); //是否验证过
const topicItem = ref(store.state.topicItem); //点击详情很重要
const leftDiv = ref(null);
const inputBottomDiv = ref(null);
const qaHeight = ref(null);
const seleteUserInfo = ref([]); //验证人
const leftNotes = ref("");
const requestverifyLoading = ref(false); //loading验证
const deleteLoading = ref(false); //loading归档
const messageList = ref([]);
const inputRef = ref(null);
const baseFileDialog = ref(false);
const saveList = ref([]);
const tipList = ref([
  "base.base243",
  "base.base244",
  "base.base245",
  "base.base246",
]);
const tipActive = ref(null);
const editMessage = ref("");
const chunkList = ref([]);
const routeType = ref("");
const templateCall = ref("");
const idea = ref([]);
onMounted(() => {
  routeType.value = route.query.type ? route.query.type : "chat";
  if (Object.keys(store.state.postObj).length == 0) {
    getTalk();
    getDefauleModel();
  } else {
    model.value = store.state.postObj.model;
    getCall();
  }
  window.onresize = () => {
    getDivHeight();
  };
  getMessage();
  getDivHeight();
});
onUpdated(() => {
  scrollBottom();
});
watch(
  () => inputBottomDiv.value,
  (newValue, oldValue) => {
    getDivHeight();
  }
);
// 监听切换 然后调用接口
watch(
  () => route.query.type,
  (newValue, oldValue) => {
    routeType.value = route.query.type ? route.query.type : "chat";
  }
);
// 监听切换 然后调用接口
watch(
  () => store.state.topicItem,
  (newValue, oldValue) => {
    topicItem.value = newValue;
    lookDialog.value = false;
    if (Object.keys(store.state.postObj).length == 0) {
      getTalk();
    }
  }
);
watch(
  () => store.state.chatType,
  (newValue, oldValue) => {
    chatType.value = newValue;
  }
);
watch(
  () => historyDialog.value,
  (newValue, oldValue) => {
    if (rightType.value) {
      getDivHeight();
    }
  }
);
watch(
  () => lookDialog.value,
  (newValue, oldValue) => {
    setTimeout(() => {
      getDivHeight();
    }, 100);
  }
);
// 当前页面新对话 进行清除
const clearChatList = () => {
  chatList.value = [];
};
// 关闭文字编辑
const closeEdit = (item) => {
  item.editType = false;
};
// 打开文字编辑
const openEdit = (item) => {
  item.editType = true;
  editMessage.value = item.question;
};
// 保存对话
const saveMessage = (item) => {
  inputRef.value.saveMessage(item);
};
// 获取10选3
const getMessage = () => {
  api.chat.randomMessage().then((res) => {
    if (res.returnCode == 200) {
      messageList.value = res.data;
    }
  });
};
// 归档
const deleteSession = () => {
  deleteLoading.value = true;
  api.chat
    .sessionDelete({
      userId: userId.value,
      sessionInfo: [
        {
          sessionId: topicItem.value.sessionId,
          typeId: chatType.value == "chat" ? 1 : 0,
        },
      ],
      operationType: 0,
    })
    .then((res) => {
      deleteLoading.value = false;
      if (res.returnCode == 200) {
        message.success(t("base.base247"));
        store.commit("SET_MANGER", "base.base248");
        store.commit("SET_MENUACTIVE", {
          name: "lookAll",
          key: "/lookAll",
          type: 1,
          iconType: false,
          active: require("@/assets/header/lookAll.svg"),
        });
        router.push({
          name: "lookAll",
          query: {
            type: 1,
          },
        });
      }
    })
    .catch((err) => {
      deleteLoading.value = false;
    });
};
// 话题收藏
const likeTalk = (id) => {
  api.chat
    .baseCollect({
      userId: userId.value,
      sessionId: topicItem.value.sessionId,
      operationType: id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        topicItem.value.isCollected = id == 0 ? 1 : 0;
        message.success(id == 0 ? t("base.base220") : t("base.base15"));
      }
    });
};
// 获取高度
const getDivHeight = () => {
  nextTick(() => {
    qaHeight.value =
      leftDiv.value.offsetHeight - inputBottomDiv.value.offsetHeight;
  });
};
// 加减页数
const changeItemPage = (type) => {
  if (type && historyList.value.chatActive < historyList.value.answers.length) {
    historyList.value.chatActive++;
  }
  if (!type && historyList.value.chatActive > 1) {
    historyList.value.chatActive--;
  }
};
const getLoading = () => {
  return chatList.value.some((item) => item.loading === true);
};
// 发送消息
const sendMessage = (obj) => {
  if (getLoading()) {
    message.warning(t("base.base310"));
    return;
  }
  if (!obj.message) {
    message.warning(t("base.base15"));
    return;
  }
  if (obj.model) {
    model.value = obj.model;
  } else {
    obj.model = model.value;
  }
  if (!model.value) {
    message.warning(t("base.base249"));
    return;
  }
  //加密时间
  let time = Math.round(new Date() / 1000);
  let postObj = {
    time: time,
    idempotentEncryData: encryptApi(JSON.stringify({ ...obj, time: time })),
  };
  let chatObj = {
    question: obj.message,
    chatActive: 1,
    loading: false,
    answers: [
      {
        answerContent: "",
      },
    ],
    model: obj.model,
  };
  chatList.value.push(chatObj);
  let lang = store.state.lang;
  if (lang === "CN") {
    lang = "zh-CN";
  } else if (lang === "HK") {
    lang = "zh-HK";
  } else if (lang === "US") {
    lang = "EN";
  }
  if (chatType.value == "chat") {
    sendFn(postObj, lang, "/openai/chat/v4/memory/chat");
  } else {
    sendFn(postObj, lang, "/openai/chat/v4/knowledge/chat");
  }
};
const sendFn = async (postObj, lang, url) => {
  try {
    chatList.value[chatList.value.length - 1].loading = true;
    let controller = new AbortController();
    const response = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + store.state.token,
        "Accept-Language": lang,
      },
      body: JSON.stringify(postObj),
      signal: controller.signal,
    });
    if (response.status === 500) {
      message.warning(t("base.base182"));
      chatList.value[chatList.value.length - 1].answers[
        chatList.value[chatList.value.length - 1].chatActive - 1
      ].answerContent = t("base.base182");
      chatList.value[chatList.value.length - 1].loading = false;
      store.commit("SET_TALK", {});
      return;
    }
    if (response.status === 504) {
      message.warning(t("base.base183"));
      chatList.value[chatList.value.length - 1].answers[
        chatList.value[chatList.value.length - 1].chatActive - 1
      ].answerContent = t("base.base183");
      chatList.value[chatList.value.length - 1].loading = false;
      store.commit("SET_TALK", {});
      return;
    }
    const reader = response.body.getReader();
    let flag = true;
    let data = "";
    while (flag) {
      const { done, value } = await reader.read();
      const rawData = new TextDecoder().decode(value);
      const regex = /<start>(.*?)<end>/g;
      let matches;
      const extractedData = [];
      while ((matches = regex.exec(rawData)) !== null) {
        extractedData.push(matches[1]);
      }
      const decryptedData = extractedData.map((data) => decryptApi(data));
      data += decryptedData.map((item) => item).join("");
      chatList.value[chatList.value.length - 1].answers[
        chatList.value[chatList.value.length - 1].chatActive - 1
      ].answerContent = data;
      nextTick(() => {
        scrollBottom();
      });
      if (done) {
        chatList.value[chatList.value.length - 1].loading = false;
        store.commit("SET_TALK", {});
        if (
          !data.includes("returnCode") &&
          !data.includes("!(end of answer)")
        ) {
          nextTick(() => {
            getMessage();
            getTalk();
          });
        } else if (data.includes("!(end of answer)")) {
          message.warning(t("base.base184"));
          chatList.value[chatList.value.length - 1].answers[
            chatList.value[chatList.value.length - 1].chatActive - 1
          ].answerContent = t("base.base184");
        } else if (data.includes("returnCode")) {
          let newData = JSON.parse(data);
          message.warning(newData.returnMessage);
          if (newData.returnCode == 423 || newData.returnCode == 412) {
            router.push("/login");
          } else {
            chatList.value[chatList.value.length - 1].answers[
              chatList.value[chatList.value.length - 1].chatActive - 1
            ].answerContent = newData.returnMessage;
          }
        }
        break;
      }
    }
  } catch (err) {
    chatList.value[chatList.value.length - 1].loading = false;
  }
};
// 复制
const copyFn = async (item) => {
  if (!navigator.clipboard) {
    message.warning("浏览器不支持拷贝到剪贴板");
    return;
  }
  try {
    await navigator.clipboard.writeText(item.question);
    message.success(t("base.base216"));
  } catch (err) {
    message.warning(t("base.base217"));
  }
};
const openBaseFile = (arr) => {
  saveList.value = arr;
  baseFileDialog.value = true;
};
// 删除
const deleteBaseFile = (item) => {
  api.chat.baseFileDelete({
    userId: userId.value,
    sessionId: topicItem.value.sessionId,
    fileId: item.id,
    fileType: item.fileType,
  });
  // .then((res) => {
  //   inputRef.value.getBaseFileIcon(topicItem.value.sessionId);
  // });
};
// 保存筛选的文件
const saveBase = (arr) => {
  let fileList = [];
  let folderList = [];
  let attachmentSessionId = null;
  arr.forEach((item) => {
    if (item.fileName && item.fileType != 1 && item.fileType != 2) {
      fileList.push(item.id);
    } else if (item.folderName || item.fileType == 1) {
      folderList.push(item.id);
    } else if (item.folderName || item.fileType == 2) {
      attachmentSessionId = item.id;
    }
  });
  api.chat
    .attachmentAdd({
      userId: userId.value,
      sessionId: topicItem.value.sessionId,
      file_ids: fileList,
      folder_ids: folderList,
      attachmentSessionId: attachmentSessionId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        inputRef.value.getBaseFileIcon(topicItem.value.sessionId);
        baseFileDialog.value = false;
      }
    });
};
// 获取call
const getCall = () => {
  if (topicItem.value == null) return;
  if (chatType.value == "base") {
    let conversationIds = [];
    if (topicItem.value.conversations) {
      conversationIds = topicItem.value.conversations.map((item) => {
        return item.conversationId;
      });
    }
    api.share
      .baseDetailQuery({
        userId: userId.value,
        sessionId: topicItem.value.sessionId,
        conversationIds: conversationIds ? conversationIds : [],
      })
      .then((res) => {
        if (res.returnCode == 200) {
          templateCall.value = res.templateCall;
          idea.value = res.idea;
        }
      });
  } else {
    api.share
      .chatDetailQuery({
        userId: userId.value,
        topicId: topicItem.value.sessionId,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          templateCall.value = res.templateCall;
          idea.value = res.idea;
        }
      });
  }
};
// 获取对话内容
const getTalk = () => {
  if (topicItem.value == null) return;
  if (chatType.value == "base") {
    let conversationIds = [];
    if (topicItem.value.conversations) {
      conversationIds = topicItem.value.conversations.map((item) => {
        return item.conversationId;
      });
    }
    api.share
      .baseDetailQuery({
        userId: userId.value,
        sessionId: topicItem.value.sessionId,
        conversationIds: conversationIds ? conversationIds : [],
      })
      .then((res) => {
        if (res.returnCode == 200) {
          if (res.data.length != 0) {
            res.data.forEach((item) => {
              item.chatActive = 1;
              item.resonType = false;
              item.resonList = [];
              item.tipDialog = false;
              item.editType = false;
              item.loading = false;
            });
          }
          templateCall.value = res.templateCall;
          idea.value = res.idea;
          chatList.value = res.data;
          rightType.value = chatList.value.length > 1 ? true : false;
          nextTick(() => {
            scrollBottom();
          });
        }
      });
  } else {
    api.share
      .chatDetailQuery({
        userId: userId.value,
        topicId: topicItem.value.sessionId,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          res.data.forEach((item) => {
            item.chatActive = 1;
            item.resonType = false;
            item.resonList = [];
            item.tipDialog = false;
            item.editType = false;
            item.loading = false;
          });
          chatList.value = res.data;
          templateCall.value = res.templateCall;
          idea.value = res.idea;
          rightType.value = chatList.value.length > 1 ? true : false;
        }
      });
  }
};
// 获取默认model
const getDefauleModel = () => {
  api.chat.modelGet({ userId: userId.value }).then((res) => {
    if (res.returnCode == 200) {
      model.value = res.data;
    }
  });
};
// 打开历史
const openHistory = (data) => {
  historyList.value = data;
  historyDialog.value = true;
};
const scrollBottom = () => {
  nextTick(() => {
    containerRef.value.scrollTop = containerRef.value.scrollHeight;
  });
};
const handleClick = (e) => {
  e.preventDefault();
};
// 打开请求验证
const openCap = () => {
  peopleSel.value = [];
  leftNotes.value = "";
  treeData.value = [];
  api.base
    .customLibraryDesc({
      userId: userId.value,
      libraryId: topicItem.value.knowledge_library_id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        seleteUserInfo.value = res.data.userInfo.filter(
          (item) => item.type !== 2
        );
        capDialog.value = true;
      }
    });
};
// 关闭验证
const closeCap = () => {
  capDialog.value = false;
};
// 保存验证
const saveCap = () => {
  if (treeData.value.length > 6) {
    message.warning(t("base.base250"));
    return;
  }
  let verifyNums = peopleSel.value
    .map((ownerId) => {
      const item = seleteUserInfo.value.find(
        (item) => item.ownerId === ownerId
      );
      return item ? { ownerId: item.ownerId, ownerType: item.ownerType } : null;
    })
    .filter((item) => item !== null);
  requestverifyLoading.value = true;
  api.share
    .postRequestverify({
      userId: userId.value,
      conversationIds: treeData.value,
      verifyNums: verifyNums,
      sessionName: topicItem.value.sessionName,
      sessionId: topicItem.value.sessionId,
      leftNotes: leftNotes.value,
      onlyQA: false,
    })
    .then((res) => {
      requestverifyLoading.value = false;
      if (res.returnCode == 200) {
        message.success(t("base.base214"));
        // catType.value = true;
        capDialog.value = false;
      }
    });
};
// 打开文件预览
const openFileView = (item, list) => {
  fileItem.value = item;
  chunkList.value = list;
  historyDialog.value = false;
  rightType.value = false;
  lookDialog.value = true;
};
// 关闭文件预览
const closeFileView = () => {
  lookDialog.value = false;
};
</script>

<style lang="less" scoped>
.chatContent {
  width: 100%;
  height: 100%;
  display: flex;
}

.talkDiv {
  overflow: auto;
  padding: 0px 10px;
}

.chatIndex {
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  background-color: #ffffff;
  border-radius: 30px;
  padding: 30px;
  position: relative;
}

.fileIndex {
  width: 467px;
  height: calc(100% - 60px);
  background-color: #ffffff;
  border-radius: 30px;
  padding: 30px;
  position: relative;
  margin-right: 20px;
}

.flexCenterEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 20px;
  right: 0;
}

.marginLeft10 {
  margin-left: 10px;
}

.tipStart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tipDiv {
  margin-bottom: 12px;
}

.width1010 {
  min-width: 1010px;
  width: calc(100% - 510px);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% - 60px);
  transition: all 0.5s ease;
}

.width10101 {
  min-width: 1010px;
  width: calc(100% - 491px);
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translate(0px, -50%);
  height: calc(100% - 60px);
  transition: all 0.5s ease;
}

.width10102 {
  min-width: 632px;
  width: calc(100% - 888px);
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translate(0px, -50%);
  height: calc(100% - 60px);
  transition: all 0.5s ease;
}

.width10103 {
  width: calc(100% - 60px);
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translate(0px, -50%);
  height: calc(100% - 60px);
  transition: all 0.5s ease;
}

.chatSelete {
  width: 125px;
  z-index: 8;
  position: absolute;
  left: 30px;
  top: 30px;
  // box-shadow: 0 0px 20px 18px #ffffff;
  // background-color: #ffffff;

  :deep(.el-select__wrapper) {
    height: 48px;
    color: #3d3d3d;
    font-size: 16px;
    font-family: "Regular";
    border-radius: 16px;
    border: 2px solid #edeef6;
  }

  :deep(.el-select__caret) {
    font-size: 20px;
    color: #8a8a8a;
  }
}

.rightIcon {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
}

.rightIcon:hover {
  background-color: #edeef6;
  border-radius: 12px;
  cursor: pointer;
}

.sc {
  border: 2px solid #edeef6;
  border-radius: 16px;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  margin-right: 12px;
  cursor: pointer;
  height: 48px;
  margin-left: 0px !important;
}

.color2 {
  background-color: #3376ff;
  color: #ffffff;
  border: 2px solid #3376ff;
  position: relative;
}

.color3 {
  color: #f02d63;
}

.marginRight8 {
  margin-right: 8px;
}

.wid14 {
  width: 14px;
}

.wid21 {
  width: 21px;
}

.wid18 {
  width: 18px;
}

.wid20 {
  width: 20px;
}

.wid36 {
  width: 36px;
}

.flexRightCenter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 35px;
  position: relative;
}

.rightDivAbs {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 401px;
  height: calc(100% - 60px);
}

.iconRightCenter {
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconRightCenter:hover {
  background-color: #edeef6;
  border-radius: 12px;
  cursor: pointer;
}

.expand-transition-enter-active,
.expand-transition-leave-active {
  transition: all 0.5s ease;
}

.expand-transition-enter-from,
.expand-transition-leave-to {
  transform: translateX(10%);
  opacity: 0;
}

.expand-transition-enter-to,
.expand-transition-leave-from {
  transform: translateX(0);
  opacity: 1;
}

.hrefDiv {
  padding: 0px 18px;
  max-height: calc(100% - 84px);
  overflow: auto;
}

.historyDialog {
  position: absolute;
  right: 444px;
  top: 30px;
  width: 353px;
  height: calc(100% - 86px);
  background-color: #f7f8ff;
  border-radius: 18px;
  padding: 13px 24px;
}

.hisOne {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 353px;
  height: calc(100% - 86px);
  background-color: #f7f8ff;
  border-radius: 18px;
  padding: 13px 24px;
}

.lineIcon {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lineIcon:hover {
  background-color: #edeef6;
  border-radius: 12px;
  cursor: pointer;
}

.historyCloseIcon {
  width: 14px;
  cursor: pointer;
}

.historyTopDiv {
  font-size: 20px;
  height: 29px;
  color: #3d3d3d;
  font-family: "Medium";
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #d9d9d9;
}

.hisToryItem {
  height: calc(100% - 60px);
  margin-top: 15px;
  overflow: auto;
}

.txImg {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 6px;
  line-height: 18px;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  font-family: "Regular";
}

.userNmae {
  color: #3d3d3d;
  font-family: "Medium";
  font-size: 18px;
}

.historyTime {
  color: #8a8a8a;
  font-family: "Regular";
  font-size: 16px;
}

.hisCenter {
  display: flex;
  align-items: center;
}

.hisBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hisQ {
  font-size: 18px;
  font-family: "Regular";
  color: #3d3d3d;
  margin-top: 5px;
  margin-bottom: 15px;
  position: relative;
}

.hisItemText {
  font-size: 17px;
  color: #3d3d3d;
  font-family: "Regular";
  margin-top: 2px;
}

.marginRightHistory {
  margin-right: 5px;
  margin-bottom: 5px;
}

.marginHis10 {
  margin: 10px 0px;
}

.closeDiv {
  cursor: pointer;
}

.capDialogClass {
  position: absolute;
  right: 100px;
  bottom: -550px;
  width: 566px;
  border-radius: 16px;
  background-color: #f7f8ff;
  z-index: 10;
  padding: 17px;
  cursor: default;
}

.crossSvug {
  width: 24px;
  cursor: pointer;
}

.capTitle {
  font-size: 22px;
  color: #3d3d3d;
  font-family: "Medium";
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  height: 30px;
}

.capLabel {
  position: relative;
  margin-right: 20px;
}

.capLabel::after {
  content: "*";
  position: absolute;
  color: #f02d63;
  right: -15px;
}

.labelItem {
  font-size: 18px;
  font-family: "Medium";
  color: #3d3d3d;
  margin-bottom: 12px;
  margin-top: 24px;
}

.labelTip {
  font-size: 16px;
  font-family: "Medium";
  color: #8a8a8a;
}

.catText {
  margin-bottom: 24px;

  :deep(.el-textarea__inner) {
    height: 110px;
    min-height: 110px;
    max-height: 110px;
    border-radius: 16px;
    border: 2px solid #edeef6;
    padding: 12px 20px;
    resize: none;
    outline: none;
    font-family: "Regular";
    font-size: 18px;
  }
}

.capBtnDiv {
  text-align: right;
}

.capCancel {
  height: 48px;
  color: #f02d63;
  border: 2px solid #edeef6;
  border-radius: 16px;
  font-size: 20px;
  font-family: "Regular";
  padding: 10px 20px;
}

.capSave {
  background-color: #3376ff;
  border: 0px;
  border-radius: 16px;
  height: 48px;
  font-size: 20px;
  font-family: "Regular";
  color: #fefefe;
  padding: 10px 20px;
}

.treeSelTip {
  color: #8a8a8a;
  font-family: "Medium";
  font-size: 17px;
  margin-right: 10px;
}

.treeText {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
}

.fileViewDiv {
  width: calc(100% - 547px);
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
}

.rightGd {
  width: 20px;
}

.gdText {
  color: #f02d63;
}

.messageView {
  margin-bottom: 52px;
}

.fillImg {
  width: 24px;
  cursor: pointer;
}

.fillText {
  font-size: 18px;
  font-family: "Semibold";
  color: #8a8a8a;
}

.fillCenter {
  display: flex;
  align-items: center;
  margin-right: 12px;
  width: 80px;
  position: absolute;
  top: 0;
  left: 0;
}

.historyItemText {
  color: #000;
  font-family: "Regular";
  font-size: 18px;
}

.text92 {
  text-indent: 92px;
}

.fillColor {
  color: #d9d9d9;
}

.dcflex {
  word-wrap: break-word;
}
.tipSetDiv {
  max-height: 159px;
  background-color: #f7f8ff;
  border-radius: 16px;
  padding: 17px;
  margin-bottom: 24px;
}
.qxdz {
  width: 28px;
}
.tipCenter {
  display: flex;
  align-items: center;
}
.tipTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Medium";
  margin-left: 13px;
}
.tipTitleFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.tipNoActive {
  height: 44px;
  border: 2px solid #edeef6;
  border-radius: 16px;
  font-size: 20px;
  color: #8a8a8a;
  font-family: "Regular";
  text-align: center;
  line-height: 44px;
  background-color: #fefefe;
  padding: 0px 20px;
  margin-right: 12px;
  margin-bottom: 12px;
  cursor: pointer;
}
.tipActive {
  height: 44px;
  border: 2px solid #dbdfff;
  border-radius: 16px;
  font-size: 20px;
  color: #0256ff;
  font-family: "Regular";
  text-align: center;
  line-height: 44px;
  background-color: #eceeff;
  padding: 0px 20px;
  margin-right: 12px;
  margin-bottom: 12px;
  cursor: pointer;
}
.tipInput {
  width: 80%;
  :deep(.el-input__wrapper) {
    height: 50px;
    border-radius: 16px;
    border: 2px solid #edeef6;
    box-shadow: 0px 0px 0px 0px;
    font-size: 20px;
    font-family: "Regular";
  }
}
.tipSaveBtn {
  height: 48px;
  border-radius: 16px;
  padding: 0px 20px;
  font-size: 20px;
  color: #fefefe;
  font-family: "Regular";
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sendImg {
  width: 30px;
}
.editInput {
  :deep(.el-input__wrapper) {
    font-size: 22px;
    font-family: "Medium";
    box-shadow: 0px 0px 0px 0px;
    border: 0px;
  }
}
.editDiv {
  height: 33px;
  border-radius: 18px;
  border: 2px solid #d9d9d9;
  padding: 15px 15px 15px 30px;
  display: flex;
  align-items: center;
  margin-left: 23px;
  width: calc(100% - 85px);
}
.editFlex {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
:deep(.el-anchor__link) {
  font-size: 16px;
  font-family: "Regular";
}
:deep(.el-dropdown-menu__item) {
  font-size: 16px;
  font-family: "Regular";
}
.ideaDiv {
  background-color: #ffffff;
  border: 2px solid #edeef6;
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 18px;
  font-family: "Regular";
  color: #000000;
  text-align: center;
  margin-bottom: 10px;
}
:deep(.el-button.is-disabled, .el-button.is-disabled:hover) {
  background-color: none;
  color: none;
}
</style>
