<template>
  <div class="addContent">
    <div class="titleFlex">
      <div class="addTitle">{{ t("statistics.name1") }}</div>
      <img :src="addClose" alt="" class="addClose" @click="closeDialog" />
    </div>
    <div class="tipText">
      {{ t("statistics.name2") }}
    </div>
    <div class="btnDiv">
      <el-button class="close" @click="closeDialog">{{
        t("statistics.name3")
      }}</el-button>
      <el-button class="save" @click="saveDialog">{{
        t("statistics.name4")
      }}</el-button>
    </div>
  </div>
  <div class="mask"></div>
</template>

<script setup>
import addClose from "@/assets/base/addClose.svg";
import { useI18n } from "vue-i18n";
const {t} = useI18n()
const emit = defineEmits(["saveDialog", "closeDialog"]);
const saveDialog = () => {
  emit("saveDialog");
};
const closeDialog = () => {
  emit("closeDialog");
};
</script>

<style lang="scss" scoped>
.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 120;
  opacity: 0.2;
}

.addContent {
  position: absolute;
  width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  z-index: 121;
  padding: 30px;
  border-radius: 30px;
  box-shadow: 0px 0px 8px 0px #dbdfff;
}
.addClose {
  width: 14px;
  cursor: pointer;
}
.addTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Medium";
}
.titleFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  margin-bottom: 20px;
}
.tipText {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Medium";
}
.save {
  font-size: 20px;
  font-family: "Regular";
  color: #fefefe;
  border: 2px solid #3376ff;
  background-color: #3376ff;
  border-radius: 16px;
  height: 48px;
  padding: 10px 20px;
  margin-left: 12px;
}
.close {
  font-size: 20px;
  font-family: "Regular";
  color: #f02d63;
  border: 2px solid #edeef6;
  border-radius: 16px;
  height: 48px;
  padding: 10px 20px;
}
.btnDiv {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
</style>
