<template>
  <div>
    <!-- q -->
    <div class="qDiv">
      <div :class="props.type == 'chat' ? 'itemQuestionChat' : 'itemQuestion'">
        {{ props.item.question }}
      </div>
      <slot name="question"></slot>
    </div>
    <!-- 左侧 -->
    <div
      v-if="
        !props.item.resonType &&
        props.item.answers[props.item.chatActive - 1].answerContent
      "
      class="resonDiv"
      @click="getReson"
    >
      <img :src="searchSvg" alt="" class="wid19" />
      <div class="resonText">{{ t("base.base276") }}</div>
    </div>
    <div class="resonFlex" v-if="props.item.resonType">
      <!-- 来源 -->
      <div class="reson" v-if="!resonDialog" @click="openReson">
        <img :src="searchSvg" alt="" class="wid19" />
        <div class="resonText">
          {{ t("base.base277") }}{{ getNumber }}{{ t("base.base278") }}
        </div>
        <!-- <div class="resonImg">
          <img
            :src="getChatFileImg(items)"
            alt=""
            :style="'left:' + index * -10 + '%' + ';z-index:' + index"
            v-for="(items, index) in item.resonList"
            :key="items.fileId"
            class="wlImg"
          />
        </div> -->
      </div>
      <div
        v-if="resonDialog && (getFileNumber != 0 || getNetNumber != 0)"
        class="resonDialog"
        @click="closeReson"
      >
        <div class="reSonUpFile bottom12" v-if="getFileNumber != 0">
          <div class="reSonCenter">
            <img class="reSonIcon" :src="baseReson" alt="" />
            <div class="reSonTitle">
              {{ t("base.base277") }}{{ getFileNumber }}{{ t("base.base280") }}
            </div>
          </div>
          <img
            class="reSonUpFileImg"
            :src="upReson"
            alt=""
            v-if="resonOneType"
            @click="resonOneType = !resonOneType"
          />
          <img
            class="reSonUpFileImg"
            :src="downReson"
            alt=""
            v-else
            @click="resonOneType = !resonOneType"
          />
        </div>
        <!-- 文件内容 -->
        <el-collapse-transition>
          <div class="pa" v-show="resonOneType">
            <img
              :src="leftReson"
              alt=""
              class="leftResonIcon"
              v-if="
                props.item.resonList.answers[props.item.chatActive - 1]
                  .answerResource.fileResource.length > 3
              "
              @click="changeLeftReson(resonOne)"
            />
            <div class="flexItemReson" ref="resonOne">
              <fileReSon
                v-for="items in props.item.resonList.answers[
                  props.item.chatActive - 1
                ].answerResource.fileResource"
                :key="items.fileId"
                :fileItem="items"
                class="resonMargin"
                @click="openFileView(items)"
              ></fileReSon>
              <talkReSon
                class="resonMargin"
                v-for="items in props.item.resonList.answers[
                  props.item.chatActive - 1
                ].answerResource.sessionResource"
                :key="items.sessionId"
                :talkItem="items"
              ></talkReSon>
            </div>
            <img
              :src="rightReson"
              alt=""
              class="rightResonIcon"
              v-if="
                props.item.resonList.answers[props.item.chatActive - 1]
                  .answerResource.fileResource.length > 3
              "
              @click="changeRightReson(resonOne)"
            />
          </div>
        </el-collapse-transition>

        <!-- 网页 -->
        <div class="reSonUpFile" v-if="getNetNumber != 0">
          <div class="reSonCenter">
            <img class="reSonIcon" :src="netReson" alt="" />
            <div class="reSonTitle">
              {{ t("base.base277") }}{{ getNetNumber }}{{ t("base.base279") }}
            </div>
          </div>
          <img
            class="reSonUpFileImg"
            :src="upReson"
            alt=""
            v-if="resonTwoType"
            @click="resonTwoType = !resonTwoType"
          />
          <img
            class="reSonUpFileImg"
            :src="downReson"
            alt=""
            v-else
            @click="resonTwoType = !resonTwoType"
          />
        </div>
        <el-collapse-transition>
          <div class="pa" v-show="resonTwoType">
            <img
              :src="leftReson"
              alt=""
              class="leftResonIcon"
              v-if="
                props.item.resonList.answers[props.item.chatActive - 1]
                  .answerResource.internetResource.length > 3
              "
              @click="changeLeftReson(resonTwo)"
            />
            <div class="flexItemReson" ref="resonTwo">
              <htmlReSon
                v-for="items in props.item.resonList.answers[
                  props.item.chatActive - 1
                ].answerResource.internetResource"
                :key="items"
                :htmlItem="items"
                class="resonMargin"
              ></htmlReSon>
            </div>
            <img
              :src="rightReson"
              alt=""
              class="rightResonIcon"
              v-if="
                props.item.resonList.answers[props.item.chatActive - 1]
                  .answerResource.internetResource.length > 3
              "
              @click="changeRightReson(resonTwo)"
            />
          </div>
        </el-collapse-transition>
      </div>
    </div>
    <!-- 回答 -->
    <messageView
      v-if="props.item.answers[props.item.chatActive - 1].answerContent"
      :message="props.item.answers[props.item.chatActive - 1].answerContent"
      class="messageView"
    ></messageView>
    <span class="loader messageView" v-else></span>
  </div>
</template>

<script setup>
import searchSvg from "@/assets/chat/search.svg";
import baseReson from "@/assets/chat/baseReson.svg";
import upReson from "@/assets/chat/upReson.svg";
import downReson from "@/assets/chat/downReson.svg";
import rightReson from "@/assets/chat/rightReson.svg";
import leftReson from "@/assets/chat/leftReson.svg";
import netReson from "@/assets/chat/netReson.svg";
import fileReSon from "@/components/reson/file.vue";
import htmlReSon from "@/components/reson/html.vue";
import talkReSon from "@/components/reson/talk.vue";
import messageView from "@/components/message/index.vue";
import { ref, watch, computed ,onMounted} from "vue";
import { getChatFileImg } from "@/utils/file";
import { useStore } from "vuex";
import api from "@/api";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useStore();
const props = defineProps(["item", "type"]);
const emit = defineEmits(["openFileView"]);
const userId = ref(store.state.userId);
const topicItem = ref(store.state.topicItem);
const chatType = ref(store.state.chatType);
const resonOne = ref(null);
const resonTwo = ref(null);
const resonOneType = ref(true);
const resonTwoType = ref(true);
const resonDialog = ref(false); //来源展示框
// 监听切换 然后调用接口
watch(
  () => store.state.topicItem,
  (newValue, oldValue) => {
    topicItem.value = newValue;
  }
);
watch(
  () => store.state.chatType,
  (newValue, oldValue) => {
    chatType.value = newValue;
  }
);
const getNumber = computed(() => {
  let number =
    props.item.resonList.answers[props.item.chatActive - 1].answerResource
      .fileResource.length +
    props.item.resonList.answers[props.item.chatActive - 1].answerResource
      .internetResource.length +
    props.item.resonList.answers[props.item.chatActive - 1].answerResource
      .sessionResource.length;
  return number;
});
const getNetNumber = computed(() => {
  let number =
    props.item.resonList.answers[props.item.chatActive - 1].answerResource
      .internetResource.length;
  return number;
});

const getFileNumber = computed(() => {
  let number =
    props.item.resonList.answers[props.item.chatActive - 1].answerResource
      .fileResource.length +
    props.item.resonList.answers[props.item.chatActive - 1].answerResource
      .sessionResource.length;
  return number;
});
// 获取来源数据
const getReson = () => {
  if (chatType.value == "chat") {
    api.base
      .chatHistory({
        userId: userId.value,
        conversationId: props.item.conversationId,
        topicId: topicItem.value.topicId,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          props.item.resonList = res.data;
          props.item.resonType = true;
          if (resonDialog.value = true) {
            resonDialog.value = false
          }
        }
      });
  } else {
    api.base
      .baseHistory({
        userId: userId.value,
        conversationId: props.item.conversationId,
        libraryId:
          topicItem.value.knowledge_library_id || topicItem.value.libraryId,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          props.item.resonList = res.data;
          props.item.resonType = true;
          if (resonDialog.value = true) {
            resonDialog.value = false
          }
        }
      });
  }
};
// 打开来源
const openReson = () => {
  if (getNumber.value == 0) return;
  resonDialog.value = true;
};
// 关闭来源
const closeReson = () => {
  // resonDialog.value = false;
};
// 想左移动
const changeLeftReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 4);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft - itemWidth;
};
// 向右移动
const changeRightReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 4);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft + itemWidth;
};
// 打开文件预览
const openFileView = (item) => {
  emit(
    "openFileView",
    item,
    props.item.resonList.answers[props.item.chatActive - 1].answerResource
      .chunkResource
  );
};
</script>

<style lang="scss" scoped>
.resonFlex {
  display: flex;
  cursor: pointer;
  margin-bottom: 24px;
}
.reson {
  display: flex;
  border-radius: 30px;
  background-color: #f7f8ff;
  padding: 12px 15px;
  align-items: center;
  position: relative;
}
.resonText {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Semibold";
  margin-left: 10px;
  margin-right: 25px;
}
.resonImg {
  display: flex;
  align-items: center;
  position: relative;
}
.wlImg {
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 50%;
  width: 30px;
}
.resonDialog {
  width: calc(100% - 30px);
  max-height: 302px;
  background-color: #f7f8ff;
  border-radius: 30px;
  padding: 17px 15px;
  position: relative;
}
.reSonUpFile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottom12 {
  margin-bottom: 12px;
}
.reSonCenter {
  display: flex;
  align-items: center;
}
.reSonIcon {
  width: 25px;
  margin-right: 10px;
}
.reSonTitle {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Semibold";
}
.reSonUpFileImg {
  width: 24px;
}
.pa {
  position: relative;
  margin-left: 32px;
  .rightResonIcon {
    display: none;
  }
  .leftResonIcon {
    display: none;
  }
}
.pa:hover {
  .rightResonIcon {
    display: block;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0px, -50%);
    width: 30px;
    z-index: 10;
  }
  .leftResonIcon {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0px, -50%);
    width: 30px;
    z-index: 10;
  }
}
.flexItemReson {
  display: flex;
  margin: 0px 0px 12px 0px;
  align-content: flex-start;
  justify-content: flex-start;
  overflow: auto; /* 使div可滚动 */
  scrollbar-width: none; /* 对于现代浏览器，隐藏滚动条 */
  -ms-overflow-style: none;
  position: relative;
}
.resonMargin {
  margin-right: 12px;
}
.itemQuestion {
  display: flex;
  background-color: #f7f8ff;
  border-radius: 16px;
  padding: 12px 26px;
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
  margin-bottom: 52px;
}
.itemQuestionChat {
  display: flex;
  background-color: #f7f8ff;
  border-radius: 16px;
  padding: 12px 26px;
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
  margin-bottom: 80px;
}
.qDiv {
  display: flex;
  justify-content: flex-end;
  margin-left: 110px;
  position: relative;
}
.messageView {
  margin-bottom: 52px;
}
.loader {
  width: 28px;
  height: 28px;
  border: 4px solid #f6f6f6;
  border-bottom-color: #0256ff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.resonDiv {
  display: flex;
  background-color: #f7f8ff;
  border-radius: 30px;
  height: 48px;
  padding: 0px 15px;
  align-items: center;
  margin-bottom: 24px;
  max-width: 235px;
  cursor: pointer;
}
.wid19 {
  width: 19px;
}
</style>
