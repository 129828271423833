<script setup>
import { ref, defineProps, defaultEmits, onMounted, watch } from "vue";
import { useStore } from "vuex";
import api from "@/api/index";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useStore();
const userId = ref(store.state.userId);
const props = defineProps({
  show: Boolean,
  groupIdsValue: Array,
  roleId: Number,
});
const show = ref(props.show);
watch(
  () => props.show,
  (newVal) => {
    show.value = newVal;
  }
);
onMounted(() => {
  value.value = props.groupIdsValue;
  getTableList();
});
const emit = defineEmits(["closeUser", "saveUser"]);
const listData = ref({
  userId: userId.value,
  search: "",
  ruleId: null,
});
watch(
  () => listData.value.search,
  (newVal) => {
    listData.value.search = newVal;
    getTableList();
  }
);

const value = ref();
const groupList = ref([]);
const getTableList = () => {
  listData.value.ruleId = props.roleId;
  api.manage.getGroupListApi(listData.value).then((res) => {
    if (res.returnCode == 200) {
      groupList.value = res.data;
    }
  });
};
const newArr = ref([]);
const changeBtn = (data, check) => {
  if (check) {
    newArr;
  } else {
  }
};
const defaultProps = ref({
  children: "children",
  label: "groupName",
  id: "groupId",
  disabled:'rule_occupy'
});
const handleClose = () => {
  show.value = !show.value;
  emit("closeUser");
  value.value = "";
};
const handleSave = () => {
  show.value = !show.value;
  emit("saveUser", value.value);
  value.value = "";
};
</script>
<template>
  <div>
    <el-dialog
      :z-index="100"
      destroy-on-close
      v-model="show"
      align-center
      :close-on-click-modal="false"
      :show-close="false"
    >
      <template #header="">
        <div class="my-header">
          <div>{{ t("manage.userForm.user98") }}</div>
          <el-icon
            class="el-icon--left"
            :class="'close-icon'"
            @click="handleClose"
            style="cursor: pointer"
          >
            <Close />
          </el-icon>
        </div>
      </template>
      <div>
        <div class="container">
          <!-- 用户 -->
          <!-- <el-input v-model="listData.search" size="large" class="searchInput"
                        :placeholder="$t('manage.groupForm.SearchGroup')">
                        <template #prefix>
                            <el-icon class="el-input__icon">
                                <search />
                            </el-icon>
                        </template>
                    </el-input> -->
        </div>
      </div>
      <el-tree-select
        multiple
        :render-after-expand="false"
        show-checkbox
        class="select"
        clearable
        v-model="value"
        :data="groupList"
        check-on-click-node
        :props="defaultProps"
        node-key="groupId"
      >
        <template #default="{ data: { groupName } }">
          <span style="float: left">{{ groupName }}</span>
        </template>
      </el-tree-select>
      <template #footer>
        <div class="dialog-footer">
          <div @click="handleClose" class="cancel">
            {{ t("manage.userForm.user35") }}
          </div>
          <div @click="handleSave" class="confirm">
            {{ t("manage.userForm.user36") }}
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.select {
  :deep(.el-scrollbar) {
    padding: 0 10px;
  }
}

:deep(.el-dialog) {
  --el-dialog-width: 500px;
  border-radius: 30px;
  padding: 30px;
}

.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Medium";
}

.dialog-footer {
  display: flex;
  justify-content: end;
  font-size: 15px;

  .cancel {
    color: #f02d63;
    padding: 8px 20px;
    border-radius: 16px;
    border: 1px solid #edeef6;
    cursor: pointer;
  }

  .confirm {
    color: #fff;
    background-color: #3376ff;
    margin-left: 10px;
    padding: 8px 20px;
    border-radius: 16px;
    cursor: pointer;
  }
}

.container {
  position: relative;

  .side {
    max-width: 200px;
    height: 460px;
    overflow: auto;
    border-radius: 16px;
    background-color: #f7f8ff;
    padding: 0px 2px;
    position: relative;
    margin-right: 20px;
  }
}

.tableHeight {
  height: 510px;
}
</style>
