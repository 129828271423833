<script setup>
import { ref, defineProps, watch, defineEmits } from "vue";
import { useI18n } from "vue-i18n"
const { t } = useI18n()
const emit = defineEmits(['closeResetInfo']);
const props = defineProps({
    newReset: Boolean,
    resetArr: Array,
});
const show = ref(props.newReset);
watch(
    () => props.showReset,
    (newVal) => {
        show.value = newVal
    }
)
// 取消
const handleCancel = () => {
    show.value = !show.value
    emit('closeResetInfo')
};


</script>

<template>
    <div>
        <!-- 重置密码 -->
        <el-dialog destroy-on-close :z-index="100" v-model="show" align-center :close-on-click-modal="false" :show-close="false">
            <template #header="">
                <div class="my-header">
                    <div>{{ t('manage.userForm.user68') }}</div>
                    <el-icon class="el-icon--left" :class="'close-icon'" @click="handleCancel" style="cursor: pointer;">
                        <Close />
                    </el-icon>
                </div>
            </template>
            <div class="content">
                <el-divider />
                <div><span style="color: #f02d63;">!</span>{{ t('manage.userForm.user69') }}</div>
                <el-table :data="props.resetArr" style="height: 100px;">
                    <el-table-column prop="userName" :label="$t('manage.userForm.userName')" />
                    <el-table-column prop="newPassword" :label="$t('manage.userForm.user66')" />
                </el-table>
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <div @click="handleCancel" class="confirm">{{ t('manage.userForm.user38') }}</div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>



<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:deep(.el-dialog) {
    --el-dialog-width: 600px;
    border-radius: 30px;
    // height: 800px;
    padding: 30px;
}

:deep(.el-divider) {
    margin: 1px 0 10px !important;
    width: calc(100%) !important;
}

:deep(.el-input__wrapper) {
    height: 40px !important;
    width: 300px !important;
}

.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
    color: #3d3d3d;
    font-family: "Medium";
}

.close-icon {
    font-size: 20px;
}

.passWord {
    color: #F02D63;
    font-size: 12px;
}

.form {
    margin-top: 10px;
}

.tit {
    font-size: 16px;
    margin-bottom: 2px;
    font-weight: 600;
    margin-right: 10px;
    color: #3d3d3d;
}

.comtent {
    font-size: 16px;
    margin-bottom: 8px;
}

.dialog-footer {
    display: flex;
    justify-content: end;
    font-size: 15px;

    .cancel {
        color: #F02D63;
        padding: 8px 20px;
        border-radius: 16px;
        border: 1px solid #EDEEF6;
        cursor: pointer;
    }

    .confirm {
        color: #fff;
        background-color: #3376FF;
        margin-left: 10px;
        padding: 8px 20px;
        border-radius: 16px;
        cursor: pointer;
    }
}
</style>