<template>
  <div class="topRightDiv">
    <div class="rightDivItem">
      <el-row class="topRow">
        <el-col :span="12" class="topLeftCol">
          <div class="topLeftTitle">{{ t("menu.index7") }}</div>
          <div class="chatTip">
            {{ t("menu.index8") }}
            <el-tooltip
              popper-class="toolTip"
              class="box-item"
              :content="t('login.login32')"
            >
              <el-icon class="warningClass"><Warning /></el-icon>
            </el-tooltip>
            ：
          </div>
          <el-select class="topSelect" v-model="model" @change="getToken">
            <el-option
              v-for="item in modelList"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            />
          </el-select>
        </el-col>
        <el-col :span="12" class="echartsItem">
          <el-progress
            type="circle"
            :percentage="allTokenSum"
            :color="getColor"
            :stroke-width="10"
          >
            <template #default>
              <div class="tipLeft" :style="'color:' + getColor(allTokenSum)">
                {{ t("menu.index9") }}
              </div>
              <div class="tipLeft1" :style="'color:' + getColor(allTokenSum)">
                {{ tokenSum }}
              </div>
              <div class="tipLeft" :style="'color:' + getColor(allTokenSum)">
                {{ t("menu.index10") }}
              </div>
            </template>
          </el-progress>
          <el-popover placement="bottom" popper-class="formDIv">
            <template #reference>
              <div class="formDiv">
                <span>{{ t("menu.index11") }}</span>
                <img :src="eImg" alt="" class="resonImg" />
                <img :src="bImg" alt="" class="resonImgActive" />
              </div>
            </template>
            <el-table :data="tokenData">
              <el-table-column :label="t('menu.index11')">
                <template #default="scope">
                  <div class="tableCenter">
                    <!-- <div
                      v-if="scope.row.name"
                      class="messageTx"
                      :style="'background-color:' + getRandomColor1()"
                    >
                      {{ scope.row.name.slice(0, 1) }}
                    </div> -->
                    <div class="tableText">{{ scope.row.name }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column property="number" :label="t('menu.index15')">
                <template #default="scope">
                  <span class="tableText"
                    >{{ t("menu.index12") }}{{ scope.row.number
                    }}{{ t("menu.index10") }}</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-popover>
        </el-col>
      </el-row>
    </div>
    <div class="rightDivItem">
      <!-- 左侧 -->
      <div class="echartsItem">
        <el-progress
          type="circle"
          :percentage="allImgSum"
          :color="getColor"
          :stroke-width="10"
        >
          <template #default>
            <div class="tipLeft" :style="'color:' + getColor(allImgSum)">
              {{ t("menu.index13") }}
            </div>
            <div class="tipLeft1" :style="'color:' + getColor(allImgSum)">
              {{ imgSum }}
            </div>
            <div class="tipLeft" :style="'color:' + getColor(allImgSum)">
              {{ t("menu.index14") }}
            </div>
          </template>
        </el-progress>
        <el-popover placement="top" popper-class="formDIv">
          <template #reference>
            <div class="formDiv">
              <span>{{ t("menu.index11") }}</span>
              <img :src="eImg" alt="" class="resonImg" />
              <img :src="bImg" alt="" class="resonImgActive" />
            </div>
          </template>
          <el-table :data="imgData">
            <el-table-column :label="t('menu.index11')">
              <template #default="scope">
                <div class="tableCenter">
                  <!-- <div
                    class="messageTx"
                    v-if="scope.row.name"
                    :style="'background-color:' + getRandomColor1()"
                  >
                    {{ scope.row.name.slice(0, 1) }}
                  </div> -->
                  <div class="tableText">{{ scope.row.name }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column property="number" :label="t('menu.index15')">
              <template #default="scope">
                <span class="tableText"
                  >{{ t("menu.index12") }}{{ scope.row.number
                  }}{{ t("menu.index18") }}</span
                >
              </template>
            </el-table-column>
          </el-table>
        </el-popover>
      </div>
      <!-- 右侧 -->
      <div class="echartsItem">
        <el-progress
          type="circle"
          :color="getColor"
          :percentage="allFileSum"
          :stroke-width="10"
        >
          <template #default>
            <div class="tipLeft" :style="'color:' + getColor(allFileSum)">
              {{ t("menu.index16") }}
            </div>
            <div class="tipLeft1" :style="'color:' + getColor(allFileSum)">
              {{ fileSum }}
            </div>
            <div class="tipLeft" :style="'color:' + getColor(allFileSum)">
              {{ t("menu.index17") }}
            </div>
          </template>
        </el-progress>
        <el-popover placement="top" popper-class="formDIv">
          <template #reference>
            <div class="formDiv">
              <span>{{ t("menu.index11") }}</span>
              <img :src="eImg" alt="" class="resonImg" />
              <img :src="bImg" alt="" class="resonImgActive" />
            </div>
          </template>
          <el-table :data="fileData">
            <el-table-column :label="t('menu.index11')">
              <template #default="scope">
                <div class="tableCenter">
                  <!-- <div
                    class="messageTx"
                    v-if="scope.row.name"
                    :style="'background-color:' + getRandomColor1()"
                  >
                    {{ scope.row.name.slice(0, 1) }}
                  </div> -->
                  <div class="tableText">
                    {{ scope.row.name ? scope.row.name : "-" }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column property="number" :label="t('menu.index15')">
              <template #default="scope">
                <span class="tableText"
                  >{{ t("menu.index12") }}{{ scope.row.number
                  }}{{ t("menu.index17") }}</span
                >
              </template>
            </el-table-column>
          </el-table>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script setup>
import eImg from "@/assets/index/e.svg";
import bImg from "@/assets/index/b.svg";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import api from "@/api";
import { getRandomColor1 } from "@/utils/utils";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useStore();
const model = ref("GPT-4o");
const modelList = ref(store.state.modelList); //模型列表
const userId = ref(store.state.userId);
const imgSum = ref(0); // 图片
const fileSum = ref(0); //文件
const tokenSum = ref(0); //token
const tokenData = ref([]); //悬浮来源数据
const fileData = ref([]); //悬浮来源数据
const imgData = ref([]); //悬浮来源数据
const allImgSum = ref(null);
const allFileSum = ref(null);
const allTokenSum = ref(null);
onMounted(() => {
  getDefauleModel();
});
// 动态获取color
const getColor = (number) => {
  if (number > 80) {
    return "#14AE5C";
  } else if (number > 20) {
    return "#FFC300";
  } else {
    return "#8A8A8A";
  }
};
// 获取token
const getToken = () => {
  api.home
    .getIndexTokens({ userId: userId.value, model: model.value })
    .then((res) => {
      if (res.returnCode == 200) {
        // 展示数
        tokenSum.value = res.data.token_used;
        imgSum.value = res.data.used_image_sum;
        fileSum.value = res.data.file_knownledge_used;
        // 来源
        imgData.value = res.data.imageSum;
        fileData.value = res.data.fileKnowledgeSum;
        tokenData.value = res.data.tokenSum;
        // 环形展示
        allImgSum.value =
          (imgSum.value / imgData.value[0].number) * 100 > 100
            ? 0
            : 100 - (imgSum.value / imgData.value[0].number) * 100;
        allFileSum.value =
          (fileSum.value / fileData.value[0].number) * 100 > 100
            ? 0
            : 100 - (fileSum.value / fileData.value[0].number) * 100;
        allTokenSum.value =
          (tokenSum.value / tokenData.value[0].number) * 100 > 100
            ? 0
            : 100 - (tokenSum.value / tokenData.value[0].number) * 100;
        console.log(allImgSum.value);
        console.log(allFileSum.value);
        console.log(allTokenSum.value);
        if (tokenSum.value > tokenData.value[0].number) {
          tokenSum.value = tokenData.value[0].number - tokenSum.value;
        }
      }
    });
};
// 获取默认model
const getDefauleModel = () => {
  api.chat.modelGet({ userId: userId.value }).then((res) => {
    if (res.returnCode == 200) {
      model.value = res.data;
      getToken();
    }
  });
};
</script>

<style lang="less" scoped>
.topRightDiv {
  min-width: 475px;
  width: 30%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.rightDivItem {
  width: calc(100% - 40px);
  background-color: #fefefe;
  height: calc(50% - 45px);
  border-radius: 30px;
  padding: 20px;
  display: flex;
  box-shadow: 0px 0px 16px 0 #dbdfff82;
}
.topRow {
  width: 100%;
}
.topSelect {
  width: 155px;
}
.topLeftCol {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.echartsItem {
  width: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tipLeft {
  font-size: 16px;
  font-family: "Regular";
  margin-bottom: 5px;
}
.tipLeft1 {
  font-size: 28px;
  font-family: "Bold";
  margin-bottom: 5px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.formDiv {
  position: absolute;
  right: -20px;
  top: 0px;
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  padding: 2px 8px;
  cursor: pointer;
  .resonImg {
    display: inline-block;
    margin-left: 5px;
    width: 11px;
  }
  .resonImgActive {
    display: none;
    margin-left: 5px;
    width: 11px;
  }
}
.formDiv:hover {
  border-radius: 12px;
  background-color: #dbdfff;
  color: #0256ff;
  .resonImg {
    display: none;
    margin-left: 5px;
    width: 11px;
  }
  .resonImgActive {
    display: inline-block;
    margin-left: 5px;
    width: 11px;
  }
}
.topLeftTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Bold";
}
:deep(.el-select__wrapper) {
  height: 46px;
  color: #3d3d3d;
  font-size: 18px;
  font-family: "Regular";
  border-radius: 16px;
  border: 2px solid #edeef6;
}
:deep(.el-select__caret) {
  font-size: 20px;
  color: #8a8a8a;
}
.chatTip {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  display: flex;
  align-items: center;
}
:deep(.el-progress-circle) {
  width: 140px !important;
  height: 140px !important;
}
.messageTx {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  margin-right: 6px;
  text-align: center;
  line-height: 31px;
  color: #ffffff;
  font-size: 18px;
  font-family: "Semibold";
}
.tableText {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
}
.tableCenter {
  display: flex;
  align-items: center;
}
.warningClass {
  margin-left: 5px;
}
</style>
