<template>
  <div class="shareContent">
    <!-- 左侧 -->
    <div
      v-if="!lookDialog"
      :class="conflictType ? 'leftBigDivFlex leftBigDiv' : 'leftBigDiv'"
    >
      <div :class="conflictType ? 'leftConflict' : 'left'">
        <div class="leftqaTitles">
          <div class="leftqaBtn">{{ t("base.base136") }}</div>
          <div class="exportImgDiv" v-if="conflictType" @click="changeYct">
            <img class="exportImg" :src="exportImg" alt="" />
          </div>
        </div>
        <div class="shareLeftDiv">
          <qaIndex
            v-if="leftList.conversationId"
            type="share"
            :item="leftList"
            class="qaIndex"
            @openFileView="openLook"
          ></qaIndex>
          <div
            class="fillCenter"
            v-if="leftList.answers && leftList.answers.length > 1"
          >
            <img
              :src="leftList.chatActive != 1 ? leftFill : leftNoFill"
              alt=""
              class="fillImg"
              @click="changeItemPage(false)"
            />
            <span
              :class="leftList.chatActive == 1 ? 'fillColor' : ''"
              class="fillText"
              >{{ leftList.chatActive }}</span
            >
            <span
              class="fillText"
              :class="
                leftList.chatActive == leftList.answers.length
                  ? 'fillColor'
                  : ''
              "
              >/{{ leftList.answers.length }}</span
            >
            <img
              :src="
                leftList.chatActive == leftList.answers.length
                  ? rightNoFill
                  : rightFill
              "
              alt=""
              class="fillImg"
              @click="changeItemPage(true)"
            />
          </div>
          <!--     :class="{
              qaIndexOp: index != 0,
            }" -->
        </div>
        <!-- 锚点列表 -->
        <el-popover
          placement="top"
          trigger="click"
          popper-class="fileImgPopover"
          v-if="topicList.length > 1"
        >
          <template #reference>
            <div class="anchorImgDiv">
              <img class="anchorImg" :src="anchorImg" alt="" />
            </div>
          </template>
          <div class="anchorHover">
            <div
              v-for="(item, index) in topicList"
              :key="item"
              @click="clickAnchor(item, index)"
              :class="leftNumber == index ? 'hrefAc' : 'hrefNoac'"
            >
              <el-tooltip
                popper-class="toolTip"
                :content="item.oldData.question"
              >
                <span>{{
                  item.oldData.question.length > 13
                    ? item.oldData.question.slice(0, 13) + "..."
                    : item.oldData.question
                }}</span>
              </el-tooltip>
            </div>
          </div>
        </el-popover>
      </div>
      <div v-if="conflictType" class="leftConflict">
        <div class="leftqaTitles">
          <div class="leftqaBtn">{{ t("base.base137") }}</div>
          <div class="exportImgDiv" @click="changeCt">
            <img class="exportImg" :src="exportImg" alt="" />
          </div>
        </div>
        <div class="shareLeftDiv" v-if="!lookDialog">
          <detailsQa
            :item="publishData"
            class="qaIndex"
            @openFileView="openLook"
          ></detailsQa>
        </div>
      </div>
    </div>
    <div :class="lookDialog ? 'shareRightDivLook' : 'shareRightDiv'">
      <!-- 右侧 -->
      <div>
        <!-- 第一步 -->
        <div class="step1Div">
          <div class="flexBetween stepMargin">
            <div class="flexCenter">
              <div class="stepBtn">{{ t("base.base138") }}</div>
              <div class="stepTitle">{{ t("base.base139") }}</div>
            </div>
          </div>
          <!-- 第一步tip -->
          <div class="step1Tip">
            {{ t("base.base140") }}
          </div>
        </div>
        <!-- 微模型 -->
        <div class="flexCenter">
          <img class="baseImg" :src="baseImg" alt="" />
          <div class="fileTitle">{{ t("base.base141") }}</div>
          <div class="chDiv" @click="goUp(1)">
            <img class="chImg" :src="chImg" alt="" />
          </div>
        </div>
        <!-- 文件列表 -->
        <div class="pa" v-if="fileList.length != 0">
          <img
            :src="leftReson"
            alt=""
            class="leftResonIcon"
            v-if="fileList.length > 2"
            @click="changeLeftReson(fileDiv)"
          />
          <div class="fileListDiv" ref="fileDiv">
            <fileIndex
              @openLook="openLook"
              class="resonMargin"
              v-for="(item, index) in fileList"
              :key="item.id"
              :item="item"
              :deleType="true"
              @deleteFile="deleteFile(index)"
            ></fileIndex>
            <talkIndex
              :talkItem="item"
              class="resonMargin"
              v-for="item in sessionList"
              :key="item.sessionId"
            ></talkIndex>
          </div>
          <img
            :src="rightReson"
            alt=""
            class="rightResonIcon"
            v-if="fileList.length > 2"
            @click="changeRightReson(fileDiv)"
          />
        </div>
        <div v-else>
          <el-empty />
        </div>
        <!-- 上传新文件 -->
        <div class="selDiv">
          <el-select
            class="baseSel"
            :placeholder="t('base.base142')"
            @change="changeFile"
          >
            <el-option
              v-for="item in baseFile"
              :key="item.id"
              :value="item.id"
              >{{ item.fileName || item.folderName }}</el-option
            >
          </el-select>

          <el-button class="addFileBtn" :loading="timmer != null">
            <upLoadView type="file" @beforeAvatarUpload="upFileFn">
              <img class="step2Img" :src="addFileImg" alt="" />
              <div>{{ t("base.base143") }}</div>
            </upLoadView>
          </el-button>
        </div>
        <!-- 网页 -->
        <div class="flexCenter bottom20">
          <img class="baseImg" :src="netImg" alt="" />
          <div class="fileTitle">{{ t("base.base144") }}</div>
          <div class="chDiv" @click="goUp(2)">
            <img class="chImg" :src="chImg" alt="" />
          </div>
        </div>
        <div class="pa" v-if="netWordList.length != 0">
          <img
            :src="leftReson"
            alt=""
            class="leftResonIcon"
            v-if="netWordList.length > 2"
            @click="changeLeftReson(newDiv)"
          />
          <div class="netListDiv" ref="newDiv">
            <htmlIndex
              class="resonMargin"
              v-for="(item, index) in netWordList"
              :key="item.internetUrl"
              :htmlItem="item"
              :deleType="true"
              @deleteHtml="deleteHtml(index)"
            ></htmlIndex>
          </div>
          <img
            :src="rightReson"
            alt=""
            class="rightResonIcon"
            v-if="netWordList.length > 2"
            @click="changeRightReson(newDiv)"
          />
        </div>
        <div v-else>
          <el-empty />
        </div>
        <!-- 第二部 -->
        <div class="step1Div">
          <div class="flexBetween stepMargin">
            <div class="flexCenter">
              <div class="stepBtn">{{ t("base.base145") }}</div>
              <div class="stepTitle">{{ t("base.base146") }}</div>
            </div>
          </div>
          <!-- 第二步tip -->
          <div class="step1Tip">
            {{ t("base.base147") }}
          </div>
        </div>
        <!-- 一键更新 编辑 -->
        <div class="step2BtnDiv">
          <div class="flexCenter">
            <el-button
              :disabled="editType || updataLoading"
              :class="editType ? 'yjgxBtnDisabled' : 'yjgxBtn'"
              @click="sendFn"
              :loading="updataLoading"
            >
              <img class="step2Img" :src="yjgxImg" alt="" />
              <div class="step2FnFont step2FontColorF">
                {{ t("base.base148") }}
              </div>
            </el-button>
            <el-button
              class="editBtn"
              v-if="!editType"
              @click="editType = !editType"
            >
              <img class="step2Img" :src="editImg" alt="" />
              <div class="step2FnFont step2FontColor8A">
                {{ t("base.base149") }}
              </div>
            </el-button>
            <el-button class="yjgxBtn" v-else @click="saveEdit">
              <img class="step2Img" :src="editAcImg" alt="" />
              <div class="step2FnFont step2FontColorF">
                {{ t("base.base150") }}
              </div>
            </el-button>
          </div>
          <el-button class="editBtn" @click="goMessage">
            <img class="step2Img" :src="hdzcImg" alt="" />
            <div class="step2FnFont step2FontColor8A">
              {{ t("base.base151") }}
            </div>
          </el-button>
        </div>
        <!-- 内容 -->
        <el-input
          v-model="messageText"
          :disabled="!editType"
          autosize
          type="textarea"
          :class="editType ? 'rightInner' : 'textareaBg'"
          class="rightInner"
          :placeholder="!updataLoading ? '请输入正确内容' : '正在更新中...'"
        />
        <!-- 第三步 -->
        <div class="step1Div">
          <div class="flexBetween stepMargin">
            <div class="flexCenter">
              <div class="stepBtn">{{ t("base.base152") }}</div>
              <div class="stepTitle">{{ t("base.base153") }}</div>
            </div>
          </div>
          <!-- 第二步tip -->
          <div class="step1Tip">
            {{ t("base.base154") }}
          </div>
        </div>
        <div class="radioDiv">
          <el-radio-group v-model="setTime">
            <el-radio
              v-for="item in timeList"
              :key="item.value"
              :value="item.value"
              class="radioText"
              >{{ t(item.label) }}</el-radio
            >
          </el-radio-group>
          <el-date-picker
            v-if="setTime == 6"
            class="timePicker"
            v-model="time"
            type="date"
            value-format="YYYY-MM-DD"
            :disabled-date="disabledDate"
            :placeholder="t('base.base155')"
          />
        </div>
        <!-- 下一步 -->
        <div class="nextDiv">
          <el-button
            class="nextQ"
            v-if="leftNumber != 0 && topicList.length - 1 != leftNumber"
            @click="upQ"
          >
            {{ t("base.base156") }}
          </el-button>
          <el-button
            class="nextQ"
            v-if="leftNumber != topicList.length - 1"
            @click="nextQ"
          >
            {{ t("base.base157") }}
          </el-button>
          <el-button
            class="nextQ"
            v-if="topicList.length - 1 == leftNumber && topicList.length != 1"
            @click="goLook"
          >
            {{ t("base.base158") }}
          </el-button>
          <el-button
            v-if="topicList.length == 1"
            :disabled="editType"
            @click="goRelease"
            :class="editType ? 'yjgxBtnDisabled' : 'yjgxBtn'"
          >
            <img class="step2Img" :src="fbyzImg" alt="" />
            <div class="step2FnFont step2FontColorF">
              {{ t("base.base159") }}
            </div>
          </el-button>
        </div>
      </div>
    </div>
    <fileView
      v-if="lookDialog"
      class="fileViewDiv"
      type="chat"
      :fileItem="fileItem"
      :chunkList="chunkList"
      :chunkDialog="true"
      @closeFileView="closeFileView"
    ></fileView>
    <!-- 发布提醒 -->
    <tipView
      v-if="releaseDialog"
      :title="t('base.base160')"
      :tip="t('base.base161')"
      :cancel="t('base.base113')"
      :ok="t('base.base162')"
      @cancel="releaseCancel"
      @ok="releaseOk(true)"
    ></tipView>
    <!-- 冲突提醒 -->
    <tipView
      v-if="conflictDialog"
      :title="t('base.base163')"
      :tip="t('base.base164')"
      :cancel="t('base.base165')"
      :ok="t('base.base166')"
      @cancel="conflictCancel"
      @ok="conflictOk"
    ></tipView>
    <!-- 锚点提醒 -->
    <tipView
      v-if="anchorDialog"
      :title="t('base.base167')"
      :tip="t('base.base168')"
      :cancel="t('base.base169')"
      :ok="t('base.base170')"
      @cancel="anchorCancel"
      @ok="anchorOk"
    ></tipView>
    <!-- 进入验证 -->
    <tipView
      v-if="editByOther"
      :title="t('base.base188')"
      :tip="t('base.base293')"
      :cancel="t('base.base113')"
      :ok="t('base.base294')"
      @cancel="editByOther = false"
      @ok="saveEditByOther"
    ></tipView>

    <!-- 否决 -->
    <div :class="lookDialog ? 'absNos' : 'absNo'" class="flexCenter">
      <el-button
        class="noQBtn"
        @click="closeShare"
        v-if="myStatus != 3 && myStatus != 4"
      >
        <img class="noQ" :src="noQImg" alt="" />
        <div>{{ t("base.base171") }}</div>
      </el-button>
      <div class="outQBtn" @click="goBack">
        <img class="outQ" :src="outQaImg" alt="" />
      </div>
    </div>
  </div>
</template>

<script setup>
import outQaImg from "@/assets/share/outQa.svg";
import noQImg from "@/assets/share/noQ.svg";
import baseImg from "@/assets/share/base.svg";
import chImg from "@/assets/share/ch.svg";
import netImg from "@/assets/share/net.svg";
import editImg from "@/assets/share/edit.svg";
import editAcImg from "@/assets/share/editAc.svg";
import hdzcImg from "@/assets/share/hdzc.svg";
import yjgxImg from "@/assets/share/yjgx.svg";
import addFileImg from "@/assets/share/addFile.svg";
import anchorImg from "@/assets/share/anchorImg.svg";
import qaIndex from "@/components/qa/index.vue";
import detailsQa from "@/components/qa/detailsQa.vue";
import fileIndex from "@/components/shareReson/file.vue";
import htmlIndex from "@/components/shareReson/html.vue";
import talkIndex from "@/components/reson/talk.vue";
import rightReson from "@/assets/chat/rightReson.svg";
import leftReson from "@/assets/chat/leftReson.svg";
import exportImg from "@/assets/share/exportImg.svg";
import fbyzImg from "@/assets/share/fbyz.svg";
import upLoadView from "@/components/upload/index.vue";
import fileView from "@/components/fileView/index.vue";
import tipView from "@/components/tipDialog/index.vue";
import leftFill from "@/assets/chat/leftFill.svg";
import rightFill from "@/assets/chat/rightFill.svg";
import leftNoFill from "@/assets/chat/leftNoFill.svg";
import rightNoFill from "@/assets/chat/rightNoFill.svg";
import { message } from "ant-design-vue";
import { useStore } from "vuex";
import { ref, watch, onMounted } from "vue";
import { useRouter, onBeforeRouteLeave, useRoute } from "vue-router";
import api from "@/api";
import { encryptApi, decryptApi } from "@/utils/crypto";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const store = useStore();
const yzItem = ref(store.state.yzItem);
const userId = ref(store.state.userId);
const editByOther = ref(false);
const setTime = ref(5);
const time = ref();
const timeList = ref([
  {
    label: "base.base172",
    value: 1,
  },
  {
    label: "base.base173",
    value: 2,
  },
  {
    label: "base.base174",
    value: 3,
  },
  {
    label: "base.base175",
    value: 4,
  },
  {
    label: "base.base176",
    value: 5,
  },
  {
    label: "base.base177",
    value: 6,
  },
]);
const fileDiv = ref(null);
const newDiv = ref(null);
const editType = ref(false); //编辑状态
const lookDialog = ref(false);
const releaseDialog = ref(false); //发布提醒
const conflictDialog = ref(false); //冲突dialog
const conflictType = ref(false); //冲突展示
const anchorDialog = ref(false); //锚点跳转前提醒
const fileItem = ref({});
const messageText = ref("");
const leftNumber = ref(0);
const leftList = ref({}); //左侧列表
const model = ref(null); //一键更新
const strategy = ref(null);
const baseFile = ref([]); //文件列表
const fileList = ref([]); //文件列表
const sessionList = ref([]); //对话列表
const netWordList = ref([]); //网页列表
const rootId = ref(null); //rootID
const timmer = ref(null);
const firstMessage = ref(""); //最初的字段
const updataLoading = ref(false);
const publishData = ref({});
const oldData = ref({});
const chunkList = ref([]);
const startFileList = ref([]); //上一步
const startNetWork = ref([]);
const hrefIndex = ref(null);
const myStatus = ref(null);
const topicList = ref([]);
watch(
  () => store.state.yzItem,
  (newValue, oldValue) => {
    yzItem.value = store.state.yzItem;
  }
);
onBeforeRouteLeave((to, from, next) => {
  clearInterval(timmer.value);
  timmer.value = null;
  next();
});
// 生命周期
onMounted(() => {
  getDesc();
});
// 获取详情
const getDesc = () => {
  api.share
    .recordAll({
      userId: userId.value,
      RequestVerifyId: yzItem.value.RequestVerifyId, //4,//12,
      libraryId: yzItem.value.libraryId, //必须
    })
    .then((res) => {
      if (res.returnCode == 200) {
        res.data.forEach((item) => {
          if (item.mySaveData.status == 3) {
            item.mySaveData.question = t("login.login41");
            item.oldData.question = t("login.login41");
          }
        });
        topicList.value = res.data;
        if (route.query.type == "edit") {
          leftNumber.value = topicList.value.length - 1;
        } else {
          leftNumber.value = 0;
        }
        getLeft();
        getBaseFile();
        pollingFn();
        task();
        getRight();
      }
    });
};
const disabledDate = (time) => {
  return time.getTime() < Date.now();
};
// 删除文件
const deleteFile = (index) => {
  fileList.value.splice(index, 1);
};
// 删除来源网页
const deleteHtml = (index) => {
  netWordList.value.splice(index, 1);
};
// 保存存储
const saveEdit = () => {
  editType.value = false;
};
// 撤回上一步
const goUp = (index) => {
  if (index == 1) {
    fileList.value = JSON.parse(JSON.stringify(startFileList.value));
  } else {
    netWordList.value = JSON.parse(JSON.stringify(startNetWork.value));
  }
};
// 选择文件直接进行添加
const changeFile = (e) => {
  fileList.value.unshift(...baseFile.value.filter((item) => item.id == e));
  fileList.value = deduplicateById(fileList.value);
};
// 去重文件列表
const deduplicateById = (files) => {
  const seen = {}; // 用来存储已经遇到的id
  const uniqueFiles = []; // 用来存储去重后的文件数组

  files.forEach((file) => {
    if (!seen[file.id]) {
      seen[file.id] = true; // 标记这个id已经遇到过
      uniqueFiles.push(file); // 将文件添加到结果数组中
    }
  });

  return uniqueFiles;
};
// 获取微模型文件列表
const getBaseFile = () => {
  api.base
    .queryFileList(
      { userId: userId.value, folderId: null },
      yzItem.value.libraryId
    )
    .then((res) => {
      if (res.returnCode == 200) {
        rootId.value = res.data.currentFolderInfo.id;
        baseFile.value = [...res.data.filesInfo];
      }
    });
};
// 上个问答
const upQ = () => {
  if (editType.value) {
    anchorDialog.value = true;
    hrefIndex.value = leftNumber.value - 1;
    return;
  }
  leftNumber.value--;
  getLeft();
  getRight();
};
// 获取日期
const getTimeLast = (setTime) => {
  var today = new Date();
  const addMonths = (monthsToAdd) => {
    var month = today.getMonth();
    var year = today.getFullYear();
    var nextMonth = month + monthsToAdd;
    var nextYear = year;

    if (nextMonth > 11) {
      nextMonth -= 12;
      nextYear += 1;
    }

    return new Date(nextYear, nextMonth, today.getDate());
  };

  switch (setTime) {
    case 1:
      return addMonths(1).toISOString().split("T")[0];
    case 2:
      return addMonths(3).toISOString().split("T")[0];
    case 3:
      return addMonths(6).toISOString().split("T")[0];
    case 4:
      return new Date(
        today.getFullYear() + 1,
        today.getMonth(),
        today.getDate()
      )
        .toISOString()
        .split("T")[0];
    case 5:
      return null;
    case 6:
      return time.value; // 假设time是一个变量，需要在函数外部定义
    default:
      throw new Error("Invalid setTime value");
  }
};
// 下个问答
const nextQ = () => {
  if (myStatus.value == 3 || myStatus.value == 4) {
    leftNumber.value++;
    getLeft();
    getRight();
    return;
  }
  let fileIds = [];
  let folderIds = [];
  fileList.value.forEach((item) => {
    if (item.fileName) {
      fileIds.push(item.id);
    } else if (item.folderName) {
      folderIds.push(item.id);
    }
  });
  let contentSessionId = sessionList.value.map((item) => item.sessionId);
  let internetSource = netWordList.value.map((item) => item.internetUrl);
  if (fileIds.length == 0) {
    message.warning(t("base.base178"));
    return;
  }
  if (messageText.value == "") {
    message.warning(t("base.base179"));
    return;
  }
  if (editType.value) {
    anchorDialog.value = true;
    hrefIndex.value = leftNumber.value + 1;
    return;
  }
  api.share
    .recordSave({
      userId: userId.value,
      RequestVerifyId: yzItem.value.RequestVerifyId,
      ConversationRequestVerifyId:
        topicList.value[leftNumber.value].ConversationRequestVerifyId,
      expireDate: getTimeLast(setTime.value),
      libraryId: yzItem.value.libraryId,
      conversationId: topicList.value[leftNumber.value].oldData.id,
      message: messageText.value,
      model: model.value,
      strategy: strategy.value,
      question: leftList.value.question,
      isOver: conflictType.value ? 1 : 0, //先0或不传，返回201后如果要继续则传
      internetSource: internetSource,
      sessionId: yzItem.value.sessionId, //当前所在的session
      contentSessionId: contentSessionId, //来源session
      fileIds: fileIds,
      folderIds: folderIds,
      timeChoice: setTime.value - 1,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        leftNumber.value++;
        getLeft();
        getRight();
      }
    });
};
// 加减页数
const changeItemPage = (type) => {
  if (type && leftList.value.chatActive < leftList.value.answers.length) {
    leftList.value.chatActive++;
  }
  if (!type && leftList.value.chatActive > 1) {
    leftList.value.chatActive--;
  }
};
// 否决
const closeShare = () => {
  api.share
    .recordReject({
      userId: userId.value,
      RequestVerifyId: yzItem.value.RequestVerifyId,
      conversationId: topicList.value[leftNumber.value].oldData.id,
      ConversationRequestVerifyId:
        topicList.value[leftNumber.value].ConversationRequestVerifyId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        message.success(t("base.base180"));
        getDesc();
        if (topicList.value.length - 1 == leftNumber.value) {
          api.share
            .verifyPublish({
              userId: userId.value,
              RequestVerifyId: yzItem.value.RequestVerifyId,
              libraryId: yzItem.value.libraryId,
              isOver: conflictType.value ? 1 : 0,
            })
            .then((res) => {
              if (res.returnCode == 200) {
                releaseDialog.value = false;
                router.push("/share");
              } else if (res.returnCode == 201) {
                leftNumber.value = 0;
                conflictDialog.value = true;
                getLeft();
                getRight();
              }
            });
        } else {
          editType.value = false;
          anchorDialog.value = false;
          leftNumber.value++;
          getLeft();
          getRight();
        }
      }
    });
};
// 回到最初
const goMessage = () => {
  messageText.value = firstMessage.value;
};
// 获取左侧
const getLeft = () => {
  api.base
    .baseHistory({
      userId: userId.value,
      conversationId: topicList.value[leftNumber.value].oldData.id,
      libraryId: yzItem.value.libraryId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        res.data.chatActive = 1;
        res.data.resonType = false;
        res.data.resonList = [];
        leftList.value = res.data;
      }
    });
};
// 获取右侧
const getRight = () => {
  console.log(topicList.value[leftNumber.value])
  api.share
    .recordAll({
      userId: userId.value,
      RequestVerifyId: yzItem.value.RequestVerifyId,
      ConversationRequestVerifyId:
        topicList.value[leftNumber.value].ConversationRequestVerifyId,
      libraryId: yzItem.value.libraryId,
      conversationId: topicList.value[leftNumber.value].oldData.id,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        if (!res.iscontinue) {
          editByOther.value = true;
        }
        // 有冲突
        if (Object.keys(res.data[0].publishData).length != 0) {
          publishData.value = res.data[0].publishData;
          oldData.value = res.data[0].oldData;
        }
        firstMessage.value = res.data[0].oldData.message;
        if (Object.keys(res.data[0].mySaveData).length != 0) {
          fileList.value = res.data[0].mySaveData.fileResource;
          sessionList.value = res.data[0].mySaveData.sessionResource;
          netWordList.value = res.data[0].mySaveData.internets;
          messageText.value = res.data[0].mySaveData.message;
          model.value = res.data[0].mySaveData.model;
          strategy.value = res.data[0].mySaveData.strategy;
          setTime.value = res.data[0].mySaveData.timeChoice
            ? res.data[0].mySaveData.timeChoice + 1
            : 5;
          myStatus.value = res.data[0].mySaveData.status;
        } else {
          fileList.value = res.data[0].oldData.fileResource;
          sessionList.value = res.data[0].oldData.sessionResource;
          netWordList.value = res.data[0].oldData.internets;
          messageText.value = res.data[0].oldData.message;
          model.value = res.data[0].oldData.model;
          strategy.value = res.data[0].oldData.strategy;
          setTime.value = res.data[0].oldData.timeChoice
            ? res.data[0].oldData.timeChoice + 1
            : 5;
          myStatus.value = 1;
        }
        startFileList.value = JSON.parse(JSON.stringify(fileList.value));
        startNetWork.value = JSON.parse(JSON.stringify(netWordList.value));
      }
    });
};
// 回退
const goBack = () => {
  router.push("/share");
};
// 向左
const changeLeftReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 4);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft - itemWidth;
};
// 向右
const changeRightReson = (el) => {
  const clientWidth = el.clientWidth;
  const itemWidth = Math.floor(clientWidth / 4);
  let currentLeft = el.scrollLeft || 0;
  el.scrollLeft = currentLeft + itemWidth;
};
// 打开文件预览
const openLook = (item, list) => {
  chunkList.value = list;
  fileItem.value = item;
  lookDialog.value = true;
};
// 上传文件fuction
const upFileFn = (fileList) => {
  api.base
    .uploadFileCompanyApi(
      fileList,
      { folderId: rootId.value },
      yzItem.value.libraryId
    )
    .then((res) => {
      if (res.returnCode == 200) {
        pollingFn();
        task();
        if (res.failedFileList.length != 0) {
          const textFile = res.failedFileList.toString();
          message.warning(textFile + t("base.base106"));
        }
      }
    });
};
// 轮询
const pollingFn = () => {
  clearInterval(timmer.value);
  timmer.value = null;
  timmer.value = setInterval(() => {
    setTimeout(() => {
      task();
    }, 0);
  }, 2000);
};
// 轮询
const task = () => {
  api.base
    .taskView({ userId: userId.value }, yzItem.value.libraryId)
    .then((res) => {
      if (res.returnCode == 200) {
        //刷新当前文件夹
        getBaseFile();
        if (res.data.length == 0) {
          clearInterval(timmer.value);
          timmer.value = null;
        }
      }
    });
};
const saveEditByOther = () => {
  api.share
    .continueVerify({
      userId: userId.value,
      RequestVerifyId: yzItem.value.RequestVerifyId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        editByOther.value = false;
      }
    });
};
// 关闭文件预览
const closeFileView = () => {
  lookDialog.value = false;
};
// 点击发布打开弹窗
const goRelease = () => {
  releaseDialog.value = true;
};
// 发布放弃
const releaseCancel = () => {
  releaseDialog.value = false;
};
// 发布
const releaseOk = (type) => {
  let fileIds = [];
  let folderIds = [];
  fileList.value.forEach((item) => {
    if (item.fileName) {
      fileIds.push(item.id);
    } else if (item.folderName) {
      folderIds.push(item.id);
    }
  });
  let contentSessionId = sessionList.value.map((item) => item.sessionId);
  let internetSource = netWordList.value.map((item) => item.internetUrl);
  if (type) {
    if (fileIds.length == 0) {
      message.warning(t("base.base178"));
      return;
    }
    if (messageText.value == "") {
      message.warning(t("base.base179"));
      return;
    }
  }
  api.share
    .recordSave({
      userId: userId.value,
      RequestVerifyId: yzItem.value.RequestVerifyId,
      ConversationRequestVerifyId:
        topicList.value[leftNumber.value].ConversationRequestVerifyId,
      expireDate: getTimeLast(setTime.value),
      libraryId: yzItem.value.libraryId,
      conversationId: topicList.value[leftNumber.value].oldData.id,
      message: messageText.value,
      model: model.value,
      strategy: strategy.value,
      question: leftList.value.question,
      isOver: conflictType.value ? 1 : 0, //先0或不传，返回201后如果要继续则传
      internetSource: internetSource,
      sessionId: yzItem.value.sessionId, //当前所在的session
      contentSessionId: contentSessionId, //来源session
      netWork: netWordList.value,
      fileIds: fileIds,
      folderIds: folderIds,
      timeChoice: setTime.value - 1,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        api.share
          .verifyPublish({
            userId: userId.value,
            RequestVerifyId: yzItem.value.RequestVerifyId,
            libraryId: yzItem.value.libraryId,
            isOver: conflictType.value ? 1 : 0,
          })
          .then((res) => {
            if (res.returnCode == 200) {
              message.success(t("base.base181"));
              releaseDialog.value = false;
              router.push("/share");
            } else if (res.returnCode == 201) {
              leftNumber.value = 0;
              conflictDialog.value = true;
              getLeft();
              getRight();
            }
          });
      }
    });
};
// 放弃冲突
const conflictCancel = () => {
  conflictDialog.value = false;
  router.push("/share");
};
// 原文打冲突
const changeYct = () => {
  fileList.value = oldData.value.fileResource;
  sessionList.value = oldData.value.sessionResource;
  netWordList.value = oldData.value.internets;
  messageText.value =
    leftList.value.answers[leftList.value.chatActive - 1].answerContent;
  model.value = oldData.value.model;
  setTime.value = oldData.value.timeChoice ? oldData.value.timeChoice + 1 : 5;
};
// 上次问答转移到右侧
const changeCt = () => {
  fileList.value = publishData.value.fileResource;
  sessionList.value = publishData.value.sessionResource;
  netWordList.value = publishData.value.internets;
  messageText.value = publishData.value.message;
  model.value = publishData.value.model;
  setTime.value = publishData.value.timeChoice
    ? publishData.value.timeChoice + 1
    : 5;
};
// 解决冲突
const conflictOk = () => {
  api.share
    .conflictVerify({
      userId: userId.value,
      RequestVerifyId: yzItem.value.RequestVerifyId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        conflictDialog.value = false;
        conflictType.value = true;
        releaseDialog.value = false
      }
    });
};
// 锚点点击事件
const clickAnchor = (item, index) => {
  if (editType.value) {
    hrefIndex.value = index;
    console.log(hrefIndex.value, "=========");
    anchorDialog.value = true;
    return;
  }
  leftNumber.value = index;
  getLeft();
  getRight();
};
// 锚点提醒确定
const anchorOk = () => {
  let fileIds = [];
  let folderIds = [];
  fileList.value.forEach((item) => {
    if (item.fileName) {
      fileIds.push(item.id);
    } else if (item.folderName) {
      folderIds.push(item.id);
    }
  });
  let contentSessionId = sessionList.value.map((item) => item.sessionId);
  let internetSource = netWordList.value.map((item) => item.internetUrl);
  if (fileIds.length == 0) {
    message.warning(t("base.base178"));
    return;
  }
  if (messageText.value == "") {
    message.warning(t("base.base179"));
    return;
  }
  api.share
    .recordSave({
      userId: userId.value,
      RequestVerifyId: yzItem.value.RequestVerifyId,
      ConversationRequestVerifyId:
        topicList.value[leftNumber.value].ConversationRequestVerifyId,
      expireDate: getTimeLast(setTime.value),
      libraryId: yzItem.value.libraryId,
      conversationId: topicList.value[leftNumber.value].oldData.id,
      message: messageText.value,
      model: model.value,
      strategy: strategy.value,
      question: leftList.value.question,
      isOver: conflictType.value ? 1 : 0, //先0或不传，返回201后如果要继续则传
      internetSource: internetSource,
      sessionId: yzItem.value.sessionId, //当前所在的session
      contentSessionId: contentSessionId, //来源session
      fileIds: fileIds,
      folderIds: folderIds,
      netWork: netWordList.value,
      timeChoice: setTime.value - 1,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        editType.value = false;
        anchorDialog.value = false;
        leftNumber.value = hrefIndex.value;
        getLeft();
        getRight();
      }
    });
};
// 锚点提醒取消
const anchorCancel = () => {
  anchorDialog.value = false;
  editType.value = false;
  leftNumber.value = hrefIndex.value;
  getLeft();
  getRight();
};
// 进入预览
const goLook = () => {
  let fileIds = [];
  let folderIds = [];
  fileList.value.forEach((item) => {
    if (item.fileName) {
      fileIds.push(item.id);
    } else if (item.folderName) {
      folderIds.push(item.id);
    }
  });
  let contentSessionId = sessionList.value.map((item) => item.sessionId);
  let internetSource = netWordList.value.map((item) => item.internetUrl);
  if (fileIds.length == 0) {
    message.warning(t("base.base178"));
    return;
  }
  api.share
    .recordSave({
      userId: userId.value,
      RequestVerifyId: yzItem.value.RequestVerifyId,
      ConversationRequestVerifyId:
        topicList.value[leftNumber.value].ConversationRequestVerifyId,
      expireDate: getTimeLast(setTime.value),
      libraryId: yzItem.value.libraryId,
      conversationId: topicList.value[leftNumber.value].oldData.id,
      message: messageText.value,
      model: model.value,
      strategy: strategy.value,
      question: leftList.value.question,
      isOver: conflictType.value ? 1 : 0, //先0或不传，返回201后如果要继续则传
      internetSource: internetSource,
      sessionId: yzItem.value.sessionId, //当前所在的session
      contentSessionId: contentSessionId, //来源session
      fileIds: fileIds,
      folderIds: folderIds,
      netWork: netWordList.value,
      timeChoice: setTime.value - 1,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        api.share
          .verifyStatus({
            userId: userId.value,
            RequestVerifyId: yzItem.value.RequestVerifyId,
          })
          .then((res) => {
            // inConflict点过冲突  status2  发布状态 2发布
            if (res.data.inConflict) {
              router.push({
                name: "shareView",
                query: {
                  type: "edit",
                },
              });
              return;
            }
            if (res.data.status == 2) {
              leftNumber.value = 0;
              conflictDialog.value = true;
              getLeft();
              getRight();
              return;
            } else {
              router.push({
                name: "shareView",
                query: {
                  type: "edit",
                },
              });
              return;
            }
          });
      }
    });
};
// 一键更新
const sendFn = async () => {
  let fileIds = [];
  let folderIds = [];
  updataLoading.value = true;
  fileList.value.forEach((item) => {
    if (item.fileName) {
      fileIds.push(item.id);
    } else if (item.folderName) {
      folderIds.push(item.id);
    }
  });
  let obj = {
    userId: userId.value,
    model: model.value, //暂无
    message: leftList.value.question,
    libraryId: yzItem.value.libraryId,
    isInternetOn: false,
    fileIds: fileIds,
    folderIds: folderIds,
    sessionId: yzItem.value.sessionId,
    strategy: strategy.value, //暂无
    regenerateFlag: 0,
    internetSource: null,
    conversationId: topicList.value[leftNumber.value].oldData.id,
    inValid: true,
    regenerateFlag: 1,
  };
  messageText.value = "";
  let time = Math.round(new Date() / 1000);
  let postObj = {
    time: time,
    idempotentEncryData: encryptApi(JSON.stringify({ ...obj, time: time })),
  };
  let lang = store.state.lang;
  if (lang === "CN") {
    lang = "zh-CN";
  } else if (lang === "HK") {
    lang = "zh-HK";
  } else if (lang === "US") {
    lang = "EN";
  }
  try {
    let controller = new AbortController();
    const response = await fetch("/openai/chat/v4/knowledge/chat", {
      method: "post",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        Authorization: "Bearer " + store.state.token,
        "Accept-Language": lang,
      },
      body: JSON.stringify(postObj),
      signal: controller.signal,
    });
    if (response.status === 500) {
      updataLoading.value = false;
      message.warning(t("base.base182"));
    }
    if (response.status === 504) {
      updataLoading.value = false;
      message.warning(t("base.base183"));
    }
    const reader = response.body.getReader();
    let flag = true;
    let data = "";
    while (flag) {
      const { done, value } = await reader.read();
      const rawData = new TextDecoder().decode(value);
      const regex = /<start>(.*?)<end>/g;
      let matches;
      const extractedData = [];
      while ((matches = regex.exec(rawData)) !== null) {
        extractedData.push(matches[1]);
      }
      const decryptedData = extractedData.map((data) => decryptApi(data));
      data += decryptedData.map((item) => item).join("");
      messageText.value = data;
      updataLoading.value = false;
      if (done) {
        if (
          !data.includes("returnCode") &&
          !data.includes("!(end of answer)")
        ) {
        } else if (data.includes("!(end of answer)")) {
          message.warning(t("base.base184"));
          messageText.value = t("base.base184");
        } else if (data.includes("returnCode")) {
          let newData = JSON.parse(data);
          message.warning(newData.returnMessage);
          if (newData.returnCode == 423 || newData.returnCode == 412) {
            router.push("/login");
          } else {
            messageText.value = newData.returnMessage;
          }
        }
        break;
      }
    }
  } catch (err) {
    updataLoading.value = false;
    console.log(err);
  }
};
</script>

<style lang="scss" scoped>
.shareContent {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}
.leftBigDiv {
  width: calc(50% - 10px);
  height: 100%;
}
.left {
  position: relative;
  padding: 30px;
  margin-right: 10px;
  background-color: #fefefe;
  border-radius: 30px;
  height: calc(100% - 60px);
}
.leftBigDivFlex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.leftConflict {
  position: relative;
  padding: 30px;
  margin-right: 10px;
  background-color: #fefefe;
  border-radius: 30px;
  height: calc(50% - 70px);
}
.shareLeftDiv {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.shareRightDiv {
  width: calc(50% - 70px);
  margin-left: 10px;
  background-color: #fefefe;
  border-radius: 30px;
  height: calc(100% - 60px);
  padding: 30px;
  overflow: auto;
  position: relative;
}
.shareRightDivLook {
  width: 447px;
  margin-right: 20px;
  background-color: #fefefe;
  border-radius: 30px;
  height: calc(100% - 60px);
  padding: 30px;
  overflow: auto;
  position: relative;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.stepMargin {
  margin-bottom: 6px;
}
.stepBtn {
  background-color: #767bfa;
  height: 31px;
  text-align: center;
  line-height: 31px;
  border-radius: 30px;
  color: #ffffff;
  font-size: 18px;
  font-family: "Semibold";
  padding: 0px 15px;
}
.stepTitle {
  font-size: 20px;
  font-family: "Semibold";
  color: #3d3d3d;
  margin-left: 12px;
}
.outQ {
  width: 20px;
}
.noQ {
  width: 14px;
  margin-right: 6px;
}
.noQBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #edeef6;
  border-radius: 12px;
  padding: 5px 12px;
  color: #f02d63;
  font-family: "Regular";
  font-size: 18px;
  cursor: pointer;
}
.outQBtn {
  width: 36px;
  height: 36px;
  background-color: #edeef6;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 8px;
}
.step1Tip {
  font-size: 16px;
  color: #8a8a8a;
  font-family: "Medium";
}
.step1Div {
  margin-bottom: 24px;
}
.fileTitle {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Semibold";
  margin: 0px 12px;
}
.baseImg {
  width: 25px;
}
.chDiv {
  width: 21px;
  height: 21px;
  background-color: #edeef6;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chImg {
  width: 13px;
}
.fileListDiv {
  margin: 17px 15px 12px 11px;
  height: 78px;
  padding: 4px;
  display: flex;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.netListDiv {
  margin: 17px 15px 30px 11px;
  height: 114px;
  padding: 4px;
  display: flex;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.resonMargin {
  margin-right: 12px;
}
.absNo {
  position: absolute;
  right: 30px;
  top: 17px;
  z-index: 9;
  background-color: #ffffff;
  // box-shadow: -20px 20px 20px 0px #ffffff;
}
.absNos {
  position: absolute;
  left: 335px;
  top: 17px;
  z-index: 9;
  background-color: #ffffff;
  // box-shadow: -20px 20px 20px 0px #ffffff;
}
.pa {
  position: relative;
  .rightResonIcon {
    display: none;
  }
  .leftResonIcon {
    display: none;
  }
}
.pa:hover {
  .rightResonIcon {
    display: block;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%);
    width: 30px;
    z-index: 9;
    cursor: pointer;
  }
  .leftResonIcon {
    display: block;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translate(0px, -50%);
    width: 30px;
    z-index: 9;
    cursor: pointer;
  }
}
.step2Img {
  width: 25px;
  margin-right: 3px;
  height: 25px;
}
.step2FnFont {
  font-size: 18px;
  font-family: "Regular";
}
.step2FontColorF {
  color: #fefefe;
}
.step2FontColor8A {
  color: #8a8a8a;
}
.yjgxBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  background-color: #3376ff;
  border-radius: 12px;
  padding: 10px 20px;
  margin-right: 8px;
}
.editBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #edeef6;
  border-radius: 12px;
  height: 42px;
  padding: 6px 12px;
}
.step2BtnDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 27px;
  margin: 24px 0px 12px 0px;
}
.textareaBg {
  background-color: #eceeff;
}
:deep(.el-textarea.is-disabled .el-textarea__inner) {
  background-color: #eceeff;
  color: #3d3d3d;
  cursor: text;
  box-shadow: 0px 0px 0px 0px #ffffff;
}
:deep(.el-textarea__inner) {
  border: 0px;
  resize: none;
  outline: none;
  width: 100%;
  overflow: auto;
  height: 100%;
  font-size: 18px;
  font-family: "Regular";
  box-shadow: 0px 0px 0px 0px #ffffff;
}
.rightInner {
  border: 2px solid #dbdfff;
  padding: 15px 20px;
  border-radius: 16px;
  font-size: 20px;
  font-family: "Regular";
  line-height: 34px;
  margin: 0px 15px 30px 15px;
  width: calc(100% - 70px) !important;
}
.radioText {
  font-size: 18px;
  font-family: "Regular";
  color: #3d3d3d;
  :deep(.el-radio__input.is-checked .el-radio__inner) {
    background-color: transparent;
    border: 1px solid #3376ff;
  }
  :deep(.el-radio__input.is-checked .el-radio__inner::after) {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #3376ff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    content: "";
  }
  :deep(.el-radio__inner::after) {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #8a8a8a;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    content: "";
  }
  :deep(.el-radio__inner) {
    border: 1px solid #8a8a8a;
    width: 18px;
    height: 18px;
  }
  :deep(el-radio__label) {
    font-size: 18px;
    font-family: "Regular";
    color: #3d3d3d;
  }
}
.radioDiv {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
}
.nextQ {
  border-radius: 16px;
  background-color: #3376ff;
  color: #ffffff;
  font-size: 20px;
  font-family: "Regular";
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
  height: auto;
}
.nextDiv {
  display: flex;
  justify-content: flex-end;
}
:deep(.timePicker) {
  height: 36px !important;
  width: 160px !important;
  :deep(.el-input__wrapper) {
    box-shadow: 0px 0px 0px 1px #8a8a8a;
  }
  :deep(.el-input__inner) {
    color: #3d3d3d;
    font-size: 18px;
    font-family: "Regular";
  }
}
.yjgxBtnDisabled {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  background-color: #d9d9d9;
  border-radius: 12px;
  padding: 6px 12px;
  margin-right: 8px;
}
.yjgxBtnDisabled:hover {
  background-color: #d9d9d9 !important;
}
.addFileBtn {
  border: 2px solid #edeef6;
  height: 48px;
  font-size: 18px;
  color: #0256ff;
  font-family: "Regular";
  border-radius: 16px;
  margin-left: 12px;
}
.baseSel {
  :deep(.el-select__wrapper) {
    height: 48px;
    border: 2px solid #edeef6;
    box-shadow: 0px 0px 0px 0px #ffffff;
    border-radius: 16px;
    font-size: 18px;
    color: #d9d9d9;
    font-family: "Regular";
  }
}
.selDiv {
  display: flex;
  align-items: center;
  padding: 0px 15px;
  margin-bottom: 24px;
}
.fileViewDiv {
  width: calc(100% - 547px);
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
}
.leftqaTitle {
  position: absolute;
  background-color: #767bfa;
  color: #ffffff;
  padding: 3px 11px;
  border-radius: 30px;
  font-size: 18px;
  font-family: "Semibold";
  top: 30px;
  left: 30px;
  box-shadow: 20px 20px 20px 0px #ffffff;
  z-index: 199;
}
.leftqaBtn {
  background-color: #767bfa;
  color: #ffffff;
  padding: 3px 11px;
  border-radius: 30px;
  font-size: 18px;
  font-family: "Semibold";
  margin-right: 12px;
}
.leftqaTitles {
  position: absolute;
  top: 30px;
  left: 30px;
  // box-shadow: 20px 20px 20px 0px #ffffff;
  display: flex;
  align-items: center;
  z-index: 9;
}
.exportImg {
  width: 17px;
}
.exportImgDiv {
  width: 25px;
  height: 25px;
  border-radius: 8px;
  background-color: #edeef6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: rgb(255 255 255 / 80%);
}
.qaIndex {
  margin-bottom: 43px;
}
.qaIndexOp {
  opacity: 0.5;
}
.anchorImg {
  width: 18px;
}
.anchorImgDiv {
  position: absolute;
  width: 36px;
  height: 36px;
  background-color: #edeef6;
  border-radius: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  right: 30px;
  bottom: 30px;
  box-shadow: -20px 0px 24px 0px #fefefe;
}
.anchorHover {
  width: 100%;
  padding-left: 10px;
  border-left: 4px solid #8a8a8a;
}
.fillCenter {
  display: flex;
  align-items: center;
  margin-right: 12px;
}
.fillColor {
  color: #d9d9d9;
}
.fillImg {
  width: 24px;
  cursor: pointer;
}
.fillText {
  font-size: 18px;
  font-family: "Semibold";
  color: #8a8a8a;
}
.hrefNoac {
  color: #8a8a8a;
  font-family: "Regular";
  font-size: 16px;
  margin-bottom: 14px;
  cursor: pointer;
  position: relative;
}
.hrefNoac::after {
  content: "";
  width: 3px;
  height: 28px;
  background-color: #8a8a8a;
  position: absolute;
  left: -13px;
}
.hrefAc {
  color: #0256ff;
  font-family: "Regular";
  font-size: 16px;
  margin-bottom: 14px;
  cursor: pointer;
  position: relative;
}
.hrefAc::after {
  content: "";
  width: 3px;
  height: 28px;
  background-color: #0256ff;
  position: absolute;
  left: -13px;
}
.bottom20 {
  margin-bottom: 20px;
}
</style>
