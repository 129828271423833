<template>
  <div class="chatCom">
    <chatComponents
      :bottomFileList="props.bottomFileList ? props.bottomFileList : []"
      @changeFile="changeFile"
      @closeChat="closeChat"
      :type="props.type"
    ></chatComponents>
  </div>
  <div class="mask" @click="closeChat"></div>
</template>

<script setup>
import chatComponents from "@/components/chat/index.vue";
const emit = defineEmits(["closeChat"]);
const props = defineProps(["bottomFileList", "type"]);
const closeChat = () => {
  emit("closeChat");
};
const changeFile = (list) => {};
</script>

<style lang="scss" scoped>
.chatCom {
  position: fixed;
  width: 947px;
  min-height: 227px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1599;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 30px;
}
.mask {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.3;
  z-index: 1598;
}
</style>
