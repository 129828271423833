import { App, computed,ref } from 'vue';
import { useStore } from 'vuex';
import { hasPermission } from '@/utils/permission';
 
export default {
  install(app: App) {
    // 在 Vue 的全局属性上添加一个方法用于权限检查
    app.config.globalProperties.$hasPermission = function (permissionId:any) {
      const store = useStore();
      var arr = [];
      arr = store.state.roles
      arr = arr.map((i:any) => Number(i));
      const type = arr.some((item:any) => item === Number(permissionId))
      return type;
    };
  }
};