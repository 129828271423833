<template>
    <div class="addContent">
        <div class="titleFlex">
            <div class="addTitle">{{ t('bot.bot41') }}</div>
            <img :src="addClose" alt="" class="addClose" @click="closeUser" />
        </div>
        <div class="nacFelx">
            <div :class="activeName == index ? 'ac right18' : 'noAc right18'" v-for="(item, index) in navList"
                :key="index" @click="changeNav(index)">
                {{ item }}
            </div>
        </div>
        <div class="bodyCenter">
            <div class="left">
                <el-input class="searchInput" v-model="departmentName"
                    :placeholder="activeName == 0 ? t('bot.bot50') : t('bot.bot51')" @input="searchInput"></el-input>
                <div class="treeDiv" v-loading="leftLoading">
                    <el-tree ref="treeRef" v-if="activeName == 0" :data="departmentList" :props="departmentProps"
                        node-key="departmentId" show-checkbox :default-expand-all="true" :expand-on-click-node="false"
                        :check-strictly="true" @check="handleCheckChange" @node-click="handleNodeClick">
                        <template #default="{ node }">
                            <div>
                                <el-tooltip popper-class="toolTip" :content="node.label">
                                    <span>{{ node.label }}</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-tree>
                    <el-tree v-else ref="treeRef" :data="departmentList" :props="userGroupProps" node-key="groupId"
                        show-checkbox :default-expand-all="true" :expand-on-click-node="false" :check-strictly="true"
                        @check="handleCheckChange" @node-click="handleNodeClick" class="tree2">
                        <template #default="{ node }">
                            <div>
                                <el-tooltip popper-class="toolTip" :content="node.label">
                                    <span>{{ node.label }}</span>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-tree>
                </div>
            </div>
            <div class="right">
                <el-table v-if="activeName == 0" :data="userTableData" ref="multipleTableRef" row-key="userId" border
                    style="height: 100%" v-loading="rightLoading" @select="changeSel" @select-all="changeSelAll">
                    <el-table-column type="selection" width="55" align="center" :selectable="selectable"/>
                    <el-table-column prop="userName" :label="t('bot.bot52')" />
                    <el-table-column prop="roleName" :label="t('bot.bot53')" />
                </el-table>
                <el-table v-if="activeName == 1" :data="groupTableList.users" ref="multipleTableRef" row-key="userId"
                    border style="height: 100%" v-loading="rightLoading" @select="changeSel" @select-all="changeSelAll">
                    <el-table-column type="selection" width="55" align="center" :selectable="selectable"/>
                    <el-table-column prop="userName" :label="t('bot.bot52')" />
                    <el-table-column prop="departmentName" :label="t('bot.bot102')" />
                </el-table>
            </div>
        </div>
        <div class="dialog-footer">
            <div @click="closeUser" class="cancel">{{ t('bot.bot48') }}</div>
            <div @click="saveUser" class="confirm">{{ t('bot.bot47') }}</div>
        </div>
    </div>
    <div class="mask"></div>
</template>

<script setup>
import addClose from "@/assets/base/addClose.svg";
import api from "@/api";
import { defineEmits, nextTick, onMounted, ref, defineProps } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useStore();
const userId = ref(store.state.userId);
const departmentList = ref([]); //部门列表
const activeName = ref(0); //nav
const navList = ref(["部门", "群组"]);
const leftDepartmentId = ref(null); //左侧选择的id 部门和群组公用
const userTableData = ref([]); //右侧table
const rightLoading = ref(false); //loading
const leftLoading = ref(false); //loading
const rightList = ref([]); //右侧选择
const departmentLeftList = ref([]); //部门左侧选择
const multipleTableRef = ref(); //ref
const groupLeftList = ref([]); //部门
const groupTableList = ref({}); //群组table
const leftIds = ref([]);
const rightIds = ref([]);
const treeRef = ref(null);
const departmentProps = ref({
    label: "departmentName",
    children: "children",
    value: "departmentId",
});
const userGroupProps = ref({
    label: "groupName",
    children: "children",
    value: "groupId",
});
const departmentName = ref(""); //搜索名称
const emit = defineEmits(["closeUser", "saveUser"]);
const props = defineProps({
    userList:Array,userObj:Object
});
onMounted(() => {    
    console.log(props.userObj)
    if (props.userList !== undefined) {
        rightList.value = props.userList?.userList; //人
        departmentLeftList.value = props.userList?.departmentList; //部门
        leftIds.value = props.userList.departmentList.map((item) => item.id)
        groupLeftList.value = props.userList?.gropuList; //群组
        rightIds.value = props.userList?.gropuList.map((item) => item.id)
    }
    getDepartment();
});    
const selectable = (row)=>{
  return row.userId != props.userObj[0].id
}     
// 保存user
const saveUser = () => {
    rightList.value.forEach((item) => {
        if (item.userId == props.userObj[0].id) {
        item.type = 0;
        }
    });
    let arr = rightList.value.concat(
        departmentLeftList.value,
        groupLeftList.value
    );
    console.log(arr);
    
    emit("saveUser", arr);
};
const changeNav = (index) => {
    activeName.value = index;
    departmentList.value = [];
    if (activeName.value == 0) {
        getDepartment();
    } else {
        getGroupList();
    }
};
// 搜索
const searchInput = () => {
    departmentList.value = [];
    if (activeName.value == 0) {
        getDepartment();
    } else {
        getGroupList();
    }
};
// 选择树 //单个
const handleCheckChange = (item) => {
    if (activeName.value == 0) {
        leftIds.value = treeRef.value.getCheckedKeys(false);
        const index = departmentLeftList.value.findIndex(
            (items) => items.departmentId === item.departmentId
        );
        if (index !== -1) {
            departmentLeftList.value.splice(index, 1);
        } else {
            departmentLeftList.value.push({
                ...item,
                id: item.departmentId,
                name: item.departmentName,
                type: 1,
                ownerType: 1,
            });
        }
    } else {
        rightIds.value = treeRef.value.getCheckedKeys(false);
        const index = groupLeftList.value.findIndex(
            (items) => items.groupId === item.groupId
        );
        if (index !== -1) {
            groupLeftList.value.splice(index, 1);
        } else {
            groupLeftList.value.push({
                ...item,
                id: item.groupId,
                name: item.groupName,
                type: 1,
                ownerType: 2,
            });
        }
    }
};
// 树选择
const handleNodeClick = (e) => {
    if (activeName.value == 0) {
        leftDepartmentId.value = e.departmentId;
        getUserTable();
    } else {
        leftDepartmentId.value = e.groupId;
        getGroupUser();
    }
};
// 获取左侧列表
const getDepartment = () => {
    leftLoading.value = true;
    api.manage
        .getDepartmentList({
            userId: userId.value,
            departmentName: departmentName.value,
        })
        .then((res) => {
            leftLoading.value = false;
            if (res.returnCode == 200) {
                departmentList.value = res.data;
                leftDepartmentId.value = res.data[0].departmentId;
                getUserTable();
                nextTick(() => {
                    treeRef.value.setCheckedKeys(leftIds.value, false);
                });
            }
        })
        .catch((err) => {
            leftLoading.value = false;
        });
};
// 左侧群组
const getGroupList = () => {
    leftLoading.value = true;
    api.manage
        .queryGroupList({
            search: departmentName.value,
            userId: userId.value,
        })
        .then((res) => {
            leftLoading.value = false;
            if (res.returnCode == 200) {
                leftDepartmentId.value = res.data[0].groupId;
                departmentList.value = res.data;
                getGroupUser();
                nextTick(() => {
                    treeRef.value.setCheckedKeys(rightIds.value, false);
                });
            }
        })
        .catch((err) => {
            leftLoading.value = false;
        });
};
const getGroupUser = () => {
    rightLoading.value = true;
    api.manage
        .getGroupTableApi({
            userId: userId.value,
            groupId: leftDepartmentId.value,
            pageId: 0,
            perPage: 0,
        })
        .then((res) => {
            rightLoading.value = false;
            if (res.returnCode == 200) {
                groupTableList.value = res.data;
                toggleSelection();
            }
        })
        .catch((err) => {
            rightLoading.value = false;
        });
};
// 获取右侧table
const getUserTable = () => {
    rightLoading.value = true;
    api.manage
        .getUserList({
            userId: userId.value,
            departmentId: leftDepartmentId.value,
            pageId: 0,
            perPage: 10,
        })
        .then((res) => {
            rightLoading.value = false;
            if (res.returnCode == 200) {
                userTableData.value = res.data;
                toggleSelection();
            }
        })
        .catch((err) => {
            rightLoading.value = false;
        });
};
// 右侧回显选择
const toggleSelection = () => {
    nextTick(() => {
        multipleTableRef.value.clearSelection();
        rightList.value.forEach((row) => {
            userTableData.value.forEach((item) => {
                if (item.userId === Number(row.userId )|| item.userId === Number(row.id )) {
                    multipleTableRef.value.toggleRowSelection(item, true);
                }
            });
        });
    });
};
// 右侧全选
const changeSelAll = (list) => {
    //   有数据则添加
    if (list.length != 0) {
        list.forEach((item) => {
            rightList.value.push({
                ...item,
                id: item.userId,
                name: item.userName,
                type: 1,
                ownerType: 0,
            });
        });
    } else {
        // 拿table列表数据
        userTableData.value.forEach((item) => {
            const index = rightList.value.findIndex(
                (user) => user.userId === item.userId
            );
            if (index !== -1) {
                rightList.value.splice(index, 1);
            }
        });
    }
};
// 右侧选择人 value数组
const changeSel = (value, row) => {
    const index = rightList.value.findIndex((user) => user.userId === row.userId);
    if (index !== -1) {
        rightList.value.splice(index, 1);
        nextTick(() => {
            multipleTableRef.value.toggleRowSelection(row, false);
        });
    } else {
        rightList.value.push({
            ...row,
            id: row.userId,
            name: row.userName,
            type: 1,
            ownerType: 0,
        });
    }
};
// 关闭添加用户
const closeUser = () => {
    emit("closeUser");
};
</script>

<style lang="scss" scoped>
:deep(.el-tree-node) {
    width: 225px;
    display: table;
}

.mask {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #000000;
    z-index: 61;
    opacity: 0.2;
}

.addContent {
    position: absolute;
    width: 700px;
    height: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    z-index: 62;
    padding: 30px;
    border-radius: 30px;
}

.titleFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    margin-bottom: 20px;
}

.addClose {
    width: 14px;
    cursor: pointer;
}

.addTitle {
    font-size: 20px;
    color: #3d3d3d;
    font-family: "Medium";
}

.left {
    width: 250px;
}

.right {
    margin-left: 10px;
    width: 440px;
}

.bodyCenter {
    display: flex;
    height: 344px;
}

.treeDiv {
    height: calc(100% - 58px);
    overflow: auto;
    margin-top: 10px;
}

:deep(.el-tree-node__content) {
    height: 50px;
    font-size: 18px;
    color: #3d3d3d;
    font-family: "Regular";
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

:deep(.el-tree-node__content:hover) {
    background-color: #eceeff;
    color: #0256ff;
    width: 100%;
}

:deep(.el-tree-node:focus > .el-tree-node__content) {
    background-color: #eceeff;
    color: #0256ff;
    width: 100%;
}

.searchInput {
    font-size: 18px;
    font-family: "Regular";
}

.dialog-footer {
    display: flex;
    justify-content: end;
    font-size: 15px;
    margin-top: 24px;
    .cancel {
        color: #F02D63;
        padding: 8px 20px;
        border-radius: 16px;
        border: 1px solid #EDEEF6;
        cursor: pointer;
    }

    .confirm {
        color: #fff;
        background-color: #3376FF;
        margin-left: 10px;
        padding: 8px 20px;
        border-radius: 16px;
        cursor: pointer;
    }
}

.noAc {
    font-size: 20px;
    color: #8a8a8a;
    font-family: "Regular";
    padding-bottom: 6px;
    border-bottom: 2px solid #8a8a8a;
    cursor: pointer;
}

.ac {
    font-size: 20px;
    color: #0256ff;
    font-family: "Regular";
    padding-bottom: 6px;
    border-bottom: 2px solid #0256ff;
    cursor: pointer;
}

.nacFelx {
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 10px;
}

.right18 {
    margin-right: 18px;
}
</style>