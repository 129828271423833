<template>
    <div class="avatar-group">
        <div style="display: flex;" class="box">
            <div v-for="(avatar, index) in props.avatars" :key="index" class="avatar" v-show="index < number">
                <div class="img" :style="'background-color:' + getRandomColor1()">
                    {{ avatar.userName?.slice(0, 1) }}
                </div>
            </div>
        </div>
        <div v-if="props.avatars?.length > number" class="moreSpan"> +{{ props.avatars?.length - number }}</div>
        <div v-else></div>
    </div>
</template>

<script setup>
import { getRandomColor1 } from "@/utils/utils";
import { defineProps } from 'vue';
const props = defineProps({
    avatars: Array,
    number: Number
})
const avatars = props.avatars
const number = props.number;    
</script>
<style scoped lang="less">
.avatar-group {
    display: flex;
}

.avatar {
    margin-left: -6px;
    text-align: center;
}
.avatar .img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    line-height: 30px;
    font-family: "Semibold"
}

.box {
    margin-right: 10px;
}

.moreSpan {
    height: 30px;
    background-color: #D9D9D9;
    border-radius: 15px;
    color: #8A8A8A;
    font-size: 14px;
    padding: 3px 6px;
}
</style>