<template>
  <div style="height: 100%">
    <VuePdfApp theme="light" :config="config" :pdf="props.pdfUrl"></VuePdfApp>
  </div>
</template>

<script setup>
import VuePdfApp from "vue3-pdf-app";
import "vue3-pdf-app/dist/icons/main.css";
import { ref } from "vue";
const props = defineProps(["pdfUrl"]);
// 工具栏配置项
const config = ref({
  // 右侧其他区工具
  sidebar: {
    viewThumbnail: true, //启用缩略图视图
    viewOutline: true, //启用大纲视图
    viewAttachments: false, //启用附件视图
  },
  secondaryToolbar: {
    secondaryPresentationMode: true, //启用演示模式
    secondaryOpenFile: false, //启用打开文件功能
    secondaryPrint: true, //启用打印功能
    secondaryDownload: true, //启用下载功能
    secondaryViewBookmark: true, //启用书签视图
    firstPage: true, //启用跳转到第一页
    lastPage: true, //启用跳转到最后一页
    pageRotateCw: true, //启用顺时针旋转页面
    pageRotateCcw: true, //启用逆时针旋转页面
    cursorSelectTool: true, //启用选择工具
    cursorHandTool: true, //启用手形工具
    scrollVertical: true, //启用垂直滚动
    scrollHorizontal: true, //启用水平滚动
    scrollWrapped: true, //启用包裹滚动
    spreadNone: false, //启用无跨页模式
    spreadOdd: false, // 启用奇数页跨页模式
    spreadEven: false, //启用偶数页跨页模式
    documentProperties: true, //启用文档属性查看
  },
  // 配置左侧工具栏
  toolbar: {
    toolbarViewerLeft: {
      findbar: true, //启用查找条
      previous: true, // 启用上一页按钮
      next: true, //启用下一页按钮
      pageNumber: true, // 启用页码显示
    },
    //  配置右侧工具栏
    toolbarViewerRight: {
      presentationMode: true, //启用演示模式
      openFile: false, //启用打开文件功能
      print: true, //启用打印功能
      download: true, // 启用下载功能
      viewBookmark: false, // 启用书签视图
    },
    // 配置中间工具栏
    toolbarViewerMiddle: {
      zoomOut: true, // 启用缩小功能
      zoomIn: true, //启用放大功能。
      scaleSelectContainer: true, //启用缩放选择容器功能
    },
  },
  errorWrapper: true, //启用错误包装，这可能用于显示错误信息或处理错误情况。
});
</script>

<style lang="scss" scoped>
:deep(.pdf-app .toolbar){
  z-index: 5 !important;
}
</style>
