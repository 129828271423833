<template>
  <div class="cardDiv">
    <div class="flexCenter">
      <el-tooltip popper-class="toolTip" :content="props.title + '（CNY）'">
        <div class="carTitle">{{ props.title }}（CNY）</div>
      </el-tooltip>
      <img
        class="titleIcon"
        v-if="props.item.detail&&props.item.detail.length != 0&&props.item.imgType"
        :src="upReson"
        alt=""
        @click="props.item.imgType = !props.item.imgType"
      />
      <img
        class="titleIcon"
        v-if="props.item.detail&&props.item.detail.length != 0&&!props.item.imgType"
        :src="downReson"
        alt=""
        @click="props.item.imgType = !props.item.imgType"
      />
    </div>
    <div class="flexBetWeen">
      <div class="flexCenter wid">
        <div class="textDiv">输<br />入</div>
        <div class="bigNumber flexCenter wid40">
          <span class="numberDw">¥</span>
          <el-tooltip popper-class="toolTip" :content="props.left">
            <div class="widDiv">{{ props.left.toFixed(2) }}</div>
          </el-tooltip>
        </div>
      </div>
      <div class="flexCenter wid">
        <div class="textDiv">输<br />出</div>
        <div class="bigNumber flexCenter wid40">
          <span class="numberDw">¥</span>
          <el-tooltip popper-class="toolTip" :content="props.right">
            <div class="widDiv">{{ props.right.toFixed(2) }}</div>
          </el-tooltip>
        </div>
      </div>
    </div>
    <img
      :src="echarImg"
      class="echarImg"
      alt=""
      @click="openEchar(props.item)"
      v-if="
        props.item.daily &&
        props.item.daily.length != 0 &&
        props.item.detail &&
        props.item.detail.length != 0
      "
    />
  </div>
</template>

<script setup>
import upReson from "@/assets/chat/upReson.svg";
import downReson from "@/assets/chat/downReson.svg";
import echarImg from "@/assets/statistics/echar.svg";
const props = defineProps(["title", "left", "right", "item"]);
const emit = defineEmits(["openEchar"]);
const openEchar = (item) => {
  emit("openEchar", item.daily, item.detail_cost);
};
</script>

<style lang="scss" scoped>
.cardDiv {
  background-color: #f7f8ff;
  border-radius: 30px;
  padding: 30px;
  width: calc(100% - 60px);
  height: 180px;
  position: relative;
}
.carTitle {
  color: #3d3d3d;
  font-size: 20px;
  font-family: "Medium";
  width: calc(100% - 20px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.titleIcon {
  width: 17px;
}
.bigNumber {
  font-size: 40px;
  color: #767bfa;
  font-family: "bold";
  text-align: center;
  height: 117px;
  line-height: 117px;
}
.numberDw {
  font-size: 32px;
  width: 21px;
}
.echarImg {
  width: 24px;
  position: absolute;
  right: 30px;
  bottom: 30px;
  cursor: pointer;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.textDiv {
  background-color: #dbdfff;
  border-radius: 8px;
  font-size: 16px;
  color: #0256ff;
  font-family: "Medium";
  padding: 6px;
  margin-right: 12px;
  width: 28px;
}
.flexBetWeen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wid {
  width: 50%;
}
.widDiv {
  width: calc(100% - 21px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}
.wid40 {
  width: calc(100% - 40px);
}
</style>
