<template>
    <div class="avatar-group">
        <div style="display: flex;" class="box">
            <div v-for="(avatar, index) in props.avatars" :key="index" class="avatar" v-show="index < number">
                <div class="img" >
                    {{ avatar.departmentName }}
                </div>
            </div>
        </div>
        <div v-if="props.avatars?.length > number" class="moreSpan"> +{{ props.avatars.length - number }}</div>
        <div v-else></div>
    </div>
</template>

<script setup>
import { defineProps } from 'vue';
const props = defineProps({
    avatars: Array,
    number: Number
})
const avatars = props.avatars
const number = props.number;    
</script>
<style scoped lang="less">
.avatar-group {
    display: flex;
}

.avatar {
    margin-left: -6px;
    text-align: center;
}

.avatar .img {
    padding: 0 8px;
    height: 24px;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    // color: #8A8A8A;
    font-size: 16px;
    text-align: center;
    line-height: 24px;
}

.box {
    margin-right:8px;
}

.moreSpan {
    height: 24px;
    line-height:24px;
    border-radius: 15px;
    color: #8A8A8A;
    font-size: 14px;
}
</style>