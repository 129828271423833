<template>
    <div class="audioContent">
        <div class="btnRight">
            <div class="left" @click="back">
                <ArrowLeftOutlined class="icon" />
                <div>{{ t('bot.bot55') }}</div>
            </div>
            <div class="right" @click="openHistory">{{ t('bot.bot56') }}</div>
        </div>
        <!-- 上传 -->
        <div v-if="type == 'upFile'">
            <UploadDragger class="upFile" name="file" :multiple="false" :showUploadList="false"
                :customRequest="customRequest" action="#" :before-upload="beforeUpload">
                <div class="iconCenter">
                    <AudioOutlined class="word iconItem" />
                </div>
                <div class="upFileTip">{{ t('bot.bot57') }}</div>
                <div class="auditTip">{{ t('bot.bot58') }}</div>
                <dl class="auditTip">{{ t('bot.bot59') }}</dl>
            </UploadDragger>
        </div>
        <!-- 提取中 -->
        <div v-if="type == 'loading'" class="upFile">
            <div class="loadingAbs">
                <div v-loading="loading" class="loading"></div>
                <div class="loadingText">{{ t('bot.bot60') }}</div>
            </div>
        </div>
        <!-- 成功 -->
        <div v-if="type == 'finish'" class="upFile">
            <el-row>
                <el-col :span="24" class="col12">
                    <div class="finfishFlex">
                        <div class="titleIcon"></div>
                        <div class="finishTitle">{{ t('bot.bot61') }}</div>
                    </div>
                    <div class="leftCenterText">
                        {{ leftText }}
                    </div>
                    <div class="audioDiv">
                        <audioComponentsVue :fileurl="voiceUrl"></audioComponentsVue>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- 历史 -->
        <Drawer v-if="historyType" :title="$t('bot.bot56')" placement="right" :closable="false" :width="500" :open="historyType"
            @close="historyType = false">
            <div>
                <div v-for="item in voicesList" :key="item.id" class="historyDiv">
                    <AudioOutlined class="historyIcon" />
                    <div class="historyName">{{ item.fileName }}</div>
                    <el-icon class="down" @click="downloadFile(item)">
                        <Download />
                    </el-icon>
                </div>
            </div>
        </Drawer>
    </div>
</template>

<script setup>
import api from "@/api/index";
import { ref, reactive, onMounted } from "vue";
import { AudioOutlined, ArrowLeftOutlined } from '@ant-design/icons-vue';
import { message, UploadDragger, Drawer } from "ant-design-vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import audioComponentsVue from "./audioComponents.vue";
onMounted(() => {

})
const { t } = useI18n();
const router = useRouter();
const store = useStore();
const userId = ref(store.state.userId);
const type = ref("upFile");
const historyType = ref(false);
const audioUrl = ref("");
const leftText = ref("");
const fileId = ref(null)
const voicesList = ref([]);
const voiceUrl = ref("");
const timmer = ref(null);
const loading = ref(false);

const downloadFile = (item) => {
    const imageUrl = item.contentUrl;
    // 创建一个下载链接
    const a = document.createElement("a");
    a.href = imageUrl;
    a.download = "downloaded-image.jpg"; // 设置保存的文件名
    a.style.display = "none";
    // 将链接添加到DOM中
    document.body.appendChild(a);
    // 模拟用户点击链接以触发下载
    a.click();
    // 从DOM中移除链接
    document.body.removeChild(a);
}
// 打开历史记录
const openHistory = () => {
    historyType.value = true;
    api.bot
        .voiceListApi({
            userId: userId.value,
        })
        .then((res) => {
            if (res.returnCode == 200) {
                voicesList.value = res.data;
            }
        })
}

// 上传前校验
const beforeUpload = (file) => {
    // 文件类型
    file.fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
    let validFileTypes = [
        "wav",
        "WAV",
        "mp3",
        "MP3",
        "M4A",
        "m4a",
        "opus",
        "OPUS",
        "OGG",
        "ogg",
        "FLAC",
        "flac",
        "WMA",
        "wma",
        "AAC",
        "aac",
        "ALAW in WAV container",
        "MULAW in WAV container",
        "AMR",
        "amr",
        "WebM",
        "SPEEX",
        "speex",
    ];
    if (!validFileTypes.includes(file.fileType)) {
        message.warning(t('bot.bot62'));
        return false;
    }
    type.value = "loading";
    loading.value = true;
    api.bot.upVoice([file], {}).then((res) => {
        if (res.data.length != 0) {
            poill(res.data);
        }
    });
}
// 轮询
const poill = (data) => {
    api.bot
        .voiceStatus({
            userId: userId.value,
            targetUrl: data,
        })
        .then((res) => {
            if (res.returnCode == 200) {
                if (res.data == "Succeeded") {
                    api.bot
                        .voiceResult({
                            userId: userId.value,
                            targetUrl: data,
                        })
                        .then((res) => {
                            audioUrl.value = data;
                            leftText.value = res.data;
                            voiceUrl.value = res.voiceUrl;
                            loading.value = false;
                            type.value = "finish";
                        });
                } else {
                    timmer.value = setTimeout(() => {
                        poill(data);
                    }, 1000);
                }
            }
        });
};
const customRequest = () => { };
// 返回上一页
const back = () => {
    router.go(-1);
};
</script>

<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.audioContent {
    background-color: #ffffff;
    border-radius: 16px;
    padding: 20px 30px 30px 30px;
    min-width: 1430px;
    min-height: 711px;
    width: 100%;
    height: 100%;
}

.btnRight {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 30px;

    .left {
        display: flex;
        color: #8a8a8a;
        cursor: pointer;

        .icon {
            margin-top: 4px;
            margin-right: 6px;
            size: 20px;
        }
    }

    .right {
        cursor: pointer;
        color: #3376ff;
        font-weight: 600;
    }
}

.iconItem {
    display: block;
    margin-bottom: 40px;
    font-size: 120px;
}

.word {
    color: #458ae6;
}

.historyDiv {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
}

.historyIcon {
    font-size: 23px;
    color: #458ae6;
    display: block;
    margin-right: 10px;
    width: 25px;
}

.down {
    position: absolute;
    right: 0;
    font-size: 24px;
    width: 25px;
    margin-left: 10px;
}

.historyName {
    width: calc(100% - 70px);
}

.upFile {
    width: 100%;
    height: calc(100% - 30px);
    border-radius: 16px;
}

.iconCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 180px;
}

.finsh {
    width: 100%;
    height: 690px;
}

:deep(.ant-upload-wrapper .ant-upload-drag) {
    width: 1000px !important;
    height: 560px !important;
    margin: 40px auto 0 !important;
}

:deep(.ant-upload.ant-upload-drag) {
    background-color: #edf2ff;
}

.upFileIcon {
    font-size: 80px;
    color: #264095;
}

.upFileTip {
    font-size: 28px;
    color: #000000;
    font-family: "Regular";
    margin: 20px 0px;
}

.auditTip {
    font-size: 14px;
    color: #8a8a8a;
    font-family: "Regular";
    margin-bottom: 10px;
}

.loadingText {
    font-size: 20px;
    color: #3376ff;
    margin-top: 80px;
    font-family: "Regular";
}

.loadingAbs {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 240px;
    border-radius: 16px;
    background-color: #edf2ff;
    height: 690px;

    .loading {
        margin-right: 60px;
    }
}

.col12 {
    padding: 20px;
    height: 690px;
    border-radius: 16px;
    position: relative;
    background-color: #edf2ff;
}

.titleIcon {
    width: 2px;
    background-color: #264095;
    height: 20px;
    margin-right: 10px;
}

.finishTitle {
    font-size: 18px;
    color: #000000;
    font-family: "Bold";
}

.finfishFlex {
    display: flex;
    align-items: center;
    height: 30px;
}

.playAudio {
    height: 80px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.leftCenterText {
    height: calc(100% - 140px);
    overflow: auto;
    font-size: 16px;
    font-family: "Regular";
    padding: 20px 0px;
    color: #000000;
}

.flexCenterRight {
    display: flex;
    align-items: center;
    height: 30px;
    justify-content: flex-end;
}

.rightIcon {
    color: #264095;
    margin-right: 5px;
}

.exportText {
    color: #264095;
    font-family: "Regular";
    font-size: 18px;
}

.rightText {
    height: calc(100% - 60px);
    overflow: auto;
    font-size: 16px;
    font-family: "Regular";
    padding: 20px 0px;
    color: #000000;
}

.audioDiv {
    width: 1380px;
    height: 50px;
}
</style>