<template>
  <div>
    <!-- 文件信息 -->
    <div class="topMDiv">
      <div class="topRightDiv59" v-if="chatType == 'base'">
        <div class="flexCenter">
          <div class="topTitleName" v-if="topicItem.knowledge_name">
            <el-tooltip popper-class="toolTip" :content="topicItem.knowledge_name">
              {{ topicItem.knowledge_name }}
            </el-tooltip>
          </div>
          <img
            :src="arrow"
            alt=""
            class="wid24"
            v-if="topicItem.knowledge_name"
          />
          <div class="topFileName flexCenter">
            <el-tooltip popper-class="toolTip" :content="fileItem.fileName">
              <div
                :class="{
                  nameH:
                    fileDescObj.importantFlag == 1 &&
                    fileDescObj.importantExpireTime,
                  nameC:
                    fileDescObj.importantFlag == 1 &&
                    !fileDescObj.importantExpireTime,
                  nameN: fileDescObj.importantFlag != 1,
                }"
              >
                {{ fileItem.fileName }}
              </div>
            </el-tooltip>
            <el-tooltip popper-class="toolTip" :content="t('base.base290')">
              <div class="flexCenter">
                <img
                  :src="topYes"
                  alt=""
                  class="wid25 marGin12"
                  v-if="fileDescObj.importantFlag == 1"
                />
                <div class="topFontItem" v-if="fileDescObj.importantFlag == 1">
                  {{
                    fileDescObj.importantExpireTime
                      ? getYear(fileDescObj.importantExpireTime)
                      : t("base.base176")
                  }}
                </div>
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="flexCenter" v-if="Object.keys(fileDescObj).length != 0">
          <div class="topFontItem">{{ t("base.base269") }}:</div>
          <div class="topTx" :style="'background-color:' + getRandomColor1()">
            {{ fileDescObj.userName.slice(0, 1) }}
          </div>
          <el-tooltip popper-class="toolTip" :content="fileDescObj.userName">
            <div class="topFontItem nameMax">{{ fileDescObj.userName }}</div>
          </el-tooltip>
          <el-tooltip popper-class="toolTip" :content="t('base.base289')">
            <div class="flexCenter">
              <img :src="topTime" alt="" class="wid25 marGin12" />
              <div class="topFontItem">
                {{
                  fileDescObj.createTime ? getYear(fileDescObj.createTime) : ""
                }}
              </div>
            </div>
          </el-tooltip>
          <el-tooltip popper-class="toolTip" :content="t('base.base120')">
            <div class="flexCenter">
              <img
                :src="baseGq"
                alt=""
                class="wid25 marGin12"
                v-if="fileDescObj.expireFlag == 1"
              />
              <div class="topFontItem" v-if="fileDescObj.expireFlag == 1">
                {{
                  fileDescObj.expireDate ? getYear(fileDescObj.expireDate) : ""
                }}
              </div>
            </div>
          </el-tooltip>
        </div>
      </div>
      <div class="topRightDivCenter" v-else>
        <div class="topFileName">
          <el-tooltip popper-class="toolTip" :content="fileItem.fileName">
            {{ fileItem.fileName }}
          </el-tooltip>
        </div>
      </div>
      <div class="topRightBtn" v-if="chatType == 'base'">
        <el-dropdown @command="changeMore" v-if="props.type == 'base'">
          <div class="moreDiv">
            <img :src="hMore" alt="" class="hMore" />
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :command="1">{{
                t("base.base62")
              }}</el-dropdown-item>
              <el-dropdown-item
                :command="2"
                v-if="fileDescObj.knowledge_role_id == 0"
                >{{ t("base.base67") }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>

        <div class="topLook" @click="lookText">
          <img :src="topResh" alt="" class="wid25 marGin6" />
          <div class="topFontItem">
            {{ textType ? t("base.base270") : t("base.base271") }}
          </div>
        </div>
        <el-dropdown
          @command="changeSel"
          v-if="routeType == 'chat' && fileDescObj.knowledge_role_id != 2"
        >
          <div class="topLook">
            <div class="topFonItemF">{{ t("base.base63") }}</div>
            <img :src="topSel" alt="" class="topSel marGin9" />
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                :command="1"
                v-if="fileDescObj.expireDate == null"
                >{{ t("base.base63") }}</el-dropdown-item
              >
              <el-dropdown-item :command="3" v-else>{{
                t("base.base64")
              }}</el-dropdown-item>
              <el-dropdown-item
                :command="2"
                v-if="fileDescObj.importantFlag == 1"
                >{{ t("base.base65") }}</el-dropdown-item
              >
              <el-dropdown-item
                :command="4"
                v-if="
                  fileDescObj.importantFlag == 0 ||
                  fileDescObj.importantFlag == null
                "
                >{{ t("base.base66") }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>

        <img
          :src="closeFileView"
          alt=""
          class="closeFileView"
          @click="closeView"
        />
      </div>
      <div class="topRightBtn" v-else>
        <img
          :src="closeFileView"
          alt=""
          class="closeFileView"
          @click="closeView"
        />
      </div>
    </div>
    <!-- 文件展示 -->
    <div
      class="rightFileChunk"
      v-if="
        props.chunkDialog == true &&
        Object.keys(props.fileItem).length != 0 &&
        !textType
      "
    >
      <chunkView :fileItem="props.fileItem"></chunkView>
    </div>
    <!-- 文字展示 -->
    <div
      class="rightFileChunk"
      v-if="
        props.chunkDialog == true &&
        Object.keys(props.fileItem).length != 0 &&
        textType
      "
    >
      <div
        v-for="(item, index) in textInner"
        :key="index"
        :class="getId(item) ? 'textMessageItem' : 'textMessageItem'"
      >
        <div :class="getId(item) ? ' textMessageItemAc' : ''">
          {{ item.chunk }}
        </div>

        <!-- <messageView :message="item.chunk"></messageView> -->
      </div>
    </div>
    <!-- 时间展示 -->
    <timeView
      v-if="timeDialog"
      :type="timeType"
      @closeTime="closeTime"
      @saveTime="saveTime"
      :loading="timeLoading"
    ></timeView>
  </div>
</template>

<script setup>
import arrow from "@/assets/base/arrow.svg";
import topTime from "@/assets/base/topTime.svg";
import topYes from "@/assets/base/fileYear.svg";
import topResh from "@/assets/base/topResh.svg";
import topSel from "@/assets/base/topSel.svg";
import closeFileView from "@/assets/base/closeFileView.svg";
import hMore from "@/assets/base/hMore.svg";
import chunkView from "@/components/chunk/index.vue";
import messageView from "@/components/message/index.vue";
import baseGq from "@/assets/base/bseGq.svg";
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { getYear } from "@/utils/utils";
import { getRandomColor1 } from "@/utils/utils";
import { useRoute } from "vue-router";
import api from "@/api";
import { message } from "ant-design-vue";
import timeView from "@/views/base/time.vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const route = useRoute();
const store = useStore();
const emit = defineEmits([
  "closeFileView",
  "downFile",
  "deleteFile",
  "getFileList",
]);
const props = defineProps(["fileItem", "chunkDialog", "type", "chunkList"]);
const topicItem = ref(store.state.topicItem);
const textType = ref(false);
const userId = ref(store.state.userId);
const chatType = ref(store.state.chatType);
const textInner = ref("");
const timeDialog = ref(false); //设置过期时间
const fileDescObj = ref({}); //文件描述
const timeLoading = ref(false);
const timeType = ref(null);
const routeType = ref("");
onMounted(() => {
  routeType.value = route.query.type ? route.query.type : "chat";
  getText();
  getFileDesc();
});
watch(
  () => store.state.chatType,
  (newValue, oldValue) => {
    chatType.value = newValue;
  }
);
watch(
  () => props.fileItem,
  (newValue, oldValue) => {
    textType.value = false;
    textInner.value = "";
    timeDialog.value = false;
    fileDescObj.value = {};
    timeLoading.value = false;
    getFileDesc();
    getText();
  }
);
// 确定设置过期时间
const saveTime = (time) => {
  timeLoading.value = true;
  api.base[timeType.value == 1 ? "fileExpire" : "importantSet"]({
    userId: userId.value,
    fileId: props.fileItem.fileId || props.fileItem.id,
    expireTime: time,
    libraryId:
      topicItem.value.knowledge_library_id || topicItem.value.libraryId,
    operateType: "add",
  })
    .then((res) => {
      timeLoading.value = false;
      if (res.returnCode == 200) {
        timeDialog.value = false;
        message.success(t("base.base105"));
        getFileDesc();
        emit("getFileList");
      }
    })
    .catch((err) => {
      timeLoading.value = false;
    });
};
const getFileDesc = () => {
  if (chatType.value == "base") {
    api.base
      .fileDesc({
        userId: userId.value,
        libraryId:
          topicItem.value.knowledge_library_id || topicItem.value.libraryId,
        fileId: props.fileItem.fileId || props.fileItem.id,
      })
      .then((res) => {
        if (res.returnCode == 200) {
          fileDescObj.value = res.data;
          console.log(res.data);
        }
      });
  }
};
defineExpose({
  getFileDesc,
});
const closeView = () => {
  emit("closeFileView");
};
const changeMore = (e) => {
  if (e == 1) {
    emit("downFile", props.fileItem);
  }
  if (e == 2) {
    emit("deleteFile");
  }
};
const changeSel = (e) => {
  if (e == 1) {
    timeType.value = 1;
    timeDialog.value = true;
  }
  if (e == 2) {
    api.base
      .importantSet({
        userId: userId.value,
        fileId: props.fileItem.fileId || props.fileItem.id,
        expireTime: null,
        libraryId:
          topicItem.value.knowledge_library_id || topicItem.value.libraryId,
        operateType: "delete",
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("base.base15"));
          getFileDesc();
          emit("getFileList");
        }
      });
  } else if (e == 3) {
    api.base
      .fileExpire({
        userId: userId.value,
        fileId: props.fileItem.fileId || props.fileItem.id,
        expireTime: null,
        libraryId:
          topicItem.value.knowledge_library_id || topicItem.value.libraryId,
        operateType: "delete",
      })
      .then((res) => {
        if (res.returnCode == 200) {
          message.success(t("base.base15"));
          getFileDesc();
          emit("getFileList");
        }
      });
  }
  if (e == 4) {
    timeType.value = 2;
    timeDialog.value = true;
  }
};
const closeTime = () => {
  timeDialog.value = false;
};
// 展示文字
const lookText = () => {
  textType.value = !textType.value;
};
// 判断高亮
const getId = (item) => {
  if (!props.chunkList || props.chunkList.length == 0) {
    return false;
  }
  const hasChunkId = props.chunkList.some((e) => e.chunkId === item.chunkId);
  return hasChunkId;
};
// 获取文字内容
const getText = () => {
  if (chatType.value != "chat") {
    api.base
      .textView(
        {
          userId: userId.value,
          fileId: props.fileItem.fileId || props.fileItem.id,
        },
        topicItem.value.knowledge_library_id || topicItem.value.libraryId
      )
      .then((res) => {
        if (res.returnCode == 200) {
          textInner.value = res.returnMessage;
        }
      });
  }
};
</script>

<style lang="scss" scoped>
.topMDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 92px;
  padding: 0px 30px;
  border-bottom: 1px solid #e2e6ed;
  background-color: #fefefe;
  border-radius: 30px 30px 0px 0px;
}
.topRightDiv59 {
  width: calc(100% - 420px);
  height: 59px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.topRightDivCenter {
  width: calc(100% - 30px);
  height: 59px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.topTitleName {
  color: #8a8a8a;
  font-size: 20px;
  font-family: "Regular";
  max-width: 180px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.wid24 {
  width: 24px;
}
.topSel {
  width: 17px;
}
.topFileName {
  color: #3d3d3d;
  font-size: 20px;
  font-family: "Regular";
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.topFontItem {
  color: #8a8a8a;
  font-family: "Regular";
  font-size: 18px;
}
.nameMax {
  max-width: 100px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.topTx {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  margin: 0px 10px;
  font-size: 16px;
  color: #f7f8ff;
  text-align: center;
  font-family: "Regular";
  line-height: 31px;
}
.wid25 {
  width: 18px;
}
.marGin12 {
  margin: 0px 6px 0px 12px;
}
.topRightBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 420px;
}
.topLook {
  height: 44px;
  border-radius: 16px;
  border: 2px solid #edeef6;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 12px;
  margin-left: 12px;
  cursor: pointer;
}
.marGin6 {
  margin-right: 6px;
}
.topFonItemF {
  color: #f02d63;
  font-family: "Regular";
  font-size: 18px;
}

.marGin9 {
  margin-left: 9px;
}
.rightFileChunk {
  padding: 12px;
  height: calc(100% - 117px);
  width: calc(100% - 24px);
  background-color: #ffffff;
  overflow: auto;
}
.closeFileView {
  width: 14px;
  margin-left: 12px;
  cursor: pointer;
}
.hMore {
  width: 18px;
}
.moreDiv {
  width: 36px;
  height: 36px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}
.moreDiv:hover {
  background-color: #f7f8ff;
}
.textMessageItem {
  background-color: #f7f8ff;
  border-radius: 12px;
  margin-bottom: 24px;
  font-size: 18px;
  font-family: "Regular";
  padding: 12px;
}
.textMessageItemAc {
  background-color: #faf2d8;
}
.nameH {
  max-width: calc(100% - 250px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.nameC {
  max-width: calc(100% - 100px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.nameN {
  max-width: 100%;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
