<script setup>
import { ref, defineProps, onMounted, watch } from "vue";
const emit = defineEmits(['handleSaml', 'handleSamlAdd']);
const props = defineProps({
    showSaml: Boolean,
});
// watch(() => props.groupDetail, (newVal, oldValue) => {
//     if (newVal !== oldValue) {

//     }
// })
onMounted(() => {
})
const form = ref({})
// 关闭
const handleCancel = () => {
    emit('handleSaml')
}
//同意
const handleUpload = () => {
    emit('handleSamlAdd')
};

const logActive = ref(0)
const logoAzure = require('@/assets/manage/logoAzure.svg')
const logoBaidu = require('@/assets/manage/logoBaidu.svg')
const logoAli = require('@/assets/manage/logoAli.svg')
// 资源配置选中
const logList = ref([
    {
        url: logoAzure,
    },
    {
        url: logoAli,
    },
    {
        url: logoBaidu,
    },
]);
const changeLog = (index) => {
    logActive.value = index
};
</script>

<template>
    <div>
        <!-- 部门详情 -->
        <el-dialog :z-index="100" destroy-on-close v-model="props.showSaml" align-center :close-on-click-modal="false" :show-close="false">
            <template #header="">
                <div class="my-header">
                    <div style="display: flex;">
                        <div class="tit_img">
                            <img src="@/assets/manage/samlLogo.svg" alt="">
                        </div>
                        {{ $t('manage.baseForm.base53') }}
                        <div class="line">{{ $t('manage.baseForm.base54') }}</div>
                    </div>
                    <el-icon class="el-icon--left" :class="'close-icon'" @click="handleCancel" style="cursor: pointer;">
                        <Close />
                    </el-icon>
                </div>
            </template>
            <div class="content">
                <el-form :model="form">
                    <el-form-item prop="name">
                        <div class="form-name">
                            <div class="tit">
                                <div class="div">{{ $t('manage.baseForm.base55') }}</div>
                                <div class="right">
                                    <img src="@/assets/manage/download.svg" alt="" class="download">
                                    <div>{{ $t('manage.baseForm.base56') }}</div>
                                </div>
                            </div>
                            <div class="green">Assertion Consumer Service (ACS) URL</div>
                            <el-input placeholder="url" class="comtent"></el-input>
                            <div class="green">Service Provider (SP) Entity ID</div>
                            <el-input placeholder="url" class="comtent"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item prop="name">
                        <div class="form-name">
                            <div class="tit">
                                <div class="div">{{ $t('manage.baseForm.base57') }}</div>
                                <div class="right">
                                    <img src="@/assets/manage/upload.svg" alt="" class="download">
                                    <div>{{ $t('manage.baseForm.base58') }}</div>
                                </div>
                            </div>
                            <div class="green">SAML 2.0 Endpint <div style="color:#F02D63 ;">*</div></div>
                            <el-input placeholder="url" class="comtent"></el-input>
                            <div class="green">Identity Provider Issuer <div style="color:#F02D63 ;">*</div></div>
                            <el-input placeholder="url" class="comtent"></el-input>
                            <div class="green">Public Certificate <div style="color:#F02D63 ;">*</div></div>
                            <el-input placeholder="url" class="comtent"></el-input>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <div @click="handleCancel" class="cancel">{{ $t('manage.baseForm.base5') }}</div>
                    <div @click="handleUpload" class="confirm">{{ $t('manage.baseForm.base59') }}</div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>



<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:deep(.el-dialog) {
    --el-dialog-width: 520px;
    border-radius: 30px;
    height: 700px;
    padding: 30px;
    background-color: #F7F8FF;
}

:deep(.el-select__wrapper) {
    width: 450px !important;
    border-radius: 12px !important;
}

:deep(.el-select--large) {
    width: 450px !important;
}

:deep(.el-input__wrapper) {
    height: 38px !important;
    width: 428px !important;
    border-radius: 12px;
}

.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 20px;
    color: #3d3d3d;
    font-family: "Medium";

    .tit_img {
        width: 28px;
        height: 28px;
        border-radius: 8px;
        margin-right: 12px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
        }
    }
    .line{
        margin-left: 12px;
        color: #3376FF;
        font-size: 16px;
        margin-top: 4px;
        text-decoration: underline;        
    }

}

.close-icon {
    font-size: 20px;
}

.content {
    font-size: 18px;
    color: #8A8A8A;
    height: 550px;
    overflow-x: hidden;
    overflow: auto;

    .name {
        color: #3D3D3D;
        font-size: 24px;
        margin: auto 6px auto 18px;
        font-weight: 400;
    }
}


.form-name {
    font-size: 16px;
    color: #3D3D3D;

    .tit {
        margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        .right{
            border-radius: 12px;
            padding: 0px 12px;
            display: flex;
            font-size: 15px;
            color: #3376FF;
            background-color: #ECEEFF;
            .download{
                margin-top: 6px;
                display: block;
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
        }
    }
    .green{
        font-size: 16px;
        color: #8A8A8A;
        margin-bottom: 6px;
        display: flex;
        div{
            margin-left: 6px;
        }
    }

    .comtent {
        margin-bottom: 8px;
        display: flex;

        .log_img {
            width: 25px;
            height: 25px;
            border-radius: 8px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 8px;
            }
        }

        .log_name {
            margin-left: 8px;
            color: #8A8A8A;
            height: 25px;
            line-height: 25px;
        }

        .log_item {
            width: 132px;
            height: 42px;
            border-radius: 18px;
            padding: 10px 6px;
            background-color: #fff;
            margin-right: 10px;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .log_itemActive {
            width: 132px;
            height: 42px;
            border-radius: 18px;
            padding: 10px 6px;
            background-color: #DBDFFF;
            margin-right: 10px;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
            }
        }

    }
}
.dialog-footer {
    display: flex;
    justify-content: end;
    font-size: 15px;

    .cancel {
        color: #F02D63;
        padding: 8px 20px;
        border-radius: 16px;
        border: 1px solid #EDEEF6;
        cursor: pointer;
    }

    .confirm {
        color: #fff;
        background-color: #3376FF;
        margin-left: 10px;
        padding: 8px 20px;
        border-radius: 16px;
        cursor: pointer;
    }
}
</style>