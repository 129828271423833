<template>
  <div class="bottomDiv">
    <!-- 左侧 -->
    <div class="bottomDivItem marginRight">
      <div class="flexCenterBetween">
        <div class="leftTitle">
          {{ t("menu.index2") }}
          <span class="leftTipTitle">{{
            leftType ? t("base.base98") : t("base.base99")
          }}</span>
        </div>
        <div class="flexCenter">
          <div class="flexCenter">
            <img :src="kImg" alt="" class="bottomIconTip" @click="getRm" />
            <img :src="jImg" alt="" class="bottomIconTip" @click="getQw" />
          </div>
          <div class="flexCenter" @click="goShare">
            <span class="leftTip">{{ t("menu.index3") }}</span>
            <img :src="eImg" alt="" class="eImg" />
          </div>
        </div>
      </div>
      <div
        class="itemBottom"
        v-if="topicList.length != 0"
        v-loading="topicLoading"
      >
        <div
          v-for="item in topicList"
          :key="item"
          class="itemForDiv"
          @click="goShareView(item)"
        >
          <div class="flexCenter">
            <img :src="dImg" alt="" class="dImg" />
            <span class="itemTitle">{{ item.sessionName }}</span>
          </div>
          <div class="itemTip">
            {{ item.message || item.firstAnswer }}
          </div>
          <div class="flexCenter padding35">
            <div class="flexCenter" v-if="item.updateTime">
              <img :src="timePng" alt="" class="bottomIconRihgt" />
              <span class="bottomFont">{{ getYear(item.updateTime) }}</span>
            </div>
            <div class="flexCenter" v-if="item.isValid == 1">
              <img :src="yesPng" alt="" class="bottomIconRihgt" />
              <span class="bottomFont">{{
                item.expireDate !=null ? getYear(item.expireDate) : t("menu.index4")
              }}</span>
            </div>
            <div class="flexCenter">
              <img :src="hotPng" alt="" class="bottomIconRihgt" />
              <span class="bottomFont">{{ getNumber(item.result) }}</span>
            </div>
            <div class="tipDiv">
              <img :src="leftTip" alt="" class="wid20" />
              <span class="leftTipText">{{
                item.knowledgeName || item.knowledge.knowledgeName
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="itemBottom flex"
        v-if="topicList.length == 0 && !topicLoading"
      >
        <img :src="noData" class="noData" alt="" />
      </div>
    </div>
    <!-- 右侧 -->
    <div class="bottomDivItem marginLeft">
      <div class="flexCenterBetween">
        <div class="leftTitle">{{ t("menu.bot") }}</div>
        <div class="flexCenter">
          <div class="flexCenter">
            <img :src="kImg" alt="" class="bottomIconTip" @click="lastPage" />
            <img :src="jImg" alt="" class="bottomIconTip" @click="nextPage" />
          </div>
          <div class="flexCenter" @click="goBot">
            <span class="leftTip">{{ $t("menu.index5") }}</span>
            <img :src="eImg" alt="" class="eImg" />
          </div>
        </div>
      </div>
      <div class="itemBottom flexStart" v-loading="botLoading">
        <div
          class="rightFor"
          v-for="item in botList"
          :key="item"
          @click="goBaseDetails(item)"
        >
          <div class="flexCenter">
            <img :src="botImg" alt="" class="botImg" />
            <el-tooltip popper-class="toolTip"
              class="box-item"
              effect="dark"
              :content="item.templateName"
            >
              <div class="itemTitle">{{ item.templateName }}</div>
            </el-tooltip>
          </div>
          <div class="itemTip">
            {{ item.templateDesc }}
          </div>
          <div class="flexCenter padding35">
            <div class="flexCenter">
              <!-- <img :src="rightTipImg" alt="" class="bottomIconRihgt" /> -->
              <span class="bottomFont">{{ getYear(item.createAt) }}</span>
            </div>
            <!-- <div class="flexCenter">
              <img :src="hotPng" alt="" class="bottomIconRihgt" />
              <span class="bottomFont">{{ getNumber(item.voteUpNum) }}</span>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import jImg from "@/assets/index/j.svg";
import kImg from "@/assets/index/k.svg";
import eImg from "@/assets/index/e.svg";
import dImg from "@/assets/index/d.svg";
import timePng from "@/assets/index/time.svg";
import yesPng from "@/assets/index/yes.svg";
import hotPng from "@/assets/index/hot.svg";
import leftTip from "@/assets/index/leftTip.svg";
import botImg from "@/assets/index/bot.svg";
import rightTipImg from "@/assets/index/rightTip.svg";
import noData from "@/assets/nodata.svg";
import { useI18n } from "vue-i18n";
import { getYear } from "@/utils/utils";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ref, onMounted } from "vue";
import api from "@/api";
const { t } = useI18n();
const store = useStore();
const router = useRouter();
const userId = ref(store.state.userId);
const botList = ref([]);
const botLoading = ref(false);
const topicList = ref([]);
const topicLoading = ref(false);
const pageId = ref(1); //分页
const perPage = ref(6);
const totalPage = ref(null);
const leftType = ref(false);
onMounted(() => {
  getBot();
  getRm();
});
const getNumber = (item) => {
  let number = Math.floor(item);
  return number;
};
const getQw = () => {
  topicLoading.value = true;
  leftType.value = true;
  api.share
    .getVerifyQuertApi({
      userId: userId.value,
      namePattern: "",
      pageId: 1,
      perPage: 3,
    })
    .then((res) => {
      topicLoading.value = false;
      if (res.returnCode == 200) {
        topicList.value = res.data;
      }
    })
    .catch((err) => {
      topicLoading.value = false;
    });
};
const getRm = () => {
  topicLoading.value = true;
  leftType.value = false;
  api.base
    .hotQuery({ userId: userId.value, namePattern: "", pageId: 1, perPage: 3 })
    .then((res) => {
      topicLoading.value = false;
      if (res.returnCode == 200) {
        topicList.value = res.data;
      }
    })
    .catch((err) => {
      topicLoading.value = false;
    });
};
const lastPage = () => {
  if (pageId.value == 1) return;
  pageId.value--;
  getBot();
};
const nextPage = () => {
  if (pageId.value == totalPage.value) return;
  pageId.value++;
  getBot();
};
const getBot = () => {
  botLoading.value = true;
  api.bot
    .recommendQuery({
      userId: userId.value,
      pageId: pageId.value,
      perPage: perPage.value,
    })
    .then((res) => {
      botLoading.value = false;
      if (res.returnCode == 200) {
        totalPage.value = res.totalPage;
        botList.value = res.data;
      }
    });
};
// 知识共享预览
const goShareView = (item) => {
  if (item.isOwner == 1 && item.isValid != 1) {
    store.commit("SET_CHATTYPE", "base");
    store.commit("SET_TOPIC", item);
    router.push({
      name: "chat",
    });
  } else {
    store.commit("SET_CHATTYPE", "base");
    store.commit("SET_TOPIC", item);
    router.push({
      name: "chat",
      query: {
        type: "look",
      },
    });
  }
};
// 知识共享
const goShare = () => {
  store.commit("SET_INDEXGOSHARE");
  router.push({
    name: "share",
  });
};
// 智能体
const goBot = () => {
  store.commit("SET_INDEXGOBOT");
  router.push({
    name: "bot",
  });
};
const goBaseDetails = (item) => {
  console.log(item);
  api.chat
    .addChat({
      userId: userId.value,
      topicName: item.templateName,
      templateId: item.templateId,
    })
    .then((res) => {
      if (res.returnCode == 200) {
        // 新增对话 不算base chat
        store.commit("SET_CHATTYPE", "chat");
        store.commit("SET_TOPIC", {
          sessionName: item.templateName,
          sessionId: res.data.table_id,
        });
        router.push("/chat");
      }
    });
};
</script>

<style lang="less" scoped>
.bottomDiv {
  height: 430px;
  width: 100%;
  display: flex;
}
.bottomDivItem {
  min-width: 664px;
  width: calc(50% - 10px);
  background-color: #fefefe;
  border-radius: 30px;
  padding: 20px 30px;
  box-shadow: 0px 0px 16px 0 #dbdfff82;
}
.marginRight {
  margin-right: 10px;
}
.marginLeft {
  margin-left: 10px;
}
.leftTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Semibold";
}
.leftTip {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Semibold";
  cursor: pointer;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.bottomIconTip {
  margin-right: 35px;
  cursor: pointer;
  width: 9px;
}
.wid20 {
  margin-right: 10px;
  width: 21px;
}
.eImg {
  margin-left: 5px;
  width: 11px;
  cursor: pointer;
}
.flexCenterBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 10px;
}
.itemBottom {
  height: calc(100% - 50px);
  overflow: auto;
}
.itemTitle {
  font-size: 20px;
  color: #3d3d3d;
  font-family: "Medium";
  max-width: calc(100% - 25px);
  margin-left: 10px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.itemTip {
  font-size: 20px;
  font-family: "Regular";
  color: #8a8a8a;
  margin-left: 35px;
  width: calc(100% - 35px);
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.marginLeft45 {
  margin-left: 45px;
  width: calc(100% - 45px);
}
.padding35 {
  padding-left: 35px;
  position: relative;
}
.itemForDiv {
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}
.itemForDiv:hover {
  background-color: #eceeff;
  border-radius: 16px;
}
.rightFor {
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  width: calc(50% - 20px);
}
.rightFor:hover {
  background-color: #eceeff;
  border-radius: 16px;
}
.noMargin {
  margin: 0;
}
.bottomFont {
  font-size: 18px;
  color: #8a8a8a;
  font-family: "Regular";
  margin-right: 10px;
}
.bottomIconRihgt {
  margin-right: 10px;
  width: 18px;
}
.leftTipText {
  font-size: 18px;
  color: #3d3d3d;
  font-family: "Regular";
  max-width: 130px;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.tipDiv {
  height: 34px;
  border: 1px solid #dbdfff;
  border-radius: 16px;
  max-width: 178px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  cursor: pointer;
}
.flexStart {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.dImg {
  width: 22px;
}
.botImg {
  width: 25px;
}
.noData {
  width: 300px;
  height: 300px;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.leftTipTitle {
  font-size: 16px;
  color: #8a8a8a;
  margin-left: 10px;
}
</style>
